import { Component, OnInit } from '@angular/core';
import { Message, SelectItem } from 'primeng/primeng';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../servicios/auth.service';
import { PinService } from '../servicios/pin.service';
import { Pin } from '../modelo/pin';
import { Usuario } from '../modelo/usuario';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})
export class RegistrarseComponent implements OnInit {

  usuario: Usuario;
  password: string;
  pin: Pin;
  pinVerificado: boolean;
  msgs: Message[];
  isRegistroExitoso: boolean;
  tiposDocumentos: SelectItem[];
  forInvalido: boolean;

  constructor(private authService: AuthService, private pinService: PinService,
    private loginComponent: LoginComponent) { }

  ngOnInit() {
    console.log("iniciando regisro");
    this.tiposDocumentos = [];
    this.tiposDocumentos.push(
      { label: "Seleccione...", value: "0" },
      { label: "Tarjeta de Identidad", value: "Tarjeta de Identidad" },
      { label: "Cédula de Ciudadanía", value: "Cédula de Ciudadanía" },
      { label: "Cédula de Extranjería", value: "Cédula de Extranjería" }
    );
    this.usuario = new Usuario();
    this.pin = new Pin();
    this.pinVerificado = false;
    this.isRegistroExitoso = false;
    console.log("fin registro");
  }

  cargaInicialRegistrarse() {
    
  }

  verificarPin() {
    if (this.pin.UID !== null && this.pin.UID !== "") {
      this.pinVerificado = false;
      this.pinService.verificarPin(this.pin.UID)
        .then(pinDoc => {
          if (pinDoc.exists) {
            const pin = pinDoc.data() as Pin;
            pin.UID = pinDoc.id;
            this.pin = pin;
            this.usuario.pin = this.pin.UID;
            this.usuario.tipoUsuario = this.pin.tipo;
            this.usuario.escuela = this.pin.escuela;
            this.usuario.modulos = this.pin.modulos;
            console.log("Pin verificado:", this.pin);
            this.msgs = [];
            if (pin.estado !== "Activo") {
                this.pinVerificado = true;
                this.msgs.push({ severity: 'success', summary: 'Pin valido!.', detail: 'Pin validado exitosamente.' });              
            } else {
              this.msgs.push({ severity: 'error', summary: 'Pin inválido!.', detail: 'Pin ya ha sido activado.' });
            }
          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'Pin Inválido!', detail: 'El pin ingresado no es válido.' });
          }
        })
        .catch(error => {
          console.log("Error verificarPin:", error);
          this.msgs = [];
          //this.msgs.push({ severity: 'error', summary: 'Pin Inválido.', detail: 'El pin ingresado no es válido.' });
          this.msgs.push({ severity: 'error', summary: 'Pin Inválido!', detail: 'El pin ingresado no es válido.' });
        });
    } else {
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!.', detail: 'Ingresar PIN' });
    }
  }

  validarForm() {
    this.forInvalido = false;
    this.msgs = [];
    let msg = "Campos requeridos: ";
    if (this.usuario.email == "") {
      msg = msg + "\\n " + "Email";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Email' });
    }
    if (this.usuario.nombre == "") {
      msg = msg + "\\n " + "Nombre";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Nombre' });
    }
    if (this.usuario.apellido == "") {
      msg = msg + "\\n " + "Apellidos";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Apellidos' });
    }
    if (this.password == "") {
      msg = msg + "\\n " + "Password";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Password' });
    }
    if (this.usuario.tipoIdentificacion == "0") {
      msg = msg + "\\n " + "Tipo Identificación";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Tipo Identificación' });
    }
    if (this.usuario.numeroIdentificacion == "") {
      msg = msg + "\\n " + "Número de Identificación";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Número de Identificación' });
    }
  }

  registrarUsuario() {
    this.validarForm();
    if (!this.forInvalido) {
      this.authService.registrarUsuario(this.usuario, this.password)
        .then(nuevoUsuario => {
          this.usuario.UID = nuevoUsuario.user.uid;
          this.usuario.fechaFin = this.pin.fechaFin;
          this.usuario.caduca = this.pin.caduca;
          this.authService.agregarUsuarioCompleto(this.usuario)
            .then(ref => {
              this.loginComponent.isRegistro = false;
              this.isRegistroExitoso = true;
              this.pin.estado = "Activo"
              this.pinService.actualizarPin(this.pin);
              this.authService.logout();
            })
            .catch(error => {
              console.log("Error agregarUsuarioCompleto:", error);
              var errorCode = error.code;
              var errorMessage = error.message;
              if (errorCode == 'auth/invalid-email') {
                this.msgs = [];
                //this.msgs.push({ severity: 'error', summary: 'Formato de email inválido.', detail: '' });
                this.msgs.push({ severity: 'error', summary: 'Error Email!', detail: 'Formato de email inválido.' });
              } else {
                if (errorCode == 'auth/email-already-in-use') {
                  this.msgs = [];
                  //this.msgs.push({ severity: 'error', summary: 'El usuario está bloquedado.', detail: 'Comuniquese con el administrador.' });
                  this.msgs.push({ severity: 'error', summary: 'Error Email!', detail: 'Email ya está en uso.' });
                } else {
                  if (errorCode == 'auth/operation-not-allowed') {
                    this.msgs = [];
                    //this.msgs.push({ severity: 'error', summary: 'Error!', detail: 'Operación no permitida.' });
                    this.msgs.push({ severity: 'error', summary: 'Error!', detail: 'Operación no permitida.' });
                  } else {
                    if (errorCode == 'auth/weak-password') {
                      this.msgs = [];
                      //this.msgs.push({ severity: 'error', summary: 'Password error!', detail: 'Password muy debil.' });
                      this.msgs.push({ severity: 'error', summary: 'Password error!', detail: 'Password muy debil.' });
                    } else {
                      console.log("Error en registro de usuario:", errorMessage);
                      this.msgs = [];
                      //this.msgs.push({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Comuniquese con el administrador.' });
                      this.msgs.push({ severity: 'error', summary: 'Error inesperado!', detail: 'Comuniquese con el administrador.' });
                    }
                  }
                }
              }
            });
        })
        .catch(error => {
          console.log("Error registrarUsuario:", error);
        });
    }
  }

}
