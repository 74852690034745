import { Component, OnInit } from '@angular/core';
import { CursoService } from '../../servicios/curso.service';
import { EstadoModuloIngles } from '../../modelo/estado-modulo-ingles';
import { CursosEstado } from '../../modelo/cursos-estado';
import { AuthService } from '../../servicios/auth.service';
import { ProcesosMasivosService } from '../../servicios/procesos-masivos.service';

@Component({
  selector: 'app-curso-b2',
  templateUrl: './curso-b2.component.html',
  styleUrls: ['./curso-b2.component.css']
})
export class CursoB2Component implements OnInit {

  public estadoModuloIngles: EstadoModuloIngles;
  cursosEstado: CursosEstado;
  cursosEstadoA1: CursosEstado;
  cursosEstadoA2: CursosEstado;
  cursosEstadoB1: CursosEstado;
  cursosEstadoB2: CursosEstado;
  cursoID: string;
  isInicioCurso: boolean;
  mostrarLecciones: boolean;
  activarA1: boolean;
  activarA2: boolean;
  activarB1: boolean;
  activarB2: boolean;

  constructor(private cursoService: CursoService, public loginCom: AuthService, private procesosMasivosService: ProcesosMasivosService) { }

  ngOnInit() {
    this.cursosEstado = new CursosEstado();
    this.isInicioCurso = false;
    this.mostrarLecciones = false;
    this.cursoID = "B2";
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("curso-b2");
    } else {
      /*this.cursoService.buscarEstadoActualModuloIngles(this.loginCom.usuarioSession.UID)
        .then(res => {
          if (res.size > 0) {
            res.forEach(element => {
              const estado = element.data() as CursosEstado;
              estado.UID = element.id;
              this.cursosEstado = estado;
              if (estado.UID == "A1") {
                this.activarA1 = true;
                this.cursosEstadoA1 = estado;
              }
              if (estado.UID == "A2") {
                this.cursosEstadoA2 = estado;
                this.activarA2 = true;
              }
              if (estado.UID == "B1") {
                this.cursosEstadoB1 = estado;
                this.activarB1 = true;
              }
              if (estado.UID == "B2") {
                this.cursosEstadoB2 = estado;
                this.activarB2 = true;
              }
              this.estadoModuloIngles = new EstadoModuloIngles();
              this.estadoModuloIngles.UID = this.loginCom.usuarioSession.UID;
              this.estadoModuloIngles.cursosEstados = [];
            });
          } else {
            console.log('Estado curso no encontrado!');
          }
        })
        .catch(error => {
          console.log("Error verificando estados:", error);
        });*/
    }
  }

  irLecciones(cursoID: string) {    
    this.cursoID = cursoID;
    this.cursoService.buscarEstadoModuloInglesPorCurso(this.loginCom.usuarioSession.UID, cursoID)
      .then(doc => {
        if (doc.exists) {
          const data = doc.data() as CursosEstado;
          data.UID = doc.id;
          this.cursosEstado = data
        } else {
          this.cursosEstado = new CursosEstado();
          this.cursosEstado.UID = cursoID;
          this.cursosEstado.fraseOracion = 1;
          this.cursosEstado.leccionID = "leccion-1";
          this.cursoService.actualizarEstadoCurso(this.loginCom.usuarioSession.UID, cursoID, this.cursosEstado);
        }
        this.mostrarLecciones = true;
      })
      .catch(error => console.log(error));
  }

  continuarA1() {
    this.irLecciones("A1");
  }

  continuarA2() {
    this.irLecciones("A2");
  }

  continuarB1() {
    this.irLecciones("B1");
  }

  continuarB2() {
    this.irLecciones("B2");
  }

}
