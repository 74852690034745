export class FrasesOraciones {
    UID: string;
    orden: number;
    isCompuesta: boolean;
    permitirReplay: boolean;
    texto_1: string;
    texto_2: string;
    base64Texto_1?: string;
    base64Texto_2?: string;
    urlImagen: string;    
    languageCodeTexto_1:string;
    languageCodeTexto_2:string;
    ssmlGender_1:string;
    ssmlGender_2:string;
    constructor() {
        this.orden = null;
        this.isCompuesta = false;
        this.permitirReplay = false;
        this.texto_1 = null;
        this.texto_2 = null;
        this.urlImagen = null;
        this.UID = null;
        this.base64Texto_1 = null;
        this.base64Texto_2 = null;  
        this.languageCodeTexto_1 = null;
        this.languageCodeTexto_2 = null;
        this.ssmlGender_1 = null;
        this.ssmlGender_2 = null;
    }
}
