import { Component, OnInit } from '@angular/core';
import { Escuela } from '../modelo/escuela';
import { EstadoExamenOrientacion } from '../modelo/estado-examen-orientacion';
import { Municipio } from '../modelo/municipio';
import { ReporteModel } from '../modelo/reporte-model';
import { ReporteVisitasDiccionarioPro } from '../modelo/reporte-visitas-diccionario-pro';
import { Usuario } from '../modelo/usuario';
import { AuthService } from '../servicios/auth.service';
import { ComunesService } from '../servicios/comunes.service';
import { PinService } from '../servicios/pin.service';
import { UsuariosService } from '../servicios/usuarios.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  municipiosFiltrados: Municipio[];
  listaMunicipios: Municipio[];
  municipio: Municipio;
  listaEscuelas: Escuela[];
  listaReportes: ReporteModel[];
  listaUsuarios: Usuario[];

  constructor(private loginCom: AuthService, private comunesService: ComunesService,
    private usuariosService: UsuariosService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("reportes");
    } else {
      this.listaMunicipios = [];
      this.listaReportes = [];
      this.listaEscuelas = [];
      this.listaUsuarios = [];
      this.municipio = new Municipio();
      this.consultarMunicipios();
    }
  }

  revisarLista(){
    console.log("Lista Reportes: ", this.listaReportes);
  }

  buscarMunicipio(query): Municipio[] {
    const filtered: Municipio[] = [];
    for (let i = 0; i < this.listaMunicipios.length; i++) {
      const municipio = this.listaMunicipios[i];
      if (municipio.nombreCompleto.toLowerCase().includes(query.toLowerCase())) {
        var isNuevo: boolean = true;
        filtered.forEach(element => {
          if (element.nombreCompleto == municipio.nombreCompleto) {
            isNuevo = false;
          }
        });
        if (isNuevo) {
          filtered.push(municipio);
        }
      }
    }
    return filtered;
  }

  consultarMunicipios() {
    this.listaMunicipios = [];
    this.comunesService.consultarMunicipios()
      .then(snapshot => {
        snapshot.forEach(municipioDoc => {
          const municipio = municipioDoc.data() as Municipio;
          municipio.UID = municipioDoc.id;
          this.listaMunicipios.push(municipio);
        });
      })
      .catch(err => {
        console.log('Error consultando municipios!', err);
      });
  }

  filtrarMunicipios(event) {
    const query = event.query;
    this.municipiosFiltrados = [];
    if (this.listaMunicipios.length > 0) {
      this.municipiosFiltrados = this.buscarMunicipio(query);
    }
  }

  generarReporte() {
    this.listaReportes = [];
    this.comunesService.obtenerListaEscuelas(this.municipio.nombreCompleto)
      .then(snapshot => {
        snapshot.forEach(escuelaDoc => {
          const escuela = escuelaDoc.data() as Escuela;
          this.listaEscuelas.push(escuela);
        });
        if (this.listaEscuelas.length > 0) {
          
          this.listaEscuelas.forEach(escuela => {
            this.usuariosService.obtenerUsuariosByEscuela(escuela.nombreEscuela)
              .then(collEscuelas => {
                this.listaUsuarios = [];
                collEscuelas.forEach(user => {
                  const usuario = user.data() as Usuario;
                  usuario.UID = user.id;
                  this.listaUsuarios.push(usuario);
                });
                let countUser: number = 1;
                this.listaUsuarios.forEach(userRef => {
                  
                  let reporte : ReporteModel = new ReporteModel();
                  reporte.UID = countUser;
                  countUser = countUser + 1;
                  reporte.usuario = userRef;
                  this.comunesService.obtenerEstadoOrientacion(userRef.UID)
                    .then(orientacionRef => {
                      if (orientacionRef.exists) {
                        reporte.estadoExamenOrientacion = orientacionRef.data() as EstadoExamenOrientacion;
                        this.comunesService.obtenerReporteVisitasDiccionarioPrope(userRef.UID)
                          .then(diccRef => {
                            if (diccRef.exists) {
                              reporte.estadoDiccionarioPropedeutico = diccRef.data() as ReporteVisitasDiccionarioPro;
                              this.listaReportes.push(reporte);
                            }
                          })
                          .catch(err => {
                            console.log('Error consultando reporte visitas diccionario propedeutico!', err);
                          });
                      } else {
                        this.comunesService.obtenerReporteVisitasDiccionarioPrope(userRef.UID)
                          .then(diccRef => {
                            if (diccRef.exists) {
                              reporte.estadoDiccionarioPropedeutico = diccRef.data() as ReporteVisitasDiccionarioPro;
                              this.listaReportes.push(reporte);
                            }
                          })
                          .catch(err => {
                            console.log('Error consultando reporte visitas diccionario propedeutico!', err);
                          });
                      }
                    })
                    .catch(err => {
                      console.log('Error consultando estados de orientacion profesional!', err);
                    });
                });
              })
              .catch(err => {
                console.log('Error consultando usuarios!', err);
              });
          });
        }

      })
      .catch(err => {
        console.log('Error consultando escuelas!', err);
      });
  }



}
