import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { DiccionarioPropedeutico } from '../modelo/diccionario-propedeutico';
import { ReporteVisitasDiccionarioPro } from '../modelo/reporte-visitas-diccionario-pro';
import { AuthService } from '../servicios/auth.service';
import { DiccionarioPropedeuticoService } from '../servicios/diccionario-propedeutico.service';
import { PalabraDiccionario } from '../modelo/palabra-diccionario';

@Component({
  selector: 'app-diccionario-espanol',
  templateUrl: './diccionario-espanol.component.html',
  styleUrls: ['./diccionario-espanol.component.css']
})
export class DiccionarioEspanolComponent implements OnInit {

  idDiccionario: string;
  modulosItems: SelectItem[];
  isSelectedDiccionario: boolean;
  reporteVisitas: ReporteVisitasDiccionarioPro;
  datoBusqueda: any;
  palabrasFiltradas: PalabraDiccionario[];
  totalPalabras: number;
  totalFilas: number;
  listaPalabrasGeneral: PalabraDiccionario[];

  constructor(private loginCom: AuthService,
    private diccionarioService: DiccionarioPropedeuticoService) {
    this.reporteVisitas = new ReporteVisitasDiccionarioPro();
  }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("diccionario-propedeutico");
    } else {
      this.init();
    }
  }

  init() {
    this.totalPalabras = 0;
    this.totalFilas = 0;
    this.palabrasFiltradas = [];
    this.listaPalabrasGeneral = [];
    this.modulosItems = [];
    this.buscarDiccionario();
  }

  limpiarFiltro() {
    this.datoBusqueda = "";
    this.totalPalabras = this.listaPalabrasGeneral.length;
    let modHojas = this.listaPalabrasGeneral.length % 10;
    this.totalFilas = modHojas !== 0 ? (((this.listaPalabrasGeneral.length - modHojas) / 10) + 1) : this.listaPalabrasGeneral.length;
    this.paginar(0, 0);
  }

  onChangeSelectDiccionario(event) {
    this.isSelectedDiccionario = false;
    if (this.idDiccionario !== "0") {
      this.isSelectedDiccionario = true;
      this.buscarDiccionario();
    }
  }

  paginate(event) {
    this.paginar(event.first, event.page);
  }

  paginar(primerElemento: number, numeroPagina: number) {
    this.palabrasFiltradas = [];
    let paginaActual = numeroPagina + 1;
    for (let i = primerElemento; i < (primerElemento + (paginaActual !== this.totalFilas ? 10 : (this.listaPalabrasGeneral.length % 10))); i++) {
      let palabra = this.listaPalabrasGeneral[i];
      this.palabrasFiltradas.push(palabra);
    }
  }

  buscarDiccionario() {
    this.diccionarioService.buscarPalabrasAll()
      .then(listaPalabras => {
        listaPalabras.forEach(diccionarioRef => {
          const data = diccionarioRef.data() as DiccionarioPropedeutico;
          if (data.nombre.trim() === 'General') {
            data.palabras.forEach(element => {
              this.listaPalabrasGeneral.push(element);
            });
          }
        });
        this.totalPalabras = this.listaPalabrasGeneral.length;
        let modHojas = this.listaPalabrasGeneral.length % 10;
        this.totalFilas = modHojas !== 0 ? (((this.listaPalabrasGeneral.length - modHojas) / 10) + 1) : this.listaPalabrasGeneral.length;
        this.paginar(0, 0);
      })
      .catch(error => console.log(error));
  }

  filtadroAvanzadoPalabras(event) {
    this.palabrasFiltradas = [];
    let query = event.query;
    this.palabrasFiltradas = this.filtroPalabra(query, this.listaPalabrasGeneral);
    this.totalPalabras = this.palabrasFiltradas.length;
  }

  filtroPalabra(query, palabras: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < palabras.length; i++) {
      let palabra = palabras[i];
      if (palabra.palabra.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(palabra);
      }
    }
    return filtered;
  }

  actualizarReporteVisitas() {
    this.diccionarioService.buscarReporteActual(this.loginCom.usuarioSession.UID)
      .then(referencia => {
        if (referencia.exists) {
          const data = referencia.data() as ReporteVisitasDiccionarioPro;
          this.reporteVisitas = data;
        } else {
          this.reporteVisitas = new ReporteVisitasDiccionarioPro();
        }
        this.reporteVisitas.numeroVisitas = this.reporteVisitas.numeroVisitas + 1;
        this.reporteVisitas.fechaUltimoIngreso = new Date();
        console.log("reporteVisitas:", this.reporteVisitas);
        this.diccionarioService.actualizarEstadoDiccionario(this.loginCom.usuarioSession.UID, this.reporteVisitas);
      })
      .catch(error => console.log(error));
  }

}
