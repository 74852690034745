import { Component, OnInit } from '@angular/core';
import { SelectItem, MenuItem } from 'primeng/primeng';
import { Pin } from '../modelo/pin';
import { Municipio } from '../modelo/municipio';
import { PinService } from '../servicios/pin.service';
import { ProcesosMasivosService } from '../servicios/procesos-masivos.service';
import { ComunesService } from '../servicios/comunes.service';
import { Message } from 'primeng/primeng';
import { MessageService } from 'primeng/api';
import { AuthService } from '../servicios/auth.service';
import { ConfigPines } from '../modelo/config-pines';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Escuela } from '../modelo/escuela';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.css'],
  providers: [MessageService]
})
export class PinComponent implements OnInit {

  municipio: Municipio;
  pin: Pin;
  cantidadPines: number;
  municipiosFiltrados: Municipio[];
  escuelasFiltradas: Escuela[] = [];
  listaMunicipios: Municipio[];
  listaPines: Pin[];
  pinFiltrados: Pin[];
  isGenerarPins: boolean;
  tiposUsuarios: SelectItem[];
  listaModulos: SelectItem[];
  modulos: Array<String>[];
  tipoUsuario: string;
  nombrePin: string;
  isBuscarPin: boolean;
  listaPinesImprimir: Pin[];
  cols: any[];
  buscarPinModel: Pin;
  msgs: Message[];
  listaEscuelas: Escuela[] = [];
  escuelaModel: any;

  constructor(private pinService: PinService, private procesosMasivosService: ProcesosMasivosService,
    private comunesService: ComunesService, private loginCom: AuthService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("pin");
    } else {
      this.pin = new Pin();
      this.municipio = new Municipio();
      this.listaPines = [];
      this.isGenerarPins = false;
      this.isBuscarPin = false;
      this.cols = [
        { field: 'UID', header: 'PIN' },
        { field: 'nombrePin', header: 'Nombre Pin' },
        { field: 'tipo', header: 'Tipo' },
        { field: 'municipio', header: 'Municipio' },
        { field: 'escuela', header: 'Escuela' }
      ];
      //CONSULAT TODOS LOS PINES
      this.consultarPines();
      //CARGAR LISTA MUNICIPIOS
      this.consultarMunicipios();
      //CARGAR TIPOS DE USUARIOS
      this.cargarTiposUsuarios();
      //CARGAR LISTA MODULOS
      this.cargarListaModulos();
    }
  }

  consultarPin() {
    this.isGenerarPins = false;
    this.isBuscarPin = true;
    this.listaPinesImprimir = [];
    this.pinService.obtenerPinsPorNombrePin(this.buscarPinModel.nombrePin)
      .then(lista => {
        lista.forEach(pinDoc => {
          const pin = pinDoc.data() as Pin;
          pin.UID = pinDoc.id;
          this.listaPinesImprimir.push(pin);
        });
      })
      .catch(error => {
        console.log("Error conconsultando pines para: ", this.buscarPinModel);
        console.log(error);
      });
  }

  nuevoPin() {
    this.isGenerarPins = true;
    this.isBuscarPin = false;
  }

  cargarTiposUsuarios() {
    this.tiposUsuarios = [];
    this.tiposUsuarios.push({ label: 'Seleccione', value: '' });
    this.tiposUsuarios.push({ label: 'Estudiante', value: 'estudiante' });
    this.tiposUsuarios.push({ label: 'Supervisor', value: 'supervisor' });
  }

  cargarListaModulos() {
    this.listaModulos = [
      { label: 'Curso Ingles', value: 'Curso Ingles' },
      { label: 'Pruebas Saber', value: 'Pruebas Saber' },
      { label: 'Orientación', value: 'Orientación' },
      { label: 'Cultura General', value: 'Cultura General' },
      { label: 'Diccionario Propedéutico', value: 'Diccionario Propedéutico' }
    ];
  }

  filtrarMunicipios(event) {
    this.escuelaModel = new Escuela();
    const query = event.query;
    this.municipiosFiltrados = [];
    if (this.listaMunicipios.length > 0) {
      this.municipiosFiltrados = this.buscarMunicipio(query);
    }
  }

  filtrarPins(event) {
    const query = event.query;
    this.pinFiltrados = [];
    if (this.listaPines.length > 0) {
      this.pinFiltrados = this.buscarPin(query);
    }
  }

  buscarPin(query): Pin[] {
    const filtered: Pin[] = [];
    for (let i = 0; i < this.listaPines.length; i++) {
      const pin = this.listaPines[i];
      if (pin.nombrePin.toLowerCase().includes(query.toLowerCase())) {
        var isNuevo: boolean = true;
        filtered.forEach(element => {
          if (element.nombrePin == pin.nombrePin) {
            isNuevo = false;
          }
        });
        if (isNuevo) {
          filtered.push(pin);
        }
      }
    }
    return filtered;
  }

  buscarMunicipio(query): Municipio[] {
    const filtered: Municipio[] = [];
    for (let i = 0; i < this.listaMunicipios.length; i++) {
      const municipio = this.listaMunicipios[i];
      if (municipio.nombreCompleto.toLowerCase().includes(query.toLowerCase())) {
        var isNuevo: boolean = true;
        filtered.forEach(element => {
          if (element.nombreCompleto == municipio.nombreCompleto) {
            isNuevo = false;
          }
        });
        if (isNuevo) {
          filtered.push(municipio);
        }
      }
    }
    return filtered;
  }

  buscarEscuela(query): Escuela[] {
    const filtered: Escuela[] = [];
    for (let i = 0; i < this.listaEscuelas.length; i++) {
      const escuela = this.listaEscuelas[i];
      if (escuela.nombreEscuela.toLowerCase().includes(query.toLowerCase())) {
        var isNuevo: boolean = true;
        filtered.forEach(element => {
          if (element.nombreEscuela == escuela.nombreEscuela
            && element.municipio === this.municipio.nombreCompleto) {
            isNuevo = false;
          }
        });
        if (isNuevo) {
          filtered.push(escuela);
        }
      }
    }
    return filtered;
  }

  consultarMunicipios() {
    this.listaMunicipios = [];
    this.comunesService.consultarMunicipios()
      .then(snapshot => {
        snapshot.forEach(municipioDoc => {
          const municipio = municipioDoc.data() as Municipio;
          municipio.UID = municipioDoc.id;
          this.listaMunicipios.push(municipio);
        });
      })
      .catch(err => {
        console.log('Error consultando municipios!', err);
      });
  }

  filtrarEscuelas(event) {
    this.listaEscuelas = [];
    this.comunesService.obtenerListaEscuelas(this.municipio.nombreCompleto)
      .then(snapshot => {
        snapshot.forEach(escuelaDoc => {
          const escuela = escuelaDoc.data() as Escuela;
          this.listaEscuelas.push(escuela);
        });
        const query = event.query;
        this.escuelasFiltradas = [];
        if (this.listaEscuelas.length > 0) {
          this.escuelasFiltradas = this.buscarEscuela(query);
        }
      })
      .catch(err => {
        console.log('Error consultando escuelas!', err);
      });
  }

  consultarPines() {
    this.listaPines = [];
    this.pinService.obtenerAllPins()
      .then(snapshot => {
        snapshot.forEach(pinDoc => {
          const pin = pinDoc.data() as Pin;
          pin.UID = pinDoc.id;
          this.listaPines.push(pin);
        });
      })
      .catch(err => {
        console.log('Error consultando pins!', err);
      });
  }

  iniciarCargue() {
    this.procesosMasivosService.cargarLecciones();
  }

  generarPin() {
    this.buscarPinModel = new Pin();
    this.msgs = [];
    if(this.escuelaModel.nombreEscuela !== undefined){
      this.pin.escuela = this.escuelaModel.nombreEscuela.toUpperCase();
    } else {
      this.pin.escuela = this.escuelaModel.toUpperCase();
    }    
    this.pin.modulos = "";
    this.modulos.forEach(element => {
      this.pin.modulos = this.pin.modulos + element + ",";
    });
    if (this.pin.modulos.length > 0) {
      this.pin.modulos = this.pin.modulos.substring(0, this.pin.modulos.length - 1);
    }
    this.pin.municipio = this.municipio.nombreCompleto;
    this.pin.tipo = this.tipoUsuario;
    this.pin.nombrePin = this.nombrePin;
    this.pinService.generarPin(this.pin, this.cantidadPines);
    this.guardarDatosEscuela(this.pin);
    this.isBuscarPin = false;
    this.isGenerarPins = false;
    this.msgs.push({ severity: 'success', summary: 'Creación exitosa!.', detail: 'Nombre asignado: ' + this.pin.nombrePin + '.     .' });
    this.consultarPines();
  }

  guardarDatosEscuela(pin: Pin) {
    this.comunesService.consultarEscuela(pin.municipio, pin.escuela)
      .then(docRef => {
        let isGuardar : boolean = true;
        docRef.forEach(element => {
          if(element.exists){
            isGuardar = false;
          } 
        });  
        if(isGuardar){
          this.comunesService.guardarEscuela(this.pin.municipio, this.pin.escuela);
        }  
      })
      .catch(error => {
        console.error("Error writing guardarEscuela() ", error);
      });
  }

}
