import { EstadoExamenOrientacion } from "./estado-examen-orientacion";
import { ReporteVisitasDiccionarioPro } from "./reporte-visitas-diccionario-pro";
import { Usuario } from "./usuario";

export class ReporteModel {
    UID: number;
    usuario: Usuario;
    estadoExamenOrientacion: EstadoExamenOrientacion;
    estadoDiccionarioPropedeutico: ReporteVisitasDiccionarioPro;
    constructor(){
        this.UID = 0;
        this.usuario = new Usuario();
        this.estadoDiccionarioPropedeutico = new ReporteVisitasDiccionarioPro();
        this.estadoExamenOrientacion = new EstadoExamenOrientacion();
    }
}
