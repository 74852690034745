export class EstadoUsuarioDiccionario {
    UID: string;
    totalPalabras: number;
    ultimaTraduccion: string;
    constructor() {
        this.UID = null;
        this.totalPalabras = null;
        this.ultimaTraduccion = null;
    }
}
