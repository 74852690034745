import { PalabraDiccionario } from "./palabra-diccionario";

export class DiccionarioPropedeutico {

    nombre: String;
    palabras: PalabraDiccionario[];

    constructor(){
        this.nombre = "";
        this.palabras = [];
    }
}
