import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService  } from './servicios/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    constructor(public auth: AuthService, public app: AppComponent) {}

}
