import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { DiccionarioPropedeutico } from '../modelo/diccionario-propedeutico';
import { ReporteVisitasDiccionarioPro } from '../modelo/reporte-visitas-diccionario-pro';
import { AuthService } from '../servicios/auth.service';
import { DiccionarioPropedeuticoService } from '../servicios/diccionario-propedeutico.service';

@Component({
  selector: 'app-diccionario-propedeutico',
  templateUrl: './diccionario-propedeutico.component.html',
  styleUrls: ['./diccionario-propedeutico.component.css']
})
export class DiccionarioPropedeuticoComponent implements OnInit {

  idDiccionario: string;
  diccionarioPropedeutico: DiccionarioPropedeutico;
  modulosItems: SelectItem[];
  isSelectedDiccionario: boolean;
  reporteVisitas: ReporteVisitasDiccionarioPro;

  constructor(private loginCom: AuthService,
    private diccionarioService: DiccionarioPropedeuticoService) {
    this.isSelectedDiccionario = false;
    this.diccionarioPropedeutico = new DiccionarioPropedeutico();
    this.reporteVisitas = new ReporteVisitasDiccionarioPro();
  }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("diccionario-propedeutico");
    } else {
      this.init();
    }
  }

  init() {
    this.modulosItems = [];
    this.modulosItems.push({ value: "0", label: "Seleccione diccionario..." });
    this.modulosItems.push({ value: "administracion-empresas", label: "Administración de Empresas" });
    this.modulosItems.push({ value: "arquitectura", label: "Arquitectura" });
    this.modulosItems.push({ value: "diccionario-agropercuario", label: "Agropecuario" });
    this.modulosItems.push({ value: "economia", label: "Economia" });
    this.modulosItems.push({ value: "educacion-fisica-deportes", label: "Educación Física y Deportes" });
    this.modulosItems.push({ value: "gastronomico", label: "Gastronómico" });
    this.modulosItems.push({ value: "ingenieria-ambiental", label: "Ingeniería Ambiental" });
    this.modulosItems.push({ value: "ingenieria-civil", label: "Ingeniería Civil" });
    this.modulosItems.push({ value: "ingenieria-electronica", label: "Ingeniería Electrónica" });
    this.modulosItems.push({ value: "ingenieria-informatica", label: "Ingeniería Informática" });
    this.modulosItems.push({ value: "ingenieria-mecanica", label: "Ingeniería Mecánica" });
    this.modulosItems.push({ value: "legal", label: "Legal" });
    this.modulosItems.push({ value: "minas", label: "Minas" });
    this.modulosItems.push({ value: "medico", label: "Medico" });
    this.modulosItems.push({ value: "odontologico", label: "Odontológico" });
    this.modulosItems.push({ value: "petrolero", label: "Petrolero" });
    this.modulosItems.push({ value: "psicologico", label: "Psicológico" });
    this.modulosItems.push({ value: "turistico", label: "Turístico" });
    this.modulosItems.push({ value: "veterinaria", label: "Veterinaría" });
  }

  onChangeSelectDiccionario(event) {
    this.isSelectedDiccionario = false;
    if (this.idDiccionario !== "0") {
      this.isSelectedDiccionario = true;
      this.buscarDiccionario();
    }
  }

  buscarDiccionario() {
    this.diccionarioService.buscarPalabras(this.idDiccionario)
      .then(diccionarioRef => {
        const data = diccionarioRef.data() as DiccionarioPropedeutico;
        this.idDiccionario = diccionarioRef.id;
        this.diccionarioPropedeutico = data;
        this.actualizarReporteVisitas();        
      })
      .catch(error => console.log(error));
  }

  actualizarReporteVisitas() {
    this.diccionarioService.buscarReporteActual(this.loginCom.usuarioSession.UID)
      .then(referencia => {
        if(referencia.exists){
          const data = referencia.data() as ReporteVisitasDiccionarioPro;
          this.reporteVisitas = data;
        } else {
          this.reporteVisitas = new ReporteVisitasDiccionarioPro();
        }
        this.reporteVisitas.numeroVisitas = this.reporteVisitas.numeroVisitas + 1;
        this.reporteVisitas.fechaUltimoIngreso = new Date();        
        console.log("reporteVisitas:", this.reporteVisitas);        
        this.diccionarioService.actualizarEstadoDiccionario(this.loginCom.usuarioSession.UID, this.reporteVisitas);
      })
      .catch(error => console.log(error));
  }

}
