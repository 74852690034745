export class ResultadoPreguntas {
    numero: number;
    pregunta: string;
    respuestaExitosa: string;
    respuestaIncorrecta: string;
    isCorrecta: boolean;
    constructor(){
        this.numero = null;
        this.pregunta = "";
        this.respuestaExitosa = "";
        this.respuestaIncorrecta = "";
        this.isCorrecta = false;
    }
}
