export class CursosEstado {
    UID?: string;
    isIniciado?: boolean;
    leccionID?: string;
    fraseOracion?: number;
    constructor(){
        this.UID = null;
        this.isIniciado = false;
        this.leccionID = null;
        this.fraseOracion = null;
    }
}
