export class ChasidePregunta {
    grupo: string;
    numero: number;
    pregunta: string;
    respuesta: boolean;
    constructor() {
        this.numero = 0;
        this.pregunta = "";
        this.grupo = "";
        this.respuesta = false;
    }
}
