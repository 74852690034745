export class Municipio {
    UID: string;
    nombre: string;
    nombreCompleto: string;
    constructor() {
        this.UID = null;
        this.nombre = "";
        this.nombreCompleto = "";
    }
}
