import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { EstadoActualExamenSaber } from '../modelo/estado-actual-examen-saber';
import { PruebasSaberConfi } from '../modelo/pruebas-saber-confi';

@Injectable({
  providedIn: 'root'
})
export class PruebasSaberService {

  constructor(private afs: AngularFirestore) { }

  buscarEstadoActualExamen(usuarioID: string) {
    return this.afs.firestore.collection("pruebasSaber").doc("estadoActualExamenSaber")
      .collection("estados").doc(usuarioID).get();
  }

  buscarPreguntaPorNumero(cuardernilloID: string, numero: number) {
    return this.afs.firestore.collection("pruebasSaber").doc("cuadernillos")
      .collection("cuadernos").doc(cuardernilloID).collection("preguntas").where("numero", "==", numero).get();
  }

  buscarPreguntasPorCuadernillo(cuardernilloID: string) {
    return this.afs.firestore.collection("pruebasSaber").doc("cuadernillos")
      .collection("cuadernos").doc(cuardernilloID).collection("preguntas").get();
  }

  buscarCuadernillos() {
    return this.afs.firestore.collection("pruebasSaber").doc("cuadernillos").collection("cuadernos").get();
  }

  actualizarEstadoExamen(estadoID: string, listaExamenes: EstadoActualExamenSaber[]) {
    var lista: any[] = [];
    listaExamenes.forEach(element => {
      var objeto = {
        fechaExamenActual: element.fechaExamenActual,
        numeroItento: element.numeroItento,
        puntajeActual: element.puntajeActual,
        puntajeMaximoActual: element.puntajeMaximoActual,
        modulo: element.modulo !== undefined ? element.modulo : "",
        UID: element.UID
      }
      lista.push(objeto);
    });
    return this.afs.firestore.collection("pruebasSaber").doc("estadoActualExamenSaber")
      .collection("estados").doc(estadoID)
      .set({
        listaExamenes: lista
      })
      .catch(error => {
        console.error("Error writing actualizarEstadoExamen() ", error);
      });
  }

  buscarPruebasSaberConfi() {
    return this.afs.firestore.collection("pruebasSaber").doc("pruebasSaberConfi").get();
  }

  actualizarPruebasSaberConfi(pruebasSaberConfi: PruebasSaberConfi) {
    this.afs.firestore.collection("pruebasSaber").doc("pruebasSaberConfi")
      .set({
        horasExamen: pruebasSaberConfi.horasExamen,
        minutosExamen: pruebasSaberConfi.minutosExamen,
        numeroPreguntasExamen: pruebasSaberConfi.numeroPreguntasExamen
      })
      .catch(error => {
        console.error("Error writing actualizarPruebasSaberConfi() ", error);
      });
  }

}
