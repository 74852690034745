import { FrasesOraciones } from '../modelo/frases-oraciones';

export class Leccion {
    UID: string;
    introduccion: Object;
    numero: number;
    frasesOraciones: FrasesOraciones[];
    titulo: Object;
    tieneExamen: boolean;
    constructor() {
        this.titulo = { textoEspanol: "", textoIngles: "", urlAudioEspanol: "", urlAudioIngles: "", base64Texto_1: "", base64Texto_2: "" };
        this.introduccion = { textoEspanol: "", textoIngles: "", urlAudioEspanol: "", urlAudioIngles: "" };
        this.numero = null;
        this.frasesOraciones = null;
        this.tieneExamen = false;
        this.UID = null;
    }
}
