import { Injectable } from '@angular/core';
import { EstadoExamenOrientacion } from '../modelo/estado-examen-orientacion';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class OrientacionService {

  constructor(private afs: AngularFirestore) { }

  buscarEstadoActualExamen(usuarioID: string) {
    return this.afs.firestore.collection("orientacion").doc("estadoActualExamen")
      .collection("estados").doc(usuarioID).get();
  }

  guardarEstadoExamen(estadoID: string, estado: EstadoExamenOrientacion) {
    return this.afs.firestore.collection("orientacion").doc("estadoActualExamen")
      .collection("estados").doc(estadoID)
      .set({
        numeroPregunta: estado.numeroPregunta,
        isFinalizado: estado.isFinalizado,
        areasAplicables: estado.areasAplicables,
        listaPreguntas: estado.listaPreguntas,
        fechaUltimoTest: estado.fechaUltimoTest,
        numeroIntentos: estado.numeroIntentos
      })
      .catch(error => {
        console.error("Error writing guardarEstadoExamen() ", error);
      });
  }

  buscarPreguntas() {
    return this.afs.firestore.collection("orientacion").doc("CHASIDE")
      .collection("preguntas").orderBy("numero").get();
  }

  buscarAreas() {
    return this.afs.firestore.collection("orientacion").doc("CHASIDE")
      .collection("areas").get();
  }

  buscarArea(UID: string) {
    return this.afs.firestore.collection("orientacion").doc("CHASIDE")
      .collection("areas").doc(UID).get();
  }

}
