export class EstadoActualExamenSaber {
    UID: string;
    puntajeMaximoActual: number;
    puntajeActual: number;
    fechaExamenActual: Date;
    numeroItento: number;
    numeroPreguntasModulo: number;
    modulo: string;
    constructor() {
        this.UID = null;
        this.puntajeMaximoActual = 0;
        this.puntajeActual = 0;
        this.fechaExamenActual = null;
        this.numeroItento = 0;
        this.numeroPreguntasModulo = 0;
        this.modulo = "";
    }
}
