import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { PruebasSaberService } from '../servicios/pruebas-saber.service';
import { EstadoActualExamenSaber } from '../modelo/estado-actual-examen-saber';
import { Cuadernillo } from '../modelo/cuadernillo';
import { Pregunta } from '../modelo/pregunta';
import { Respuesta } from '../modelo/respuesta';
import { PruebasSaberConfi } from '../modelo/pruebas-saber-confi';
import { AuthService } from '../servicios/auth.service';
import { Demo } from '../modelo/demo';

@Component({
  selector: 'app-pruebas-saber',
  templateUrl: './pruebas-saber.component.html',
  styleUrls: ['./pruebas-saber.component.css']
})
export class PruebasSaberComponent implements OnInit {

  private estadoExamen: EstadoActualExamenSaber;
  private pruebasConfi: PruebasSaberConfi;
  private labelBtnRealizarExamen: string;
  private isHacerExamen: boolean;
  private cuadernillos: Cuadernillo[];
  private cuadernillosFinal: Cuadernillo[];
  private cuadernilloActual: Cuadernillo;
  private preguntaActual: Pregunta = new Pregunta();
  private respuestaActual: Respuesta;
  private indiceCuadernillos: number;
  private isInicioExamen: boolean;
  private numeroPregunta: number;
  private numeroCuadernillo: number;
  private preguntasExitosas: number;
  private preguntasExitosasXTres: number;
  private preguntasExitosasXDos: number;
  private preguntasExitosasXUno: number;
  private isExamenFinalizado: boolean;
  private preguntasAleatorias: number[];
  public cargarDatosBasicos: boolean;
  public displayDetalleExamen: boolean;
  public listaParrafosResumen: string[] = [];
  public listaParrafosPregunta: string[] = [];
  public respuestaEstudiante: number = 0;
  public totalPreguntasAllCuadernillos: number = 0;
  public totalPreguntasExitosas: number = 0;
  public listaExamenes: EstadoActualExamenSaber[];
  public listaMostrar: EstadoActualExamenSaber[];
  sortKey: string;
  sortField: string;
  sortOrder: number;
  sortOptions: SelectItem[];
  numeroPreguntaActual: number = 1;
  numeroPreguntaActualExamen: number = 1;
  public listaParrafosRespuesta: string[] = [];
  isLoading: boolean;
  modulosItems: SelectItem[];
  moduloSeleccionado: string;
  deshabilitarBotonExamen: boolean;
  porcentageLeccion: number;
  esEntero: boolean;
  totalPreguntas: number;
  puntajeMaximoAMostrar: number;


  constructor(private loginCom: AuthService, private pruebasSaberService: PruebasSaberService) { }

  ngOnInit() {
    this.deshabilitarBotonExamen = true;
    this.modulosItems = [];
    this.modulosItems.push({ label: "Seleccione cuadernillos...", value: "0" });
    this.modulosItems.push({ label: "Todos", value: "todos" });
    this.isLoading = false;
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("pruebasSaber");
    } else {
      this.esEntero = true;
      this.isExamenFinalizado = false;
      this.displayDetalleExamen = false;
      this.cargarDatosBasicos = false;
      this.preguntasAleatorias = [];
      this.cuadernillos = [];
      this.estadoExamen = new EstadoActualExamenSaber();
      this.sortOptions = [
        { label: 'Más Recientes', value: '!fechaExamenActual' },
        { label: 'Más Antiguos', value: 'fechaExamenActual' },
        { label: 'Puntaje', value: 'puntajeActual' }
      ];
      this.pruebasSaberService.buscarCuadernillos()
        .then(lista => {
          lista.forEach(element => {
            const data = element.data() as Cuadernillo;
            data.UID = element.id;
            this.cuadernillos.push(data);
            this.cargarSelectModulos(data);
          });
          this.init();
          this.cargarDatosBasicos = true;
        });
    }
  }

  onChangeSelectCuadernillo(event) {
    if (this.moduloSeleccionado === "0") {
      this.deshabilitarBotonExamen = true;
    } else {
      this.deshabilitarBotonExamen = false;
    }
  }

  cargarSelectModulos(cuadernillo: Cuadernillo) {
    this.modulosItems.push({ label: cuadernillo.nombre, value: cuadernillo.UID });
  }

  showDetalleExamen() {
    var lista = this.listaExamenes;
    this.listaMostrar = [];
    lista.forEach(element => {
      var estado = new EstadoActualExamenSaber();
      estado.fechaExamenActual = element.fechaExamenActual;
      estado.modulo = element.modulo;
      estado.numeroItento = element.numeroItento;
      estado.puntajeActual = element.puntajeActual;
      estado.puntajeMaximoActual = element.puntajeMaximoActual;
      this.listaMostrar.push(estado);
    });
    this.displayDetalleExamen = true;
  }

  iniciarPreguntasAleatorias() {
    this.preguntasAleatorias = [];
    let numeroPreguntasExamen = 20;
    if (this.moduloSeleccionado !== "todos") {
      numeroPreguntasExamen = 40;
    }
    this.cargarNumeroAleatorio(numeroPreguntasExamen);
    this.cargarPreguntasCuadernilloFinal();
  }

  cargarNumeroAleatorio(numeroPreguntasPorCuadernillo: number) {
    var aleatorio = Math.round(Math.random() * 100);
    if (this.preguntasAleatorias.length <= 50) {
      var agregar = true;
      this.preguntasAleatorias.forEach(element => {
        if (aleatorio == element) {
          agregar = false;
        }
      });
      if (agregar) {
        this.preguntasAleatorias.push(aleatorio);
      }
      this.cargarNumeroAleatorio(50);
    }
  }

  init() {
    this.clearRespuestas();
    this.totalPreguntas = 0;
    this.preguntasExitosasXTres = 1;
    this.preguntasExitosasXDos = 1;
    this.preguntasExitosasXUno = 1;
    this.numeroPreguntaActual = 1;
    this.numeroPreguntaActualExamen = 1;
    this.preguntasExitosas = 0;
    this.indiceCuadernillos = 0;
    this.numeroCuadernillo = 0;
    this.numeroPregunta = 0;
    this.isInicioExamen = true;
    this.isHacerExamen = false;
    this.estadoExamen = new EstadoActualExamenSaber();
    this.labelBtnRealizarExamen = "Realizar Examen";
    this.listaExamenes = [];
    //SE CONSULTA EL ESTADO ACTUAL DEL EXAMEN
    this.pruebasSaberService.buscarEstadoActualExamen(this.loginCom.usuarioSession.UID)
      .then(doc => {
        const data = doc.data();
        if (!doc.exists) {
          this.estadoExamen = this.crearEstadoExamen();
          this.puntajeMaximoAMostrar = 500;
          this.listaExamenes.push(this.estadoExamen);
          this.pruebasSaberService.actualizarEstadoExamen(this.loginCom.usuarioSession.UID, this.listaExamenes);
        } else {
          this.listaExamenes = data.listaExamenes;
          this.estadoExamen = this.listaExamenes[this.listaExamenes.length - 1];
          this.puntajeMaximoAMostrar = 500;
          if (this.estadoExamen.modulo !== undefined) {
            if (this.estadoExamen.modulo !== "Todos") {
              this.puntajeMaximoAMostrar = 100;
            }
          }
        }
        if (this.estadoExamen.numeroItento > 0) {
          this.labelBtnRealizarExamen = "Realizar Nuevo Examen";
        }
        //COSULTAR LA CONFIGURACIÓN ACTUAL DEL MODULO DE PRUEBAS
        this.pruebasSaberService.buscarPruebasSaberConfi()
          .then(conf => {
            const data2 = conf.data() as PruebasSaberConfi;
            this.pruebasConfi = data2;
            this.iniciarPreguntasAleatorias();
          });
      })
      .catch(error => {
        console.log("Error en buscarEstadoActualExamen()", error);
      });
  }

  realizarExamen() {
    this.puntajeMaximoAMostrar = 100;
    if (this.moduloSeleccionado === "todos") {
      this.puntajeMaximoAMostrar = 500;
      this.totalPreguntas = 100;
      this.totalPreguntasAllCuadernillos = this.cuadernillos.length * this.pruebasConfi.numeroPreguntasExamen;
    } else {
      this.totalPreguntas = 40;
      this.totalPreguntasAllCuadernillos = this.pruebasConfi.numeroPreguntasModulo;
    }
    this.isHacerExamen = true;
    this.isExamenFinalizado = false;
    this.cargarPreguntaActual();
  }

  continuar() {
    this.isLoading = true;
    this.numeroPreguntaActual++;
    this.numeroPreguntaActualExamen++;
    if (this.moduloSeleccionado !== "todos") {
      if (this.numeroPreguntaActualExamen > this.pruebasConfi.numeroPreguntasExamen) {
        this.numeroPreguntaActualExamen = 1;
      }
    }
    this.validarRespuesta();
    this.cargarPreguntaActual();
    this.isLoading = false;
  }

  clearRespuestas() {
    this.respuestaEstudiante = 0;
  }

  validarRespuesta() {
    if (this.preguntaActual.numeroRespuestaCorrecta == this.respuestaEstudiante) {
      if (this.moduloSeleccionado !== "todos") {
        if (this.numeroPregunta <= 10) {
          this.preguntasExitosas = this.preguntasExitosas + 1.5;
        } else if (this.numeroPregunta > 10 && this.numeroPregunta <= 25) {
          this.preguntasExitosas = this.preguntasExitosas + 3;
        } else if (this.numeroPregunta > 25 && this.numeroPregunta <= 40) {
          this.preguntasExitosas = this.preguntasExitosas + 2;
        }
      } else {
        //this.preguntasExitosas = this.preguntasExitosas + 5;
        this.calcularValorRespuestaExitosa500();
      }
    }
    this.clearRespuestas();
  }

  calcularValorRespuestaExitosa500() {
    if (this.numeroPregunta <= 5
      || (this.numeroPregunta > 20 && this.numeroPregunta <= 25)
      || (this.numeroPregunta > 40 && this.numeroPregunta <= 45)
      || (this.numeroPregunta > 60 && this.numeroPregunta <= 65)
      || (this.numeroPregunta > 80 && this.numeroPregunta <= 85)) {
      this.preguntasExitosas = this.preguntasExitosas + 8;
    } else if ((this.numeroPregunta > 5 && this.numeroPregunta <= 10)
      || (this.numeroPregunta > 25 && this.numeroPregunta <= 30)
      || (this.numeroPregunta > 45 && this.numeroPregunta <= 50)
      || (this.numeroPregunta > 65 && this.numeroPregunta <= 70)
      || (this.numeroPregunta > 85 && this.numeroPregunta <= 90)) {
      this.preguntasExitosas = this.preguntasExitosas + 5;
    } else if ((this.numeroPregunta > 10 && this.numeroPregunta <= 15)
      || (this.numeroPregunta > 30 && this.numeroPregunta <= 35)
      || (this.numeroPregunta > 50 && this.numeroPregunta <= 55)
      || (this.numeroPregunta > 70 && this.numeroPregunta <= 75)
      || (this.numeroPregunta > 90 && this.numeroPregunta <= 95)) {
      this.preguntasExitosas = this.preguntasExitosas + 4;
    } else if ((this.numeroPregunta > 15 && this.numeroPregunta <= 20)
      || (this.numeroPregunta > 35 && this.numeroPregunta <= 40)
      || (this.numeroPregunta > 55 && this.numeroPregunta <= 60)
      || (this.numeroPregunta > 75 && this.numeroPregunta <= 80)
      || (this.numeroPregunta > 95)) {
      this.preguntasExitosas = this.preguntasExitosas + 3;
    }
  }

  onSortChange(event) {
    let value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  finalizarExamen() {
    var porcentajeRespuesta = parseInt(((100 * this.preguntasExitosas) / this.totalPreguntasAllCuadernillos) + '');
    var puntajeExamen = parseInt(((this.pruebasConfi.puntajeMaximo * porcentajeRespuesta) / 100) + '');
    this.preguntasExitosas = Math.trunc(this.preguntasExitosas);
    this.totalPreguntasExitosas = this.preguntasExitosas;
    //this.preguntasExitosas = parseInt(this.preguntasExitosas + '');
    if (this.estadoExamen.numeroItento == 0) {
      this.listaExamenes = [];
    }
    //SE CREA EL NUEVO ESTADO DE EXAMEN
    let estadoExamenFinal = new EstadoActualExamenSaber();
    estadoExamenFinal.puntajeActual = this.preguntasExitosas;
    estadoExamenFinal.fechaExamenActual = new Date();
    estadoExamenFinal.numeroItento = this.listaExamenes.length + 1;
    estadoExamenFinal.puntajeMaximoActual = this.pruebasConfi.puntajeMaximo;
    estadoExamenFinal.modulo = "Todos";
    if (this.moduloSeleccionado !== "todos") {
      this.cuadernillosFinal.forEach(element => {
        if (this.moduloSeleccionado === element.UID) {
          estadoExamenFinal.modulo = element.nombre;
        }
      });
    }
    this.listaExamenes.push(estadoExamenFinal);
    this.isExamenFinalizado = true;
    //ACTUALIZAR EL ESTADO ACTUAL DE EXAMENES DEL ESTUDIANTE
    this.pruebasSaberService.actualizarEstadoExamen(this.loginCom.usuarioSession.UID, this.listaExamenes)
      .then(result => {
        this.init();
      });
  }

  crearEstadoExamen() {
    var estadoExamen = new EstadoActualExamenSaber();
    estadoExamen.fechaExamenActual = new Date();
    estadoExamen.numeroItento = 0;
    estadoExamen.puntajeActual = 0;
    estadoExamen.puntajeMaximoActual = 0;
    estadoExamen.UID = this.loginCom.usuarioSession.UID;
    return estadoExamen;
  }

  cargarPreguntasCuadernilloFinal() {
    this.cuadernillosFinal = [];
    this.cuadernillos.forEach(cuadernillo => {
      cuadernillo.preguntas = [];
      this.preguntasAleatorias.forEach(element => {
        this.pruebasSaberService.buscarPreguntaPorNumero(cuadernillo.UID, element)
          .then(lista => {
            lista.forEach(preguntaDoc => {
              const data = preguntaDoc.data() as Pregunta;
              data.UID = preguntaDoc.id;
              cuadernillo.preguntas.push(data);
            });
          })
          .catch(error => {
            console.log("Error buscarPreguntaPorNumero:", element);
          });
      });
      this.cuadernillosFinal.push(cuadernillo);
    });
  }

  retornarPreguntaActual(indice: number): Cuadernillo {
    let cuadernillo: Cuadernillo;
    if (this.moduloSeleccionado !== "todos") {
      this.cuadernillosFinal.forEach(element => {
        if (this.moduloSeleccionado === element.UID) {
          cuadernillo = element;
        }
      });
    } else {
      cuadernillo = this.cuadernillosFinal[indice];
    }
    return cuadernillo;
  }

  cargarPreguntaActual() {
    this.listaParrafosResumen = [];
    this.listaParrafosPregunta = [];
    this.listaParrafosRespuesta = [];
    let numeroPreguntaModulo: number;
    numeroPreguntaModulo = this.numeroPregunta;
    if (this.isInicioExamen) {
      this.numeroPregunta = 1;
      numeroPreguntaModulo = this.numeroPregunta;
      this.numeroCuadernillo = 1;
      this.cuadernilloActual = this.retornarPreguntaActual(0);
      this.preguntaActual = this.cuadernilloActual.preguntas[0];
      this.isInicioExamen = false;
      this.numeroPregunta++;
    } else {
      var totalCuadernillos = this.cuadernillos.length;
      //VALIDAR SI ES LA ULTIMA PREGUNTA DEL CUADERNILLO ACTUAL
      if ((this.numeroPregunta - 1) == this.totalPreguntas) {
        this.finalizarExamen();
      } else {
        //aumentar en 1 el numero de pregunta.
        if (this.moduloSeleccionado == "todos") {
          if (this.numeroPregunta <= 20) {
            totalCuadernillos = 0;
          } else if (this.numeroPregunta > 20 && this.numeroPregunta <= 40) {
            totalCuadernillos = 1;
            numeroPreguntaModulo = this.numeroPregunta - 20;
            if (this.numeroPregunta == 21) {
              numeroPreguntaModulo = 1;
            }
          } else if (this.numeroPregunta > 40 && this.numeroPregunta <= 60) {
            totalCuadernillos = 2;
            numeroPreguntaModulo = this.numeroPregunta - 40;
            if (this.numeroPregunta == 41) {
              numeroPreguntaModulo = 1;
            }
          } else if (this.numeroPregunta > 60 && this.numeroPregunta <= 80) {
            totalCuadernillos = 3;
            numeroPreguntaModulo = this.numeroPregunta - 60;
            if (this.numeroPregunta == 61) {
              numeroPreguntaModulo = 1;
            }
          } else if (this.numeroPregunta > 80 && this.numeroPregunta <= 100) {
            totalCuadernillos = 4;
            numeroPreguntaModulo = this.numeroPregunta - 80;
            if (this.numeroPregunta == 81) {
              numeroPreguntaModulo = 1;
            }
          }
        }
        this.cuadernilloActual = this.retornarPreguntaActual(totalCuadernillos);
        if (this.moduloSeleccionado === "todos") {
          this.preguntaActual = this.cuadernilloActual.preguntas[numeroPreguntaModulo - 1];
        } else {
          this.preguntaActual = this.cuadernilloActual.preguntas[this.numeroPregunta - 1];
        }

        this.numeroPregunta++;
      }
    }
    this.calcularPorcentajeExamen();

    if (this.preguntaActual.textoResumen != null && this.preguntaActual.textoResumen != "") {
      this.preguntaActual.textoResumen = this.preguntaActual.textoResumen.replace(/--XXXXX--/g, " ");
      this.listaParrafosResumen.push(this.preguntaActual.textoResumen);
    }
    if (this.preguntaActual.textoPregunta != null && this.preguntaActual.textoPregunta != "") {
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/--XXXXX--/g, " ");
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/- /g, "");
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/-XXXXX--XXXXX--/g, "");
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/ -XXXXX--De lo que busca cada una de las organi-XXXXX--60. El socialismo busca la igualdad social, -XXXXX--zaciones mencionadas se puede afirmar que/g, "De lo que busca cada una de las organizaciones mencionadas se puede afirmar que");
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/agr cultura/g, "agricultura");
      this.preguntaActual.textoPregunta = this.preguntaActual.textoPregunta.replace(/-XXXXX--/g, "");
      this.listaParrafosPregunta.push(this.preguntaActual.textoPregunta);
    }

    if (this.preguntaActual.citaTextoPregunta !== null && this.preguntaActual.citaTextoPregunta !== "") {
      this.preguntaActual.citaTextoPregunta = this.preguntaActual.citaTextoPregunta.replace(/--XXXXX--/g, " ");
    }
    if (this.preguntaActual.citaTextoResumen !== null && this.preguntaActual.citaTextoResumen !== "") {
      this.preguntaActual.citaTextoResumen = this.preguntaActual.citaTextoResumen.replace(/--XXXXX--/g, " ");
    }

    if (this.preguntaActual != null && this.preguntaActual.respuestas != null
      && this.preguntaActual.respuestas.length > 0) {
      let respuestasNuevas: Respuesta[] = [];
      let resAux = this.preguntaActual.respuestas;
      this.preguntaActual.respuestas = [];
      resAux.forEach(element => {
        if (element.textoRespuesta !== null && element.textoRespuesta !== "") {
          element.textoRespuesta = element.textoRespuesta.replace(/--XXXXX--/g, " ");
          element.textoRespuesta = element.textoRespuesta.replace(/- /g, "");
        }
        if (element.citaTexto !== null && element.citaTexto !== "") {
          element.citaTexto = element.citaTexto.replace(/--XXXXX--/g, " ");
        }
        respuestasNuevas.push(element);
      });
      this.preguntaActual.respuestas = respuestasNuevas;
    }
  }

  realizarPruebas() {
    let demo1: Demo = new Demo();
    demo1.nombre = "Demo 1";

    let demo2: Demo = new Demo();
    demo2.nombre = "Demo 2";

    demo1.listaDemo.set("demo2", demo2);
    this.loginCom.demoCrear(demo1);
  }

  calcularPorcentajeExamen() {
    this.esEntero = true;
    this.porcentageLeccion = 0;
    let porcentaje: number;
    porcentaje = this.numeroPreguntaActual - 1;
    if (porcentaje > 0) {
      this.porcentageLeccion = (100 * porcentaje) / this.totalPreguntasAllCuadernillos;
    }
    if (!Number.isInteger(this.porcentageLeccion)) {
      this.esEntero = false;
    }
  }


}
