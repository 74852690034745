import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstadoModuloIngles } from '../modelo/estado-modulo-ingles';
import { CursosEstado } from '../modelo/cursos-estado';

@Injectable({
  providedIn: 'root'
})
export class CursoService {

  private estadoModulosInglesCollection: AngularFirestoreCollection<EstadoModuloIngles>;
  listaEstadoModulosIngles: Observable<EstadoModuloIngles[]>;
  private estadoModuloInglesDoc: AngularFirestoreDocument<EstadoModuloIngles>;
  estadoModuloIngles: Observable<EstadoModuloIngles>;

  constructor(private afs: AngularFirestore) { }

  buscarEstadoModuloIngles3() {


  }

  buscarEstadoModuloIngles2() {
    this.estadoModulosInglesCollection = this.afs.collection<EstadoModuloIngles>('estadoModulosIngles');
    this.estadoModulosInglesCollection.snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as EstadoModuloIngles;
          const id = a.payload.doc.id;
          return { id, ...data }
        }))
      ).toPromise().then(valor => {
      }).catch(err => {
        console.log('Error getting document22222----------', err);
      });
  }

  buscarEstadoModuloIngles(usuarioID: string, cursoID: string) {
    return this.afs.firestore.collection("estadoModulosIngles").doc(usuarioID).collection("cursosEstados").doc(cursoID);
  }

  buscarEstadoActualModuloIngles(usuarioID: string) {
    return this.afs.firestore.collection("estadoModulosIngles").doc(usuarioID).collection("cursosEstados").get();
  }

  buscarEstadoModuloInglesPorCurso(usuarioID: string, cursoID: string) {
    return this.afs.firestore.collection("estadoModulosIngles").doc(usuarioID)
      .collection("cursosEstados")
      .doc(cursoID)
      .get();
  }

  buscarCursoIngles(uid: string) {
    return this.afs.firestore.collection("cursosIngles").doc(uid);
  }

  actualizarEstadoCurso(usuarioID: string, cursoID: string, cursoEstado: CursosEstado) {
    this.afs.collection("estadoModulosIngles").doc(usuarioID)
      .collection("cursosEstados").doc(cursoID)
      .set({
        fraseOracion: cursoEstado.fraseOracion,
        isIniciado: cursoEstado.isIniciado,
        leccionID: cursoEstado.leccionID
      })
      .catch(error => {
        console.error("Error writing actualizarEstadoCurso() ", error);
      });
  }

  guardarEstadoCurso(usuarioID: string, cursoID: string, cursoEstado: CursosEstado) {
    this.afs.collection("estadoModulosIngles").doc(usuarioID)
      .collection("cursosEstados")
      .add({
        fraseOracion: cursoEstado.fraseOracion,
        isIniciado: cursoEstado.isIniciado,
        leccionID: cursoEstado.leccionID
      })
      .catch(error => {
        console.error("Error writing actualizarEstadoCurso() ", error);
      });
  }


}
