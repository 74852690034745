import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { isDuration } from 'moment';
import { ReporteVisitasDiccionarioPro } from '../modelo/reporte-visitas-diccionario-pro';

@Injectable({
  providedIn: 'root'
})
export class DiccionarioPropedeuticoService {
  
  constructor(private afs: AngularFirestore) { }

  buscarPalabras(idDiccionario: string) {
    return this.afs.firestore.collection("diccionarioPropedeutico").doc(idDiccionario).get();
  }

  buscarPalabrasAll() {
    return this.afs.firestore.collection("diccionarioPropedeutico").get();
  }

  buscarReporteActual(idUsuario: string) {
    return this.afs.firestore.collection("reporteVisitasDiccionarioPro").doc(idUsuario).get();
  }

  actualizarEstadoDiccionario(idUsuario: string, reporte: ReporteVisitasDiccionarioPro){
    return this.afs.firestore.collection("reporteVisitasDiccionarioPro").doc(idUsuario)
      .set({
        numeroVisitas: reporte.numeroVisitas,
        fechaUltimoIngreso: reporte.fechaUltimoIngreso
      })
      .catch(error => {
        console.error("Error writing actualizarEstadoDiccionario() ", error);
      });    
  }

}
