import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProfesionIdealService {

  constructor(private afs: AngularFirestore) { }

  buscarPaginas() {
    return this.afs.firestore.collection("orientacion/profesion-ideal/profesiones").orderBy("orden").get();
  }

}
