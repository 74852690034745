import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Leccion } from '../modelo/leccion';
import { FrasesOraciones } from '../modelo/frases-oraciones';

@Injectable({
  providedIn: 'root'
})
export class LeccionesService {

  constructor(private afs: AngularFirestore, private http: HttpClient) { }

  obtenerListaLeccionesPorCurso(cursoID: string) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID).collection("lecciones").orderBy("numero", "asc").get();
  }

  obtenerLeccionesPorCursoAndLeccionID(cursoID: string, leccionID: string) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID).collection("lecciones").doc(leccionID).get();
  }

  obtenerFrasesOracionPorLeccion(cursoID: string, leccionID: string) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID)
      .collection("lecciones").doc(leccionID).collection("frasesOraciones").orderBy("orden", "asc").get();
  }

  obtenerFrasesOracionPorLeccionLimit(cursoID: string, leccionID: string, limit: number) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID)
      .collection("lecciones").doc(leccionID).collection("frasesOraciones")
      .orderBy("orden", "asc").limit(1)
      .get();
  }

  buscarTodasLasOracionesPorLeccion(cursoID: string, leccionID: string) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID)
      .collection("lecciones").doc(leccionID).collection("frasesOraciones")
      .get();
  }

  obtenerFrasesOracionPorLeccionAndFraseOrden(cursoID: string, leccionID: string, orden: number) {    
    return this.afs.firestore.collection("cursosIngles").doc(cursoID)
      .collection("lecciones").doc(leccionID).collection("frasesOraciones").where("orden", "==", orden).get();
  }

  convertirTextoToAudio2(texto: string, languageCode: string, token: string, ssmlGender: string) {
    console.log("convertirTextoToAudio: texto languageCode token =>", texto, languageCode, token);
    const endpoint = 'https://texttospeech.googleapis.com/v1/text:synthesize';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'apiKey': 'xKVivgeSR-CbyDU0YjtCJ7Cz',
        'clientId': '1024695533025-g5lsfdrmlb0m2lfucucpo66r0acfbf8j.apps.googleusercontent.com'
      })
    };
    var objTexto = {
      "input": {
        "text": texto
      },
      "voice": {
        "languageCode": languageCode,
        "ssmlGender": ssmlGender
      },
      "audioConfig": {
        "audioEncoding": "LINEAR16"
      }
    };
    return this.http.post<any>(endpoint, JSON.stringify(objTexto), httpOptions);
  }

  convertirTextoToAudio(texto: string, languageCode: string, token: string, ssmlGender: string) {    
    const endpoint = 'https://texttospeech.googleapis.com/v1/text:synthesize';
    /*const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };*/
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': 'AIzaSyDNfU-Gx_JpkkeABbs6pf52oHZDYHoYeP4'
      })
    }
    var objTexto = {
      "input": {
        "text": texto
      },
      "voice": {
        "languageCode": languageCode,
        "ssmlGender": ssmlGender
      },
      "audioConfig": {
        "audioEncoding": "LINEAR16"
      }
    };
    return this.http.post<any>(endpoint, JSON.stringify(objTexto), httpOptions);
  }

  guardarAudioBase64ToLeccion(cursoID: string, leccion: Leccion) {
    return this.afs.firestore.collection("cursosIngles").doc(cursoID).collection("lecciones").doc(leccion.UID)
      .update({ titulo: leccion.titulo })
      .catch(error => {
        console.log("Error guardarAudioBase64ToLeccion:", error);
      });
  }

  guardarAudioBase64ToFrase(cursoID: string, leccionID: string, frase: FrasesOraciones) {    
    return this.afs.firestore.collection("cursosIngles").doc(cursoID).collection("lecciones").doc(leccionID)
      .collection("frasesOraciones").doc(frase.UID)
      .update({ base64Texto_1: frase.base64Texto_1, base64Texto_2: frase.base64Texto_2 })
      .catch(error => {
        console.log("Error guardarAudioBase64ToFrase:", error);
      });
  }

}
