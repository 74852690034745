import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { Usuario } from '../modelo/usuario';
import { Pin } from '../modelo/pin';
import { isLong } from 'long';
import { Demo } from '../modelo/demo';


@Injectable()
export class AuthService {

  private usuarioDoc: AngularFirestoreDocument<Usuario>;
  private usuariosCollection: AngularFirestoreCollection<Usuario>;
  usuario: Observable<Usuario>;
  usuarios: Observable<Usuario[]>;
  usuarioSession: Usuario;
  isLogged: boolean;
  enProcesoRegistro: boolean;
  esProfesionIdeal: boolean;
  listaModulosUno: string[] = [];
  listaModulosDos: string[] = [];

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth,
    private router: Router) {
    this.isLogged = false;
    this.enProcesoRegistro = false;
    this.esProfesionIdeal = false;
  }

  validateStatusLogin(pagina: string) {
    this.esProfesionIdeal = false;
    this.isLogged = false;
    let nav = "/";
    this.afAuth.auth.onAuthStateChanged(user => {
      if (!this.enProcesoRegistro) {
        if (user) {
          console.log("Usuario con sessión activa!");
          this.getUsuario(user.uid)
            .subscribe(userCompleto => {
              if (this.validarCaducidadUsuario(userCompleto) && userCompleto.estado == "Activo") {
                this.onSuccess(user, userCompleto);
                //console.log("curren user: ", this.afAuth.auth.currentUser);
                //console.log("curren user: ", userCompleto);
                this.afAuth.auth.currentUser.getIdToken().then(dato => {
                  //console.log("dato:", dato);
                  this.usuarioSession.token = dato;
                  this.validarModulosActivos(userCompleto);
                })
              } else {
                this.logout();
              }
              nav = nav + pagina;
              if (pagina == "profesion-ideal") {
                this.esProfesionIdeal = true;
              }
              this.router.navigate([nav]);
            });
        } else {
          console.log("Usuario con sessión inactiva!");
          if (pagina !== "login") {
            this.irLogin();
          }
        }
      }
    });
  }

  validarModulosActivos(user: Usuario){
    this.listaModulosUno = [];
    this.listaModulosDos = [];
    if(this.usuarioSession.tipoUsuario === 'estudiante'){
      //let modulos: string[] = this.usuarioSession.modulos.split(",");
      let listaMofulosTemp = "Curso Ingles,Diccionario Propedéutico,Pruebas Saber,Orientación,Cultura General";
      let modulos: string[] = listaMofulosTemp.split(",");
      let count: number = 0;
      modulos.forEach(element => {
        if(count<4){
          this.listaModulosUno.push(element);
        } else {
          this.listaModulosDos.push(element);
        }
        count++;
      });
    }
  }

  onSuccess(userFirebase: any, usuarioCompleto: Usuario) {
    this.usuarioSession = usuarioCompleto;
    this.usuarioSession.UID = userFirebase.uid;
    this.usuarioSession.urlFoto = userFirebase.photoURL;
    this.usuarioSession.isAnonimo = userFirebase.isAnonymous;
    this.usuarioSession.isEmailVerificado = userFirebase.emailVerified;
    this.usuarioSession.token = userFirebase.refreshToken;
    this.isLogged = true;
    //console.log("this.isLogged:", this.isLogged);
  }

  login(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  getUsuario(id: string) {
    this.usuarioDoc = this.afs.doc<Usuario>('usuarios/' + id);
    return this.usuario = this.usuarioDoc.valueChanges();
  }

  registrarUsuario(usuario: Usuario, password: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(usuario.email, password);
  }

  agregarUsuarioCompleto(usuario: Usuario) {
    var data = {
      apellido: usuario.apellido,
      email: usuario.email,
      escuela: usuario.escuela,
      estado: "Activo",
      modulos: usuario.modulos,
      nombre: usuario.nombre,
      pin: usuario.pin,
      tipoUsuario: usuario.tipoUsuario,
      //fechaFin: usuario.fechaFin,
      caduca: false
    };
    return this.afs.firestore.collection("usuarios").doc(usuario.UID)
      .set(data);
  }

  validarCaducidadUsuario(usuario: Usuario): boolean {
    let resultado = true;
    if (usuario.caduca) {
      let hoy = new Date();
      if (hoy > usuario.fechaFin) {
        resultado = false;
      }
    }
    console.log("Login - authService.listaModulosUno:", this.listaModulosUno);
    return resultado;
  }

  irLogin() {
    this.router.navigate(['/']);
  }

  logout() {
    console.log("logout...");
    this.afAuth.auth.signOut()
      .then((data) => {
        console.log("logout exitoso");
        this.isLogged = false;
        this.usuarioSession = null;
        this.enProcesoRegistro = false;
        this.irLogin();
      });
  }

  enviarEmailVerificacion(user: any) {
    return user.sendEmailVerification();
  }

  reestablerPasswordPorEamil(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  demoCrear(demo: Demo) {
    //const budgets = demo.listaDemo.map((demo2)=> {return Object.assign({}, demo)}); 
    var data = {
      nombre: demo.nombre,
      listaDemo: demo.listaDemo
    };
    return this.afs.firestore.collection("demos").doc("demo3")
      .set(data);
  }

}
