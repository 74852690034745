import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private afs: AngularFirestore) { }

  obtenerUsuariosByEscuela(nombreEscuela: string){
    var usersRef = this.afs.firestore.collection('usuarios');
    return usersRef.where('escuela', '==', nombreEscuela).get();
  }
}
