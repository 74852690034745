import { Component, OnInit } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { LeccionesService } from '../servicios/lecciones.service';
import { DiccionarioService } from '../servicios/diccionario.service';
import { Palabra } from '../modelo/palabra';
import { EstadoUsuarioDiccionario } from '../modelo/estado-usuario-diccionario';
import { DiccionarioConfig } from '../modelo/diccionario-config';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'app-diccionario',
  templateUrl: './diccionario.component.html',
  styleUrls: ['./diccionario.component.css']
})
export class DiccionarioComponent implements OnInit {


  palabra: Palabra;
  estadoUsuario: EstadoUsuarioDiccionario;
  diccionarioConfig: DiccionarioConfig;
  msgs: Message[];
  monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
  ];

  constructor(private loginCom: AuthService, private leccionesService: LeccionesService,
    private diccionarioService: DiccionarioService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("diccionario");
    } else {
      this.init();
    }
  }

  init() {

    this.palabra = new Palabra();
    this.diccionarioService.bucarConfiguracion()
      .then(configDoc => {
        if (configDoc.exists) {
          const data = configDoc.data() as DiccionarioConfig;
          this.diccionarioConfig = data;
          this.diccionarioService.bucarEstadoActualUsuario(this.loginCom.usuarioSession.UID)
            .then(estadoUserDoc => {
              if (estadoUserDoc.exists) {
                const estado = estadoUserDoc.data() as EstadoUsuarioDiccionario;
                estado.UID = estadoUserDoc.id;
                //var fecha = estado.ultimaTraduccion;
                /*this.estadoUsuario = new EstadoUsuarioDiccionario();
                this.estadoUsuario.UID = estado.UID;
                this.estadoUsuario.totalPalabras = estado.totalPalabras;*/
                //console.log("fecha de actualizacion:", fecha);
                var fechaActual = new Date();
                //console.log("fechaActual -fomrmat:", fechaActual.toString('dd-MMM-yyyy'));
                console.log(this.formatDate(fechaActual, 'dd-MM-yyyy'));
                this.estadoUsuario = estado;
                console.log("fecha de actualizacion - prase:", this.estadoUsuario.ultimaTraduccion);
              } else {
                this.estadoUsuario = new EstadoUsuarioDiccionario();
                this.estadoUsuario.UID = this.loginCom.usuarioSession.UID;
                this.estadoUsuario.totalPalabras = 0;
                this.estadoUsuario.ultimaTraduccion = this.formatDate(new Date(), 'dd-MM-yyyy');
                this.diccionarioService.guardarEstadoUsuario(this.estadoUsuario);
              }
            })
            .catch(error => {
              /*this.estadoUsuario = new EstadoUsuarioDiccionario();
              this.estadoUsuario.UID = this.loginCom.usuarioSession.UID;
              this.estadoUsuario.totalPalabras = 0;
              this.estadoUsuario.ultimaTraduccion = new Date();
              this.diccionarioService.guardarEstadoUsuario(this.estadoUsuario);*/
              console.log(error);
            });
        }
      })
      .catch(error => console.log(error));
  }



  formatDate(date, patternStr) {
    if (!patternStr) {
      patternStr = 'M/d/yyyy';
    }
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = this.twoDigitPad(h),
      HH = this.twoDigitPad(hour),
      mm = this.twoDigitPad(minute),
      ss = this.twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = this.dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = this.twoDigitPad(day),
      M = month + 1,
      MM = this.twoDigitPad(M),
      MMMM = this.monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
      ;
    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh).replace('h', h)
      .replace('HH', HH).replace('H', hour)
      .replace('mm', mm).replace('m', minute)
      .replace('ss', ss).replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd).replace('d', day)

      .replace('EEEE', EEEE).replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
    }
    else {
      patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
    }
    return patternStr;
  }

  twoDigitPad(num) {
    return num < 10 ? "0" + num : num;
  }



  validarPermisoTraducir(): boolean {
    var fechaActual = new Date();
    var primerDiaMesActual = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    var ultimoDiaMesActual = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
    console.log("fechaActual:", fechaActual);
    console.log("primerDiaMesActual:", primerDiaMesActual);
    console.log("ultimoDiaMesActual:", ultimoDiaMesActual);
    console.log("this.estadoUsuario.ultimaTraduccion:", this.estadoUsuario.ultimaTraduccion);
    var fechaUltimaActualizacion = new Date(this.estadoUsuario.ultimaTraduccion);
    console.log("fechaUltimaActualizacion:", fechaUltimaActualizacion);

    if (fechaUltimaActualizacion >= primerDiaMesActual &&
      fechaUltimaActualizacion <= ultimoDiaMesActual) {
      console.log("debug 1");
      if (this.estadoUsuario.totalPalabras >= this.diccionarioConfig.palabrasPorMes) {
        console.log("debug 2");
        console.log("No permitida la traducción.");
        return false;
      }
    } else {
      console.log("debug 3");
      this.estadoUsuario.totalPalabras = 0;
      this.estadoUsuario.ultimaTraduccion = this.formatDate(new Date(), 'dd-MM-yyyy');
      this.diccionarioService.guardarEstadoUsuario(this.estadoUsuario);
    }
    console.log("debug 4");
    console.log("Permitir traduccion...");
    return true;
  }

  onKey(event: any) {
    var texto = this.palabra.palabra.trim();
    this.palabra = new Palabra();
    this.palabra.palabra = texto;
  }

  traducir(idioma: string) {
    this.msgs = [];
    console.log("idioma:", idioma);
    this.diccionarioService.buscarPalabra(this.palabra.palabra.toUpperCase())
      .then(lista => {
        if (lista.size != null && lista.size > 0) {
          lista.forEach(element => {
            const data = element.data() as Palabra;
            data.uid = element.id;
            this.palabra = data;
          });
        } else {
          var palabra = this.palabra.palabra.toUpperCase();
          this.palabra = new Palabra();
          this.palabra.palabra = palabra;
        }

        console.log("palabra ingresada:", this.palabra.palabra);
        this.traducirTexto(this.palabra.palabra, idioma);
        /*if (lista.size != null && lista.size == 0) {
          if (this.validarPermisoTraducir()) {
            this.palabra.palabra = this.palabra.palabra.toUpperCase();
            console.log("palabra ingresada:", this.palabra.palabra);
            this.traducirTexto(this.palabra.palabra, idioma);
          } else {
            this.msgs.push({ severity: 'error', summary: 'Palabras Excedidas!', detail: 'Ha alcanzado el número máximo de palabras a traducir por mes.' });
            this.msgs.push({ severity: 'error', summary: 'Palabras Excedidas!', detail: 'El próxmo mes podrá volver a traducir palabras.' });
          }
        }*/
      })
      .catch(error => console.log(error));
  }

  traducirTexto(texto: string, idioma: string) {
    switch (idioma) {
      case 'ingles':
        if (this.palabra.ingles == null || this.palabra.ingles == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "en")
            .subscribe(traduccionDoc => {
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.ingles = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      case 'frances':
        if (this.palabra.frances == null || this.palabra.frances == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "fr")
            .subscribe(traduccionDoc => {
              console.log("texto traducir: ", traduccionDoc);
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.frances = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      case 'aleman':
        if (this.palabra.aleman == null || this.palabra.aleman == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "de")
            .subscribe(traduccionDoc => {
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.aleman = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      case 'portugues':
        if (this.palabra.portugues == null || this.palabra.portugues == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "pt")
            .subscribe(traduccionDoc => {
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.portugues = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      case 'italiano':
        if (this.palabra.italiano == null || this.palabra.italiano == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "it")
            .subscribe(traduccionDoc => {
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.italiano = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      case 'mandarin':
        if (this.palabra.mandarin == null || this.palabra.mandarin == "") {
          this.diccionarioService.traducirTexto(this.palabra.palabra, "zh")
            .subscribe(traduccionDoc => {
              console.log("texto traducir: ", traduccionDoc);
              var data = traduccionDoc["data"];
              var translations = data["translations"];
              translations.forEach(element => {
                var translatedText = element["translatedText"];
                this.palabra.mandarin = translatedText;
              });
              this.convertirTextoAudio(this.palabra.palabra, idioma);
            });
        }
        break;
      /*case 'mandarin':
        languageCode = "en-US";
        break;*/
      default:
        console.log("idioma no definido");
    }



  }

  convertirTextoAudio(texto: string, idioma: string) {
    console.log("convertirTextoAudio - texto:", texto);
    console.log("convertirTextoAudio - idioma:", idioma);
    console.log("convertirTextoAudio - this.palabra:", this.palabra);
    let languageCode: string;
    switch (idioma) {
      case 'ingles':
        languageCode = "en-US";
        if (this.palabra.audioBase64Ingles == null || this.palabra.audioBase64Ingles == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.ingles.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              console.log("Audio res:", res);
              this.palabra.audioBase64Ingles = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      case 'frances':
        languageCode = "fr-FR";
        if (this.palabra.audioBase64Frances == null || this.palabra.audioBase64Frances == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.frances.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              this.palabra.audioBase64Frances = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      case 'aleman':
        languageCode = "de-DE";
        if (this.palabra.audioBase64Aleman == null || this.palabra.audioBase64Aleman == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.aleman.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              this.palabra.audioBase64Aleman = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      case 'portugues':
        languageCode = "pt-BR";
        if (this.palabra.audioBase64Portugues == null || this.palabra.audioBase64Portugues == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.portugues.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              this.palabra.audioBase64Portugues = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      case 'italiano':
        languageCode = "it-IT";
        if (this.palabra.audioBase64intaliano == null || this.palabra.audioBase64intaliano == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.italiano.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              this.palabra.audioBase64intaliano = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      case 'mandarin':
        languageCode = "zh-TW";
        if (this.palabra.audioBase64Mandarin == null || this.palabra.audioBase64Mandarin == "") {
          this.leccionesService.convertirTextoToAudio(this.palabra.mandarin.toUpperCase(), languageCode, this.loginCom.usuarioSession.token, "FEMALE")
            .subscribe(res => {
              this.palabra.audioBase64Mandarin = res.audioContent;
              if (this.palabra.uid != null && this.palabra.uid != "") {
                this.diccionarioService.actualizarPalabra(this.palabra);
              } else {
                this.diccionarioService.guardarPalabra(this.palabra)
                  .then(ref => {
                    this.palabra.uid = ref.id;
                  });
              }
            });
        }
        break;
      default:
        console.log("idioma no definido");
    }

  }

  reproducirAudio(idioma: string) {
    switch (idioma) {
      case 'ingles':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64Ingles);
        audioTituloTexto_1.play();
        break;
      case 'frances':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64Frances);
        audioTituloTexto_1.play();
        break;
      case 'aleman':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64Aleman);
        audioTituloTexto_1.play();
        break;
      case 'portugues':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64Portugues);
        audioTituloTexto_1.play();
        break;
      case 'italiano':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64intaliano);
        audioTituloTexto_1.play();
        break;
      case 'mandarin':
        var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.palabra.audioBase64Mandarin);
        audioTituloTexto_1.play();
        break;
      default:
        break;
    }
  }

}
