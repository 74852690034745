import { Component, OnInit } from '@angular/core';
import { Usuario } from '../modelo/usuario';
import { AuthService } from '../servicios/auth.service';
import { Message, SelectItem } from 'primeng/primeng';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Pin } from '../modelo/pin';
import { PinService } from '../servicios/pin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  auth: AuthService;
  public usuarioSession: Usuario;
  password: string;
  email: string;
  objeto: any;
  msgs: Message[];
  isRegistro: boolean;
  pin: Pin;
  pinVerificado: boolean;
  isRegistroExitoso: boolean;
  tiposDocumentos: SelectItem[];
  forInvalido: boolean;
  usuario: Usuario;
  

  constructor(private authService: AuthService, private router: Router, private pinService: PinService) { }

  ngOnInit() {
    if (!this.authService.isLogged) {
      this.authService.validateStatusLogin("");
    }
  }  

  cargaInicialRegistrarse() {
    console.log("iniciando regisro");
    this.tiposDocumentos = [];
    this.tiposDocumentos.push(
      { label: "Seleccione...", value: "0" },
      { label: "Tarjeta de Identidad", value: "Tarjeta de Identidad" },
      { label: "Cédula de Ciudadanía", value: "Cédula de Ciudadanía" },
      { label: "Cédula de Extranjería", value: "Cédula de Extranjería" }
    );
    this.usuario = new Usuario();
    this.pin = new Pin();
    this.pinVerificado = false;
    this.isRegistroExitoso = false;
    console.log("fin registro");
  }

  irRegistrarse() {
    console.log("ir a registro");
    this.authService.enProcesoRegistro = true;
    this.cargaInicialRegistrarse();
    this.isRegistro = true;
  }

  regresarLogin() {
    this.authService.enProcesoRegistro = false;
    this.isRegistro = false;
  }

  continuarA1() {
    this.router.navigate(['/curso-a1']);
  }

  continuarA2() {
    this.router.navigate(['/curso-a2']);
  }

  continuarB1() {
    this.router.navigate(['/curso-b1']);
  }

  continuarB2() {
    this.router.navigate(['/curso-b2']);
  }

  diccionario() {
    this.router.navigate(['/diccionario']);
  }

  testIngles() {
    this.router.navigate(['/examenes']);
  }

  cursoElemental() {
    this.router.navigate(['/curso-elemental']);
  }

  PS() {
    this.router.navigate(['/pruebasSaber']);
  }

  aptitudVerbal() {
    this.router.navigate(['/aptitud']);
  }

  testCulturaGeneral() {
    this.router.navigate(['/cultura-general']);
  }

  OP() {
    this.router.navigate(['/orientacion']);
  }

  profesionIdeal() {
    this.router.navigate(['/profesion-ideal']);
  }

  diccionarioPropedeutico() {
    this.router.navigate(['/diccionario-propedeutico']);
  }

  diccionarioEspanol() {
    this.router.navigate(['/diccionario-espanol']);
  }

  reportes() {
    this.router.navigate(['/reportes']);
  }


  login() {
    this.msgs = [];
    if (this.email != null && this.email != "" && this.password != null && this.password != "") {
      this.authService.login(this.email, this.password)
        .then(dato => {
          this.authService.getUsuario(dato.user.uid)
            .subscribe(userCompleto => {
              console.log("Login - dataFirebase:", dato);
              console.log("Login - userCompleto:", userCompleto);
              if (userCompleto.estado == "Activo") {
                if (this.authService.validarCaducidadUsuario(userCompleto)) {
                  this.authService.onSuccess(dato.user, userCompleto);
                  console.log("Login - authService.listaModulosUno:", this.authService.listaModulosUno);
                  
                  if(userCompleto.tipoUsuario === "supervisor"){
                    this.reportes();
                  }
                } else {
                  this.msgs = [];
                  this.msgs.push({ severity: 'error', summary: 'Fecha Expirada.', detail: 'Su ingresó al sistema expiró.' });
                }
              } else {
                this.msgs = [];
                this.msgs.push({ severity: 'error', summary: 'Estado usuario no permitido.', detail: userCompleto.estado });
              }
            });
        })
        .catch(error => {
          console.log("Error login", error);
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == 'auth/invalid-email') {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'Formato de email inválido.', detail: '' });
          } else {
            if (errorCode == 'auth/user-disabled') {
              this.msgs = [];
              this.msgs.push({ severity: 'error', summary: 'El usuario está bloquedado.', detail: 'Comuniquese con el administrador.' });
            } else {
              if (errorCode == 'auth/user-not-found') {
                this.msgs = [];
                this.msgs.push({ severity: 'error', summary: 'El email no es válido.', detail: '' });
              } else {
                if (errorCode == 'auth/wrong-password') {
                  this.msgs = [];
                  this.msgs.push({ severity: 'error', summary: 'La contraseña no es válida.', detail: 'Intente nuevamente' });
                } else {
                  console.log("Error en registro de usuario:", errorMessage);
                  this.msgs = [];
                  this.msgs.push({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Comuniquese con el administrador.' });
                }
              }
            }
          }
        });
    } else {
      this.msgs = [];
      if (this.email == null || this.email == "") {
        this.msgs.push({ severity: 'error', summary: 'Email!', detail: 'Campo obligatorio.' });
      }
      if (this.password == null || this.password == "") {
        this.msgs.push({ severity: 'error', summary: 'Password!', detail: 'Campo obligatorio.' });
      }
    }

  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }



  registrarUsuario() {
    this.authService.registrarUsuario(this.usuario, this.password)
      .then(dato => {
        console.log("Usuario registrado exitosamente.");
        this.msgs.push({ severity: 'success', summary: 'Usuario registrado exitosamente.', detail: '' });
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
          this.msgs.push({ severity: 'error', summary: 'La contraseña es muy débil.', detail: '' });
        } else {
          console.log("Error en registro de usuario:", errorMessage);
          this.msgs.push({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Comuniquese con el administrador.' });
        }
      });
  }

  verificarPin() {
    if (this.pin.UID !== null && this.pin.UID !== "") {
      this.pinVerificado = false;
      this.pinService.verificarPin(this.pin.UID)
        .then(pinDoc => {
          if (pinDoc.exists) {
            const pin = pinDoc.data() as Pin;
            pin.UID = pinDoc.id;
            this.pin = pin;
            this.usuario.pin = this.pin.UID;
            this.usuario.tipoUsuario = this.pin.tipo;
            this.usuario.escuela = this.pin.escuela;
            this.usuario.modulos = this.pin.modulos;
            console.log("Pin verificado:", this.pin);
            this.msgs = [];
            if (pin.estado !== "Activo") {
              this.pinVerificado = true;
              this.msgs.push({ severity: 'success', summary: 'Pin valido!.', detail: 'Pin validado exitosamente.' });
            } else {
              this.msgs.push({ severity: 'error', summary: 'Pin inválido!.', detail: 'Pin ya ha sido activado.' });
            }
          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'Pin Inválido!', detail: 'El pin ingresado no es válido.' });
          }
        })
        .catch(error => {
          console.log("Error verificarPin:", error);
          this.msgs = [];
          //this.msgs.push({ severity: 'error', summary: 'Pin Inválido.', detail: 'El pin ingresado no es válido.' });
          this.msgs.push({ severity: 'error', summary: 'Pin Inválido!', detail: 'El pin ingresado no es válido.' });
        });
    } else {
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!.', detail: 'Ingresar PIN' });
    }
  }

  validarForm() {
    this.forInvalido = false;
    this.msgs = [];
    let msg = "Campos requeridos: ";
    if (this.usuario.email == "") {
      msg = msg + "\\n " + "Email";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Email' });
    }
    if (this.usuario.nombre == "") {
      msg = msg + "\\n " + "Nombre";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Nombre' });
    }
    if (this.usuario.apellido == "") {
      msg = msg + "\\n " + "Apellidos";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Apellidos' });
    }
    if (this.password == "") {
      msg = msg + "\\n " + "Password";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Password' });
    }
    if (this.usuario.tipoIdentificacion == "0") {
      msg = msg + "\\n " + "Tipo Identificación";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Tipo Identificación' });
    }
    if (this.usuario.numeroIdentificacion == "") {
      msg = msg + "\\n " + "Número de Identificación";
      this.forInvalido = true;
      this.msgs.push({ severity: 'error', summary: 'Campo Requerido!', detail: 'Número de Identificación' });
    }
  }

  registrarUsuarioFormRegistrate() {
    this.validarForm();
    if (!this.forInvalido) {
      this.authService.registrarUsuario(this.usuario, this.password)
        .then(nuevoUsuario => {
          this.usuario.UID = nuevoUsuario.user.uid;
          //this.usuario.fechaFin = this.pin.fechaFin;
          this.usuario.caduca = false;
          this.authService.agregarUsuarioCompleto(this.usuario)
            .then(ref => {
              this.isRegistro = false;
              this.isRegistroExitoso = true;
              this.pin.estado = "Activo"
              this.pinService.actualizarPin(this.pin);
              this.authService.logout();
            })
            .catch(error => {
              this.formatearMsgError(error);
            });
        })
        .catch(error => {
          console.log("Error registrarUsuario:", error);
          this.formatearMsgError(error);
        });
    }
  }

  formatearMsgError(error) {
    console.log("Error agregarUsuarioCompleto:", error);
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode == 'auth/invalid-email') {
      this.msgs = [];
      //this.msgs.push({ severity: 'error', summary: 'Formato de email inválido.', detail: '' });
      this.msgs.push({ severity: 'error', summary: 'Error Email!', detail: 'Formato de email inválido.' });
    } else {
      if (errorCode == 'auth/email-already-in-use') {
        this.msgs = [];
        //this.msgs.push({ severity: 'error', summary: 'El usuario está bloquedado.', detail: 'Comuniquese con el administrador.' });
        this.msgs.push({ severity: 'error', summary: 'Error Email!', detail: 'Email ya está en uso.' });
      } else {
        if (errorCode == 'auth/operation-not-allowed') {
          this.msgs = [];
          //this.msgs.push({ severity: 'error', summary: 'Error!', detail: 'Operación no permitida.' });
          this.msgs.push({ severity: 'error', summary: 'Error!', detail: 'Operación no permitida.' });
        } else {
          if (errorCode == 'auth/weak-password') {
            this.msgs = [];
            //this.msgs.push({ severity: 'error', summary: 'Password error!', detail: 'Password muy debil.' });
            this.msgs.push({ severity: 'error', summary: 'Password error!', detail: 'Password muy debil.' });
          } else {
            console.log("Error en registro de usuario:", errorMessage);
            this.msgs = [];
            //this.msgs.push({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Comuniquese con el administrador.' });
            this.msgs.push({ severity: 'error', summary: 'Error inesperado!', detail: 'Comuniquese con el administrador.' });
          }
        }
      }
    }
  }


}
