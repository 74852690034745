export class ReporteVisitasDiccionarioPro {

    numeroVisitas: number;
    fechaUltimoIngreso: Date;

    constructor(){
        this.fechaUltimoIngreso = null;
        this.numeroVisitas = 0;
    }
}
