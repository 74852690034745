import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class CulturaGeneralService {

  constructor(private afs: AngularFirestore) { }

  buscarPreguntasEstudiantes() {
    return this.afs.firestore.collection("culturaGeneral").get();
  }

  buscarPreguntasDocentes() {
    return this.afs.firestore.collection("culturaGeneralDocentes").get();
  }

}
