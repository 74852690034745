import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class TestInglesService {

  constructor(private afs: AngularFirestore) { }

  buscarInglesConfig() {
    return this.afs.firestore.collection("cursosIngles").doc("test").get();
  }

  buscarPreguntas() {
    return this.afs.firestore.collection("cursosIngles").doc("test")
      .collection("preguntas").get();
  }

  buscarNivelIngles(porcentaje: string) {
    return this.afs.firestore.collection("cursosIngles").doc("test")
      .collection("config").where("porcentaje", "==", porcentaje).get();
  }

  buscarTodosLosNiveles() {
    return this.afs.firestore.collection("cursosIngles").doc("test")
      .collection("config").get();
  }

  buscarPregunta(numero: number) {
    return this.afs.firestore.collection("cursosIngles").doc("test")
      .collection("preguntas").where("numero", "==", numero).get();
  }

  buscarRespuestas(preguntaNumero: number) {
    return this.afs.firestore.collection("cursosIngles").doc("test")
      .collection("preguntas").doc(preguntaNumero+"")
      .collection("respuestas")
      .orderBy("numero").get();
  }

}
