import { Component, OnInit } from '@angular/core';
import { element } from '@angular/core/src/render3/instructions';
import { PreguntaCulturaGeneral } from '../modelo/pregunta-cultura-general';
import { RespuestCulturaGeneral } from '../modelo/respuest-cultura-general';
import { ResultadoPreguntas } from '../modelo/resultado-preguntas';
import { AuthService } from '../servicios/auth.service';
import { CulturaGeneralService } from '../servicios/cultura-general.service';

@Component({
  selector: 'app-cultura-general',
  templateUrl: './cultura-general.component.html',
  styleUrls: ['./cultura-general.component.css']
})
export class CulturaGeneralComponent implements OnInit {

  listaPreguntas: PreguntaCulturaGeneral[];
  preguntaActual: PreguntaCulturaGeneral;
  respuesta: string;
  isFinalizado: boolean;
  isIniciado: boolean;
  cantidadPreguntas: number;
  cantidadRespuestasExitosas: number;
  porcentageLeccion: number;
  esEntero: boolean;
  totalPreguntas: number = 50;
  porcentaje: number = 0;
  resultadoFinal: ResultadoPreguntas[] = [];
  numeroPregunta: number;

  constructor(private loginCom: AuthService, private culturaGeneralService: CulturaGeneralService) {
    this.isFinalizado = false;
    this.isIniciado = false;
    this.respuesta = "";
    this.preguntaActual = new PreguntaCulturaGeneral();
    this.cantidadPreguntas = 0;
    this.cantidadRespuestasExitosas = 0;
    this.resultadoFinal = [];
    this.numeroPregunta = 0;
  }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("cultura-general");
    } else {
      this.init();
    }
  }

  init() {
    this.preguntaActual = new PreguntaCulturaGeneral();
    this.isFinalizado = false;
    this.isIniciado = false;
    this.respuesta = "";
    this.cantidadPreguntas = 0;
    this.cantidadRespuestasExitosas = 0;
    this.buscarPreguntasEstudiantes();
  }

  buscarPreguntasEstudiantes() {
    this.listaPreguntas = [];
    this.culturaGeneralService.buscarPreguntasEstudiantes()
      .then(lista => {
        if (lista.size > 0) {
          lista.forEach(element => {
            const pregunta = element.data() as PreguntaCulturaGeneral;
            this.listaPreguntas.push(pregunta);
          });
        }
      })
      .catch(error => {
        console.log("Error buscarPreguntasEstudiantes:", error);
      });
  }

  realizarTest() {
    this.numeroPregunta = 1;
    this.calcularPorcentajeExamen();
    this.isIniciado = true;
    this.obtenerPreguntaActual();
  }

  realizarNuevoTest() {
    this.resultadoFinal = [];
    this.preguntaActual = new PreguntaCulturaGeneral();
    this.isFinalizado = false;
    this.isIniciado = false;
    this.respuesta = "";
    this.cantidadPreguntas = 0;
    this.cantidadRespuestasExitosas = 0;
    this.realizarTest();
  }

  continuar() {
    let respuestaExitosaValidada = false;
    if (this.cantidadPreguntas === this.totalPreguntas) {
      this.porcentaje = (100 * this.cantidadRespuestasExitosas) / this.totalPreguntas;
      this.isFinalizado = true;
      this.isIniciado = false;
    } else {
      let resultadoPreguntaActual = new ResultadoPreguntas();
      resultadoPreguntaActual.numero = this.numeroPregunta;
      resultadoPreguntaActual.pregunta = this.preguntaActual.pregunta;
      this.preguntaActual.respuestas.forEach(element => {
        if (element.isCorrecta && !respuestaExitosaValidada) {         
          resultadoPreguntaActual.isCorrecta = element.isCorrecta;
          resultadoPreguntaActual.respuestaExitosa = element.respuesta;
        }
        if (this.respuesta.toUpperCase() === element.opcion.toUpperCase()) {
          respuestaExitosaValidada = true;
          if (element.isCorrecta) {
            this.cantidadRespuestasExitosas = this.cantidadRespuestasExitosas + 1;
          } else {
            resultadoPreguntaActual.isCorrecta = false;
            resultadoPreguntaActual.respuestaIncorrecta = element.respuesta;
          }
        }
      });
      this.resultadoFinal.push(resultadoPreguntaActual);
      this.cantidadPreguntas++;
      this.numeroPregunta++;
      this.calcularPorcentajeExamen();
      this.obtenerPreguntaActual();
    }
  }



  obtenerPreguntaActual() {
    this.preguntaActual = this.listaPreguntas[(this.obtenerNumeroPreguntaAleatoria() - 1)];
    this.respuesta = "";
  }

  obtenerNumeroPreguntaAleatoria(): number {
    return Math.floor((Math.random() * (968 - 1 + 1)) + 1);
  }

  calcularPorcentajeExamen() {
    this.esEntero = true;
    this.porcentageLeccion = 0;
    this.porcentageLeccion = (100 * this.cantidadPreguntas) / this.totalPreguntas;
    if (!Number.isInteger(this.porcentageLeccion)) {
      this.esEntero = false;
    }
  }

}
