import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { CursoService } from '../servicios/curso.service';
import { LeccionesService } from '../servicios/lecciones.service';
import { CursosEstado } from '../modelo/cursos-estado';
import { Curso } from '../modelo/curso';
import { Leccion } from '../modelo/leccion';
import { FrasesOraciones } from '../modelo/frases-oraciones';
import { AuthService } from '../servicios/auth.service';
import { ReconocimientoVozService } from '../servicios/reconocimiento-voz.service';

import { Observable } from 'rxjs/Rx';

interface IWindow extends Window {
  //window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
  webkitSpeechRecognition: any;
}


@Component({
  selector: 'app-lecciones',
  templateUrl: './lecciones.component.html',
  styleUrls: ['./lecciones.component.css']
})
export class LeccionesComponent implements OnInit {

  @Input() cursosEstado: CursosEstado;
  @Input() cursoID: string;
  @Input() isInicioCurso: boolean;

  leccionActual: Leccion;
  fraseOracionActual: FrasesOraciones;
  leccionesItems: MenuItem[];
  activeIndexLeccion: number;
  isCursoFinalizado: boolean;
  cursoActual: Curso;
  desactivarBotonRegresar: boolean;
  desactivarBotonContinuar: boolean;
  reproducirTodos: boolean;
  cargueInicialFinalizado: boolean;
  isEjecutandoCargueInicial: boolean;
  reconocimiento: any;
  interimTranscript: any;
  desactivarBotonGrabar: boolean;
  desactivarBotonPararGrabar: boolean;
  isLoading: boolean;
  porcentageLeccion: number;
  totalOraciones: number;
  esEntero: boolean;

  constructor(private cursoService: CursoService, public loginCom: AuthService,
    private leccionesService: LeccionesService, private speech: ReconocimientoVozService) { }

  ngOnInit() {
    this.isLoading = true;
    console.log("cursosEstado:", this.cursosEstado);
    console.log("cursoID:", this.cursoID);
    console.log("isInicioCurso:", this.isInicioCurso);
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("curso-a1");
    } else {
      this.iniciar();
    }
  }

  iniciar() {
    this.porcentageLeccion = 0;
    console.log("Seguimieto 1");
    this.isEjecutandoCargueInicial = false;
    this.cargueInicialFinalizado = false;
    this.reproducirTodos = true;
    this.desactivarBotonContinuar = true;
    this.desactivarBotonRegresar = true;
    this.activeIndexLeccion = 0;
    this.isCursoFinalizado = false;
    this.cursoActual = new Curso();
    this.cursoActual.lecciones = [];
    this.leccionesItems = [];
    this.cursoActual.UID = this.cursoID;
    this.cursoActual.nombre = this.cursoID;
    this.fraseOracionActual = new FrasesOraciones();
    this.leccionActual = new Leccion();
    this.cargarEstadoActualLeccion();
    //this.grabar2();
    console.log("Seguimieto 2");
  }

  grabar2() {
    this.grabar3('en_US')
      .subscribe(e => console.log("Grabando: ", e));
  }


  grabar() {
    this.desactivarBotonGrabar = false;
    this.desactivarBotonPararGrabar = true;
    const { webkitSpeechRecognition }: IWindow = <IWindow>window;
    this.reconocimiento = new webkitSpeechRecognition();
    this.interimTranscript = '';
    this.reconocimiento.interimResults = true;
    this.reconocimiento.maxAlternatives = 10;
    this.reconocimiento.continuous = true;
  }

  grabar3(lenguaje: string): Observable<String> {
    return Observable.create(observer => {
      const { webkitSpeechRecognition }: IWindow = <IWindow>window;
      this.reconocimiento = new webkitSpeechRecognition();
      this.reconocimiento.interimResults = true;
      this.reconocimiento.continuous = true;
      let finalTranscript = '';

      /*reconocimiento.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
          let transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        //document.querySelector('body').innerHTML = finalTranscript + '<i style="color:#ddd;">' + interimTranscript + '</>';
      }*/

      this.reconocimiento.onresult = e => observer.next(e.results.item(e.results.length - 1).item(0).transcript);

      this.reconocimiento.onerror = e => observer.error(e);
      this.reconocimiento.onend = () => observer.complete();
      this.reconocimiento.lang = lenguaje;
      this.reconocimiento.start();
      //console.log("AUDIO this.interimTranscript: ", interimTranscript);
      console.log("AUDIO this.finalTranscript: ", finalTranscript);
    })
  }

  startAudio() {
    this.desactivarBotonGrabar = true;
    this.desactivarBotonPararGrabar = false;
    /*let finalTranscript = ''
    this.reconocimiento.onresult = (event) => {
      this.interimTranscript = '';
      for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
        let transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        } else {
          this.interimTranscript += transcript;
        }
      }
      //document.querySelector('body').innerHTML = finalTranscript + '<i style="color:#ddd;">' + interimTranscript + '</>';
    }*/
    this.grabar2();
    /*console.log("AUDIO this.interimTranscript: ", this.interimTranscript);
    console.log("AUDIO this.finalTranscript: ", finalTranscript);*/
  }

  stopAudio() {
    this.reconocimiento.stop();
    this.desactivarBotonGrabar = false;
    this.desactivarBotonPararGrabar = true;
  }

  cargarEstadoActualLeccion() {
    console.log("Seguimieto 3");
    //CARGAR EL TOTAL DE LAS LECCIONES 
    console.log("cargarEstadoActualLeccion: ", this.cursoID);
    this.leccionesService.obtenerListaLeccionesPorCurso(this.cursoID)
      .then(listaLecciones => {
        console.log("Seguimieto 5");
        listaLecciones.forEach(leccionDoc => {
          const leccion = leccionDoc.data() as Leccion;
          leccion.UID = leccionDoc.id;
          console.log("Seguimieto 5 - leccionid:", leccion.UID);
          this.leccionesItems.push(
            {
              label: "L" + leccion.numero,
              command: (event: any) => {                
                this.cargarLeccionManualmente(leccion.numero);
              }
            });
        });
      })
      .catch(err => {
        console.log('Error en obtenerLeccionesPorCurso()', err);
      });
    //CARGAR LECCION ACTUAL
    this.cargarLeccionActual();
    console.log("Seguimieto 4");
  }

  cargarLeccionActual() {
    console.log("Seguimieto 6");
    console.log("cargarLeccionActual 1", this.desactivarBotonContinuar, this.cursoID, this.cursosEstado.leccionID);
    this.leccionesService.obtenerLeccionesPorCursoAndLeccionID(this.cursoID, this.cursosEstado.leccionID)
      .then(leccionDoc => {

        console.log("cargarLeccionActual 2", this.desactivarBotonContinuar);
        const leccion = leccionDoc.data() as Leccion;
        leccion.UID = leccionDoc.id;
        console.log("Seguimieto 6 - this.cursoID:", this.cursoID);
        console.log("Seguimieto 6 - this.cursosEstado:", this.cursosEstado);
        console.log("Seguimieto 6 - leccion:", leccion.UID);
        console.log("Seguimieto 6 - this.cursosEstado.fraseOracion:", this.cursosEstado.fraseOracion);
        leccion.frasesOraciones = [];
        this.leccionActual = leccion;
        this.leccionesService.obtenerFrasesOracionPorLeccionAndFraseOrden(this.cursoID, leccion.UID, this.cursosEstado.fraseOracion)
          .then(fraseLista => {

            console.log("cargarLeccionActual 3", this.desactivarBotonContinuar);
            console.log("cargarLeccionActual 3 - fraseLista.size:", fraseLista.size);
            fraseLista.forEach(element => {
              const frase = element.data() as FrasesOraciones;
              frase.UID = element.id;
              console.log("Seguimieto 6 - leccion:" + leccion.UID + " - Frase:", frase.UID);
              this.leccionActual.frasesOraciones.push(frase);
              this.fraseOracionActual = frase;
              this.activeIndexLeccion = this.leccionActual.numero - 1;
              this.desactivarBotonContinuar = false;
              this.desactivarBotonRegresar = false;
              if (this.leccionActual.numero == 1 && this.fraseOracionActual.orden == 1) {
                this.desactivarBotonRegresar = true;
              }
              console.log("cargarLeccionActual 4", this.desactivarBotonContinuar);
              this.verificarAudiosBase64ToLeccion();
              console.log("cargarLeccionActual 5", this.desactivarBotonContinuar);
              this.cargarFrasesParaLeccionActual();
              this.isLoading = false;
            });

            //Cargar el porcenaje de la leccion actual
            this.cargarPorcentajeLeccion(leccion.UID);            

            console.log("cargarLeccionActual 6", this.desactivarBotonContinuar);
          })
          .catch(err => {
            console.log('Error en obtenerLeccionesPorCurso()', err);
          });
      })
      .catch(err => {
        console.log('Error en obtenerLeccionesPorCurso()', err);
      });
    console.log("Seguimieto 7");
  }

  cargarPorcentajeLeccion(leccionUID: string) {
    this.esEntero = false;
    this.leccionesService.buscarTodasLasOracionesPorLeccion(this.cursoID, leccionUID)
      .then(listaOraciones => {
        this.totalOraciones = listaOraciones.size;
        console.log("this.totalOraciones:", this.totalOraciones);
        if (this.totalOraciones > 0) {
          this.porcentageLeccion = (100 * this.fraseOracionActual.orden) / this.totalOraciones;
          if (Number.isInteger(this.porcentageLeccion)) {
            this.esEntero = true;
          }
        }
      })
      .catch(error => console.log(error));
  }

  getToken() {
    return "ya29.c.ElpuBv4e1535sI4LV_2d7qRuK_VxfGyFQyvoJA2VFhbtoBo848zX7a6s7b-S7P6oZDP8ymfHS0TRnLjTgxoFb9beWFp35jpjNg-lxgomg7sbnfJrY5-MUKc4K30";
  }

  verificarAudiosBase64ToLeccion() {
    console.log("Seguimieto 8");
    var token = this.getToken();
    if (this.leccionActual.titulo["base64Texto_1"] === undefined || (this.leccionActual.titulo["base64Texto_1"] == null || this.leccionActual.titulo["base64Texto_1"] == "")) {
      this.leccionesService.convertirTextoToAudio(this.leccionActual.titulo["textoEspanol"], "es-CO", token, "FEMALE")
        .subscribe(res => {
          console.log("Seguimieto 8 - consultar....");
          this.leccionActual.titulo["base64Texto_1"] = res.audioContent;
          this.leccionesService.convertirTextoToAudio(this.leccionActual.titulo["textoIngles"], "en-US", token, "FEMALE")
            .subscribe(res2 => {
              this.leccionActual.titulo["base64Texto_2"] = res2.audioContent;
              this.leccionesService.guardarAudioBase64ToLeccion(this.cursoID, this.leccionActual);
              this.verificarAudiosBase64ToFrase();
            });
        });
    } else {
      console.log("NO es primer vez");
      this.verificarAudiosBase64ToFrase();
    }
    console.log("Seguimieto 9");
  }

  verificarAudiosBase64ToFrase() {
    console.log("Seguimieto 10");
    var token = this.getToken();
    if (this.fraseOracionActual.base64Texto_1 == null || this.fraseOracionActual.base64Texto_1 == "") {
      var ssmlGender_1 = "FEMALE";
      if (this.fraseOracionActual.ssmlGender_1 !== undefined && this.fraseOracionActual.ssmlGender_1 != null
        && this.fraseOracionActual.ssmlGender_1 != "") {
        ssmlGender_1 = this.fraseOracionActual.ssmlGender_1;
      }
      this.leccionesService.convertirTextoToAudio(this.fraseOracionActual.texto_1, this.fraseOracionActual.languageCodeTexto_1,
        token, ssmlGender_1)
        .subscribe(res => {
          console.log("Seguimieto 10 - consultando 1.....");
          this.fraseOracionActual.base64Texto_1 = res.audioContent;
          this.fraseOracionActual.base64Texto_2 = "";
          if (this.fraseOracionActual.isCompuesta) {
            var ssmlGender_2 = "FEMALE";
            if (this.fraseOracionActual.ssmlGender_2 !== undefined && this.fraseOracionActual.ssmlGender_2 != null
              && this.fraseOracionActual.ssmlGender_2 != "") {
              ssmlGender_2 = this.fraseOracionActual.ssmlGender_2;
            }
            this.leccionesService.convertirTextoToAudio(this.fraseOracionActual.texto_2, this.fraseOracionActual.languageCodeTexto_2,
              token, ssmlGender_2)
              .subscribe(res2 => {
                console.log("Seguimieto 10 - consultando 2.....");
                this.fraseOracionActual.base64Texto_2 = res2.audioContent;
                this.leccionesService.guardarAudioBase64ToFrase(this.cursoID, this.leccionActual.UID, this.fraseOracionActual);
                this.cargarAudiosInicioLeccion();
              });
          } else {
            this.leccionesService.guardarAudioBase64ToFrase(this.cursoID, this.leccionActual.UID, this.fraseOracionActual);
            this.cargarAudiosInicioLeccion();
          }
        });
    } else {
      this.cargarAudiosInicioLeccion();
    }
    console.log("Seguimieto 11");
  }


  cargarAudiosInicioLeccion() {
    console.log("Seguimieto 12");
    this.reproducirTodos = true;
    var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.leccionActual.titulo["base64Texto_1"]);
    var audioTituloTexto_2 = new Audio("data:audio/wav;base64," + this.leccionActual.titulo["base64Texto_2"]);
    var audioTexto_1 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_1);
    audioTituloTexto_1.play();
    audioTituloTexto_1.addEventListener("ended", () => {
      if (this.reproducirTodos) {
        audioTituloTexto_2.play();
        audioTituloTexto_2.addEventListener("ended", () => {
          if (this.reproducirTodos) {
            audioTexto_1.play();
            audioTexto_1.addEventListener("ended", () => {
              if (this.fraseOracionActual.isCompuesta) {
                var audioTexto_2 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_2);
                audioTexto_2.play();
              }
            });
          }
        });
      }
    });
    console.log("Seguimieto 13");
  }

  reproducirAudioTituloES() {
    var audioTituloTexto_1 = new Audio("data:audio/wav;base64," + this.leccionActual.titulo["base64Texto_1"]);
    audioTituloTexto_1.play();
  }

  reproducirAudioTituloEN() {
    var audioTituloTexto_2 = new Audio("data:audio/wav;base64," + this.leccionActual.titulo["base64Texto_2"]);
    audioTituloTexto_2.play();
  }

  reproducirFrases() {
    var token = this.getToken();
    if (this.fraseOracionActual.base64Texto_1 === undefined || this.fraseOracionActual.base64Texto_1 == null || this.fraseOracionActual.base64Texto_1 == "") {
      var ssmlGender_1 = "FEMALE";
      if (this.fraseOracionActual.ssmlGender_1 !== undefined && this.fraseOracionActual.ssmlGender_1 != null
        && this.fraseOracionActual.ssmlGender_1 != "") {
        ssmlGender_1 = this.fraseOracionActual.ssmlGender_1;
      }
      this.leccionesService.convertirTextoToAudio(this.fraseOracionActual.texto_1, this.fraseOracionActual.languageCodeTexto_1,
        token, ssmlGender_1)
        .subscribe(res => {
          this.fraseOracionActual.base64Texto_1 = res.audioContent;
          this.fraseOracionActual.base64Texto_2 = "";
          if (this.fraseOracionActual.isCompuesta) {
            var ssmlGender_2 = "FEMALE";
            if (this.fraseOracionActual.ssmlGender_2 !== undefined && this.fraseOracionActual.ssmlGender_2 != null
              && this.fraseOracionActual.ssmlGender_2 != "") {
              ssmlGender_2 = this.fraseOracionActual.ssmlGender_2;
            }
            this.leccionesService.convertirTextoToAudio(this.fraseOracionActual.texto_2, this.fraseOracionActual.languageCodeTexto_2,
              token, ssmlGender_2)
              .subscribe(res2 => {
                this.fraseOracionActual.base64Texto_2 = res2.audioContent;
                this.leccionesService.guardarAudioBase64ToFrase(this.cursoID, this.leccionActual.UID, this.fraseOracionActual);
                var audioTexto_1 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_1);
                audioTexto_1.play();
                audioTexto_1.addEventListener("ended", () => {
                  if (this.fraseOracionActual.isCompuesta) {
                    var audioTexto_2 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_2);
                    audioTexto_2.play();
                  }
                });
              });
          } else {
            this.leccionesService.guardarAudioBase64ToFrase(this.cursoID, this.leccionActual.UID, this.fraseOracionActual);
            var audioTexto_1 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_1);
            audioTexto_1.play();
          }
        });
    } else {
      var audioTexto_1 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_1);
      audioTexto_1.play();
      audioTexto_1.addEventListener("ended", () => {
        if (this.fraseOracionActual.isCompuesta) {
          var audioTexto_2 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_2);
          audioTexto_2.play();
        }
      });
    }
  }

  reproducirAudioTexto_1() {
    var audioTexto_1 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_1);
    audioTexto_1.play();
  }

  reproducirAudioTexto_2() {
    var audioTexto_2 = new Audio("data:audio/wav;base64," + this.fraseOracionActual.base64Texto_2);
    audioTexto_2.play();
  }

  cargarDatosLeccion(numeroLeccion: number) {
    console.log("cargarDatosLeccion numero: ", numeroLeccion);
    console.log("cargarDatosLeccion this.cursoActual.lecciones: ", this.cursoActual.lecciones.length);
    this.cursoActual.lecciones.forEach(element => {
      if (element.numero == numeroLeccion) {
        this.leccionActual = element;
        this.activeIndexLeccion = this.leccionActual.numero - 1;
        this.fraseOracionActual = this.leccionActual.frasesOraciones[0];
      }
    });

  }

  regresar() {
    if (this.fraseOracionActual.orden == 1 && this.leccionActual.numero == 1) {
      this.desactivarBotonRegresar = false;
    } else {
      if (this.fraseOracionActual.orden == 1) {
        this.anteriorLeccion();
      } else {
        this.cursosEstado.fraseOracion--;
        this.fraseOracionActual = this.leccionActual.frasesOraciones[this.cursosEstado.fraseOracion];
        this.desactivarBotonContinuar = false;
      }
    }
  }

  ejecutarCargueInicialFrases() {
    console.log("ejecutarCargueInicialFrases 1", this.desactivarBotonContinuar);
    this.cargueInicialFinalizado = false;
    this.leccionesService.obtenerFrasesOracionPorLeccion(this.cursoID, this.leccionActual.UID)
      .then(listaFrases => {
        console.log("ejecutarCargueInicialFrases 2", this.desactivarBotonContinuar);
        listaFrases.forEach(fraseDoc => {
          const frase = fraseDoc.data() as FrasesOraciones;
          frase.UID = fraseDoc.id;
          this.leccionActual.frasesOraciones.push(frase);
        });
        console.log("ejecutarCargueInicialFrases 3", this.desactivarBotonContinuar);
        this.cargueInicialFinalizado = true;
        console.log("ejecutarCargueInicialFrases 4", this.desactivarBotonContinuar);
        this.isEjecutandoCargueInicial = true;
      })
      .catch(err => {
        console.log('Error en obtenerLeccionesPorCurso()', err);
      });
    console.log("ejecutarCargueInicialFrases 5", this.desactivarBotonContinuar);
  }

  cargarFrasesParaLeccionActual() {
    console.log("cargarLeccionActual 7", this.desactivarBotonContinuar);
    if (!this.cargueInicialFinalizado) {
      console.log("cargarLeccionActual 8", this.desactivarBotonContinuar, this.cursoID, this.leccionActual.UID);
      if (!this.isEjecutandoCargueInicial) {
        //this.ejecutarCargueInicialFrases();
      }
      /*this.leccionesService.obtenerFrasesOracionPorLeccionLimit(this.cursoID, this.leccionActual.UID, 1)
        .then(listaFrases => {
          console.log("cargarLeccionActual 9", this.desactivarBotonContinuar);
          listaFrases.forEach(fraseDoc => {
            const frase = fraseDoc.data() as FrasesOraciones;
            frase.UID = fraseDoc.id;
            this.leccionActual.frasesOraciones.push(frase);
          });
          console.log("cargarLeccionActual 10", this.desactivarBotonContinuar);
          this.desactivarBotonContinuar = false;
          console.log("cargarLeccionActual 11", this.desactivarBotonContinuar);
          if (!this.isEjecutandoCargueInicial) {
            this.ejecutarCargueInicialFrases();
          }
        })
        .catch(err => {
          console.log('Error en obtenerLeccionesPorCurso()', err);
        });*/

    }
    console.log("cargarLeccionActual 12", this.desactivarBotonContinuar);
  }

  continuar() {
    this.isLoading = true;
    console.log("continuar 1", this.desactivarBotonContinuar);
    //var totalFrases = this.leccionActual.frasesOraciones.length - 1;
    console.log("continuar 2 - this.fraseOracionActual.orden:", this.fraseOracionActual.orden);
    console.log("continuar 2 - this.totalOraciones:", this.totalOraciones);
    console.log("continuar 2 - this.cargueInicialFinalizado:", this.cargueInicialFinalizado);
    if (this.fraseOracionActual.orden == this.totalOraciones) {
      this.siguienteLeccion();
    } else {
      console.log("continuar 3", this.desactivarBotonContinuar);
      console.log("continuar 3 - this.fraseOracionActual.orden", this.fraseOracionActual.orden);
      var ordenSiguienteFrase = this.fraseOracionActual.orden + 1;
      console.log("continuar 4 - ordenSiguienteFrase", this.cargueInicialFinalizado, ordenSiguienteFrase);
      if (this.cargueInicialFinalizado) {
        ordenSiguienteFrase++;
        console.log("continuar 5", this.cargueInicialFinalizado, this.leccionActual.frasesOraciones.length);
        console.log("continuar 5 - ordenSiguienteFrase: ", ordenSiguienteFrase);
        console.log("continuar 5 - frasesOraciones: ", this.leccionActual.frasesOraciones);
        this.fraseOracionActual = this.leccionActual.frasesOraciones[ordenSiguienteFrase];
        console.log("continuar 5 - fraseOracionActual: ", this.fraseOracionActual)
        this.reproducirFrases();
      } else {
        console.log("continuar 6", this.cargueInicialFinalizado, this.cursoID, this.leccionActual.UID, ordenSiguienteFrase);
        this.leccionesService.obtenerFrasesOracionPorLeccionAndFraseOrden(this.cursoID, this.leccionActual.UID, ordenSiguienteFrase)
          .then(fraseSiguiente => {
            console.log("continuar 7", fraseSiguiente.size);
            if (fraseSiguiente.size > 0) {
              fraseSiguiente.forEach(fraseDoc => {
                const frase = fraseDoc.data() as FrasesOraciones;
                frase.UID = fraseDoc.id;
                this.leccionActual.frasesOraciones.push(frase);
                this.fraseOracionActual = frase;
              });
              this.desactivarBotonContinuar = false;
              this.reproducirFrases();
              this.isLoading = false;
            }
            console.log("continuar 8");
          })
          .catch(err => {
            console.log('Error en continuar siguiente frase.', err);
          });
        console.log("continuar 9");
      }
      this.cursosEstado.fraseOracion = ordenSiguienteFrase;
      this.desactivarBotonRegresar = false;
      console.log("continuar 10");
      //this.reproducirFrases();
      console.log("porcentageLeccion:", this.porcentageLeccion);
      this.totalOraciones;
      this.porcentageLeccion = (100 * this.fraseOracionActual.orden) / this.totalOraciones;
      this.esEntero = false;
      if (Number.isInteger(this.porcentageLeccion)) {
        this.esEntero = true;
      }
      console.log("porcentageLeccion:", this.porcentageLeccion);
      console.log("cargarLeccionActual 6", this.desactivarBotonContinuar);
    }
    //SE ACTUALIZA EL ESTADO DEL CURSO    
    this.cursoService.actualizarEstadoCurso(this.loginCom.usuarioSession.UID, this.cursoID, this.cursosEstado);
  }

  siguienteLeccion() {
    this.totalOraciones = 0;
    if (this.leccionActual.numero == this.leccionesItems.length) {
      if (this.cursoID == "A1") {
        this.cursoID = "A2";
      } else if (this.cursoID == "A2") {
        this.cursoID = "B1";
      } else if (this.cursoID == "B1") {
        this.cursoID = "B2";
      }
      this.cursosEstado.leccionID = "leccion-1";
    } else {
      this.cursosEstado.leccionID = "leccion-" + (this.leccionActual.numero + 1);
    }
    this.cursosEstado.fraseOracion = 1;
    this.cargarLeccionActual();
  }

  cargarLeccionManualmente(numeroLeccion: number) {
    this.cursosEstado = new CursosEstado();
    this.cursosEstado.UID = this.cursoID;
    this.cursosEstado.fraseOracion = 1;
    this.cursosEstado.leccionID = "leccion-" + numeroLeccion;
    this.cargarLeccionActual();
    this.activeIndexLeccion = numeroLeccion - 1;
    //SE ACTUALIZA EL ESTADO DEL CURSO    
    this.cursoService.actualizarEstadoCurso(this.loginCom.usuarioSession.UID, this.cursoID, this.cursosEstado);
  }

  anteriorLeccion() {
    this.cursosEstado.leccionID = "leccion-" + (this.leccionActual.numero - 1);
    //OBTENER TOTAL FRASES LECCION ANTERIOR
    this.leccionesService.obtenerFrasesOracionPorLeccion(this.cursoID, this.cursosEstado.leccionID)
      .then(listaFrases => {
        this.cursosEstado.fraseOracion = listaFrases.size;
        this.cargarLeccionActual();
      })
      .catch(err => {
        console.log('Error en obtenerLeccionesPorCurso()', err);
      });
  }

}
