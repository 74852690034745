import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Rx';

interface IWindow extends Window {
  //window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
  webkitSpeechRecognition: any;
}


@Injectable({
  providedIn: 'root'
})
export class ReconocimientoVozService {

  constructor(private zona: NgZone) { }

  grabar(lenguaje: string): Observable<String> {
    return Observable.create(observer => {
      const { webkitSpeechRecognition }: IWindow = <IWindow>window;
      const reconocimiento = new webkitSpeechRecognition();
      reconocimiento.interimResults = true;
      reconocimiento.continuous = true;
      let finalTranscript = '';

      /*reconocimiento.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
          let transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        //document.querySelector('body').innerHTML = finalTranscript + '<i style="color:#ddd;">' + interimTranscript + '</>';
      }*/

      reconocimiento.onresult = e => observer.next(e.results.item(e.results.length - 1).item(0).transcript);

      reconocimiento.onerror = e => observer.error(e);
      reconocimiento.onend = () => observer.complete();
      reconocimiento.lang = lenguaje;
      reconocimiento.start();
      //console.log("AUDIO this.interimTranscript: ", interimTranscript);
      console.log("AUDIO this.finalTranscript: ", finalTranscript);
    })
  }

  /*grabar2(lenguaje: string): string {
    const { speechRecognition }: IWindow = <IWindow>window;
    const reconocimiento = new speechRecognition();
    let interimTranscript = '';
    return interimTranscript;
  }*/
}
