import { Leccion } from '../modelo/leccion';
export class Curso {
    UID: string;
    nombre?: string;
    lecciones?: Leccion[];
    constructor() {
        this.nombre = null;
        this.lecciones = null;
        this.UID = null;
    }
}
