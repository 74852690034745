import { RespuestCulturaGeneral } from "./respuest-cultura-general";

export class PreguntaCulturaGeneral {

    numero: number;
    pregunta: string;
    respuestas: RespuestCulturaGeneral[];

    constructor(){
        this.numero = null;
        this.pregunta = "";
        this.respuestas = [];
    }
}
