import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Municipio } from '../modelo/municipio';

@Injectable({
  providedIn: 'root'
})
export class ComunesService {

  constructor(private afs: AngularFirestore) { }

  consultarMunicipios() {
    return this.afs.firestore.collection("municipios").get();
  }

  obtenerListaEscuelas(nombreMunicipio: string) {
    return this.afs.firestore.collection("escuelas")
    .where("municipio","==", nombreMunicipio)
    //.orderBy("nombreEscuela", "asc")
    .get();
  }

  consultarEscuela(nombreMunicipio: string, nombreEscuela: string) {
    return this.afs.firestore.collection("escuelas")
    .where("municipio","==", nombreMunicipio)
    .where("nombreEscuela","==", nombreEscuela)
    .get();
  }

  guardarEscuela(nombreMunicipio: string, nombreEscuela: string) {
    return this.afs.firestore.collection("escuelas").doc()
      .set({
        municipio: nombreMunicipio,
        nombreEscuela: nombreEscuela
      })
      .catch(error => {
        console.error("Error writing guardarEscuela() ", error);
      });
  }

  obtenerEstadoOrientacion(uidUser: string) {
    return this.afs.firestore.collection("orientacion/estadoActualExamen/estados").doc(uidUser).get();
  }

  obtenerReporteVisitasDiccionarioPrope(uidUser: string) {
    return this.afs.firestore.collection("reporteVisitasDiccionarioPro").doc(uidUser).get();
  }
}
