export class Pin {
    UID: string;
    tipo: string;
    municipio: string;
    escuela: string;
    modulos: string;
    nombrePin: string;
    fechaFin: Date;
    estado: string;
    caduca: boolean;
    constructor() {
        this.UID = null;
        this.tipo = null;
        this.municipio = null;
        this.escuela = null;
        this.modulos = null;
        this.nombrePin = null;
        this.fechaFin = null;
        this.estado = "";
        this.caduca = false;
    }
}
