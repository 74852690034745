import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class AptitudVerbalService {

  constructor(private afs: AngularFirestore) { }

  buscarRespuestas(preguntaNumero: number) {
    return this.afs.firestore.collection("pruebasSaber").doc("testAptitudVerbal")
      .collection("preguntas").doc(preguntaNumero + "")
      .collection("respuestas")
      .orderBy("numero").get();
  }

  buscarPreguntas() {
    return this.afs.firestore.collection("pruebasSaber").doc("testAptitudVerbal")
      .collection("preguntas")
      .orderBy("numero").get();
  }

  buscarPregunta(numero: number) {
    return this.afs.firestore.collection("pruebasSaber").doc("testAptitudVerbal")
      .collection("preguntas").where("numero", "==", numero).get();
  }

}
