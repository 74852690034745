export class Area {
    grupo: string;
    area: string;
    profesiones: string;
    isPrincipal: boolean;
    urlImagen: string;
    constructor() {
        this.grupo = "";
        this.area = "";
        this.profesiones = "";
        this.isPrincipal = false;
        this.urlImagen = "";
    }
}
