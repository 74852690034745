import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProcesosMasivosService {

  constructor(private afs: AngularFirestore) { }

  cargarMunicipios() {
    console.log("Iniciando cargarMunicipios.....");
    /*var db = this.afs.firestore;
    var batch = db.batch();

    batch.set(db.collection("municipios").doc("	73861	".trim()), { nombre: "	VENADILLO	".trim(), nombreCompleto: "	TOLIMA	".trim() + " || " + "	VENADILLO	".trim() });
    batch.set(db.collection("municipios").doc("	99773	".trim()), { nombre: "	CUMARIBO	".trim(), nombreCompleto: "	VICHADA	".trim() + " || " + "	CUMARIBO	".trim() });

    //HACER COMMIT DE LOS DATOS AGREGADOS
    return batch.commit().then(function () {
      console.log("Carga de cursos exitosa!");
    });*/
  }

  cargarLecciones() {
    console.log("Iniciando cargarLecciones.....");
    //this.cargarLeccion_5();
    //this.cargarLeccion_6();
  }

  cargarLeccion_1() {
    console.log("Iniciando cargarLeccion_1.....");
    var db = this.afs.firestore;
    var batch = db.batch();

    //batch.set(db.collection("cursosIngles").doc("A1"), { nombre: "A1" });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1"), { numero: 1, tieneExamen: false, titulo: { textoEspanol: "Lección 1 - Inglés de supervivencia", textoIngles: " Lesson 1 - Survival English" } });


    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	1	".trim()), { orden: 1, permitirReplay: false, texto_1: "	En esta primera lección vamos a aprender algunos términos y frases generales básicas que te serán de gran utilidad para poder desenvolverte en inglés. Escucha y repítelas.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	2	".trim()), { orden: 2, permitirReplay: true, texto_1: "	Craig ¿Cómo se dice ‘Hola’ en inglés?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	‘Hello’ or ‘Hi’	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	3	".trim()), { orden: 3, permitirReplay: true, texto_1: "	Repite Hello Hi	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	La palabra Hi también significa Hola, pero es un poco más informal.	".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	4	".trim()), { orden: 4, permitirReplay: true, texto_1: "	¡Hello Craig !	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Hi Manoli!	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	5	".trim()), { orden: 5, permitirReplay: true, texto_1: "	Buenos días	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Good morning	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	6	".trim()), { orden: 6, permitirReplay: true, texto_1: "	Buenas tardes	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Good afternoon	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	7	".trim()), { orden: 7, permitirReplay: true, texto_1: "	Buenas noches	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Good night	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	8	".trim()), { orden: 8, permitirReplay: true, texto_1: "	El saludo good morning se dice entre medianoche y mediodía. Se dice good afternoon entre mediodía y las 6 de la tarde. Cuando vas a la cama, el saludo correcto es good night.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	9	".trim()), { orden: 9, permitirReplay: true, texto_1: "	Repite	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Good morning Good afternoon Good night	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	10	".trim()), { orden: 10, permitirReplay: true, texto_1: "	Adios Craig	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Goodbye	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	11	".trim()), { orden: 11, permitirReplay: true, texto_1: "	Bye Manoli! Bye Craig!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	12	".trim()), { orden: 12, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	13	".trim()), { orden: 13, permitirReplay: true, texto_1: "	¡Hasta pronto!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	See you soon	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	14	".trim()), { orden: 14, permitirReplay: true, texto_1: "	Sí	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Yes	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	15	".trim()), { orden: 15, permitirReplay: true, texto_1: "	No	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	No	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	16	".trim()), { orden: 16, permitirReplay: true, texto_1: "	Por favor	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	17	".trim()), { orden: 17, permitirReplay: true, texto_1: "	Gracias	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Thank you	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	18	".trim()), { orden: 18, permitirReplay: true, texto_1: "	Muchas gracias	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Thank you very much	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	19	".trim()), { orden: 19, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	20	".trim()), { orden: 20, permitirReplay: true, texto_1: "	…much	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	…very much	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	21	".trim()), { orden: 21, permitirReplay: true, texto_1: "	…you very much Thank you very much Thank you very much	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	22	".trim()), { orden: 22, permitirReplay: true, texto_1: "	De nada	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	You’re welcome	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	23	".trim()), { orden: 23, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	You’re welcome	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	24	".trim()), { orden: 24, permitirReplay: true, texto_1: "	¿Cómo te llamas?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	What’s yə name?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	25	".trim()), { orden: 25, permitirReplay: false, texto_1: "	En ingles, la pronunciación es muy importante. Por ejemplo, la palabra your, o ‘su’ en español, normalmente se oye muy débil. Escucha:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	26	".trim()), { orden: 26, permitirReplay: true, texto_1: "	What’s your name? What’s yə name?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	27	".trim()), { orden: 27, permitirReplay: false, texto_1: "	Escucha y repite: yə	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	28	".trim()), { orden: 28, permitirReplay: true, texto_1: "	name	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	yə name	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	29	".trim()), { orden: 29, permitirReplay: true, texto_1: "	What’s ye name? What’s ye name?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	30	".trim()), { orden: 30, permitirReplay: true, texto_1: "	Hola. Soy…Manoli	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi. I’m....Craig	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	31	".trim()), { orden: 31, permitirReplay: true, texto_1: "	Encantado (de conocerte)	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Nice to meet you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	32	".trim()), { orden: 32, permitirReplay: true, texto_1: "	I’m Steph. Nice to meet you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Nice to meet you Steph.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	33	".trim()), { orden: 33, permitirReplay: true, texto_1: "	I’m Craig. Nice to meet you. Nice to meet you Manoli.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Hi! Nice to meet you Craig! 	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	34	".trim()), { orden: 34, permitirReplay: false, texto_1: "	Escucha este dialogo corto.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi I’m Craig. What’s your name?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	35	".trim()), { orden: 35, permitirReplay: true, texto_1: "	I’m Steph. Nice to meet you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Nice to meet you Steph.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	36	".trim()), { orden: 36, permitirReplay: false, texto_1: "	Escucha la contestación de Steph de nuevo y repítela. Substituye el nombre de Steph por el tuyo.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’m Steph. Nice to meet you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	37	".trim()), { orden: 37, permitirReplay: false, texto_1: "	Ahora escucha el dialogo de nuevo sin la contestación de Steph. Intenta responder en inglés y con tu nombre.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi I’m Craig. What’s your name?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	38	".trim()), { orden: 38, permitirReplay: true, texto_1: "	I’m                                . Nice to meet you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Nice to meet you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	39	".trim()), { orden: 39, permitirReplay: false, texto_1: "	Escucha de nuevo el diálogo original.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi I’m Craig. What’s your name? I’m Steph. Nice to meet you. Nice to meet you Steph.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	40	".trim()), { orden: 40, permitirReplay: false, texto_1: "	Escucha y repite con tu nombre.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi I’m………. What’s your name?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	41	".trim()), { orden: 41, permitirReplay: true, texto_1: "	Nice to meet you Steph.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	42	".trim()), { orden: 42, permitirReplay: false, texto_1: "	Ahora vamos a cambiar, Tu empiezas el diálogo. Habla después del tono.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hi I’m                     . What’s your name?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	43	".trim()), { orden: 43, permitirReplay: true, texto_1: "	I’m Steph. Nice to meet you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Nice to meet you Steph.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	44	".trim()), { orden: 44, permitirReplay: false, texto_1: "	Escucha y repite Te presento a... This is....	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	45	".trim()), { orden: 45, permitirReplay: true, texto_1: "	Te presento a Maria	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	This is Maria	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	46	".trim()), { orden: 46, permitirReplay: false, texto_1: "	Cuando conoces a una persona inglesa, es normal ofrecer la mano y no dar un beso. Si das besos se puede malinterpretar.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	47	".trim()), { orden: 47, permitirReplay: true, texto_1: "	Te presento a Luis	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	This is Luis	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	48	".trim()), { orden: 48, permitirReplay: false, texto_1: "	Literalmente, this is...se traduce como ‘esto es...’ No siempre se puede traducir	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	49	".trim()), { orden: 49, permitirReplay: true, texto_1: "	directamente de español a inglés. Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	This is Juan This is Lola	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	50	".trim()), { orden: 50, permitirReplay: true, texto_1: "	Perdone	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Excuse me	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	51	".trim()), { orden: 51, permitirReplay: true, texto_1: "	Lo siento	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Sorry	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	52	".trim()), { orden: 52, permitirReplay: false, texto_1: "	Excuse me y sorry son de gran ayuda para comunicarte. Los ingleses se disculpan de todo constantemente. Escucha y repite: Excuse me………	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	53	".trim()), { orden: 53, permitirReplay: true, texto_1: "	Excuse me……… Sorry!	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	I’m sorry	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	54	".trim()), { orden: 54, permitirReplay: false, texto_1: "	El sorry también te sirve si no has entendido algo. Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	55	".trim()), { orden: 55, permitirReplay: true, texto_1: "	¿cómo?  ¿perdón?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Sorry? Sorry? Sorry? Sorry?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	56	".trim()), { orden: 56, permitirReplay: true, texto_1: "	No entiendo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I don’t understand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	57	".trim()), { orden: 57, permitirReplay: true, texto_1: "	Lo siento, no entiendo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Sorry, I don’t understand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	58	".trim()), { orden: 58, permitirReplay: false, texto_1: "	Escucha la pronunciación:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Understand don’tunderstand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	59	".trim()), { orden: 59, permitirReplay: true, texto_1: "	La palabra understand empieza con un sonido vocal /Ʌ/, y la negación don’t termina con una consonante /t/.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Los sonidos /t/ y /Ʌ/ se juntan así de forma que parecen una sola palabra. Escucha:	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	60	".trim()), { orden: 60, permitirReplay: true, texto_1: "	don’tunderstand don’tunderstand	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I don’t understand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	61	".trim()), { orden: 61, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Understand don’tunderstand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	62	".trim()), { orden: 62, permitirReplay: true, texto_1: "	I don’t understand	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Sorry, I don’t understand	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	63	".trim()), { orden: 63, permitirReplay: true, texto_1: "	Lo siento, no hablo inglés.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Sorry, I don’t speak English	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	64	".trim()), { orden: 64, permitirReplay: true, texto_1: "	Por favor puede hablar más despacio	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Please speak more slowly	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	65	".trim()), { orden: 65, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	…slowly	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	66	".trim()), { orden: 66, permitirReplay: true, texto_1: "	…more slowly	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	...speak more slowly Please speak more slowly	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	67	".trim()), { orden: 67, permitirReplay: true, texto_1: "	¡No hay ningún problema!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	No problem! “TAXI!”	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	68	".trim()), { orden: 68, permitirReplay: true, texto_1: "	-Alright mate? Hurry up and jump in the lights are changing!	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	69	".trim()), { orden: 69, permitirReplay: true, texto_1: "	-Please speak more slowly	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	-No problem!	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	70	".trim()), { orden: 70, permitirReplay: false, texto_1: "	Ahora aprenderemos las nacionalidades. Escucha y repite los países y las nacionalidades.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Countries and Nationalities	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	71	".trim()), { orden: 71, permitirReplay: true, texto_1: "	Inglaterra - England Inglés – English España - Spain Español – Spanish Francia - France Frances – French Alemania - Germany Aleman – German Italia - Italy	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	72	".trim()), { orden: 72, permitirReplay: true, texto_1: "	Italiano – Italian	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	73	".trim()), { orden: 73, permitirReplay: true, texto_1: "	Estados Unidos – The USA Norte Americano – American Brasil - Brazil	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	74	".trim()), { orden: 74, permitirReplay: true, texto_1: "	Brasileño – Brazilian Méjico - Mexico Mejicano – Mexican Argentina – Argentina Argentino – Argentinian	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	75	".trim()), { orden: 75, permitirReplay: true, texto_1: "	¿Hablas español?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Do you speak Spanish?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	76	".trim()), { orden: 76, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	…Spanish	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	77	".trim()), { orden: 77, permitirReplay: true, texto_1: "	…speak Spanish	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	…you speak Spanish Do you speak Spanish? Do you speak Spanish?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	78	".trim()), { orden: 78, permitirReplay: true, texto_1: "	Craig, como cambiar la entonación en las preguntas. Si Manoli, es que sube y baja la entonación. Escucha: Do you speak Spanish?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Escucha y repite:	".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	79	".trim()), { orden: 79, permitirReplay: true, texto_1: "	¿Como estas?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How are you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	80	".trim()), { orden: 80, permitirReplay: true, texto_1: "	Bien, gracias. ¿Y tu?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Fine thanks. And you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	81	".trim()), { orden: 81, permitirReplay: true, texto_1: "	Muy bien, gracias.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Very well, thank you	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	82	".trim()), { orden: 82, permitirReplay: true, texto_1: "	Un momento, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One moment, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	83	".trim()), { orden: 83, permitirReplay: true, texto_1: "	Haste luego	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	 See you later or See you! Bye!	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	84	".trim()), { orden: 84, permitirReplay: true, texto_1: "	Hasta pronto	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	See you soon	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	85	".trim()), { orden: 85, permitirReplay: true, texto_1: "	¿Perdón. Donde están los servicios?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Excuse me. Where’s the restroom?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	86	".trim()), { orden: 86, permitirReplay: true, texto_1: "	En estados unidos, los baños se llaman restroom y en Reino Unido se dice  toilet.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Excuse me. Where’s the toilet?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	87	".trim()), { orden: 87, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	toilet	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	88	".trim()), { orden: 88, permitirReplay: true, texto_1: "	the toilet	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	where’s the toilet	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	89	".trim()), { orden: 89, permitirReplay: true, texto_1: "	Excuse me. Where’s the toilet?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	90	".trim()), { orden: 90, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	restroom	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	91	".trim()), { orden: 91, permitirReplay: true, texto_1: "	the restroom	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Where’s the restroom?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	92	".trim()), { orden: 92, permitirReplay: true, texto_1: "	Excuse me. Where’s the restroom?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	93	".trim()), { orden: 93, permitirReplay: false, texto_1: "	Escucha este dialogo corto.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello. How are you? Fine thanks, and you? Very well, thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	94	".trim()), { orden: 94, permitirReplay: false, texto_1: "	Escucha y repite:  	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Fine thanks, and you? Fine thanks, and you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	95	".trim()), { orden: 95, permitirReplay: false, texto_1: "	Escucha  parte del mismo dialogo. Intenta responder en inglés.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello. How are you? Fine thanks, and you? Very well, thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	96	".trim()), { orden: 96, permitirReplay: false, texto_1: "	Escucha de Nuevo el diálogo original.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello. How are you? Fine thanks, and you? Very well, thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	97	".trim()), { orden: 97, permitirReplay: true, texto_1: "	¿Como estas?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How are you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	98	".trim()), { orden: 98, permitirReplay: false, texto_1: "	Escucha la pronunciación:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How are you? Howə you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	99	".trim()), { orden: 99, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Howə Howə Howə you? Howə you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-1").collection("frasesOraciones").doc("leccion-1-" + "	100	".trim()), { orden: 100, permitirReplay: false, texto_1: "	Ahora vamos a cambiar, Tú empiezas el diálogo. Habla después del tono.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello. How are you? Fine thanks, and you? Very well, thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpersonas-en-carpa-restaurante.jpg?alt=media&token=398ee90d-61da-47b4-b750-9f79268713cc	".trim() });



    return batch.commit().then(function () {
      console.log("Carga de cursos exitosa!");
    });
  }

  cargarLeccion_2() {
    console.log("Iniciando cargarLeccion_2.....");
    var db = this.afs.firestore;
    var batch = db.batch();

    //batch.set(db.collection("cursosIngles").doc("A1"), { nombre: "A1" });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2"), { numero: 2, tieneExamen: true, titulo: { textoEspanol: "Lección 2 - Transporte", textoIngles: "Lesson 2 – Transport" } });

    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	1	".trim()), { orden: 1, permitirReplay: false, texto_1: "	En esta lección, vamos a aprender algunas palabras y frases claves relacionadas con el transporte. Escucha y repítelas.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	2	".trim()), { orden: 2, permitirReplay: true, texto_1: "	Avión	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Plane	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	3	".trim()), { orden: 3, permitirReplay: false, texto_1: "	Aeropuerto	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Airport	".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	4	".trim()), { orden: 4, permitirReplay: true, texto_1: "	Autobús	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Bus	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	5	".trim()), { orden: 5, permitirReplay: false, texto_1: "	El sonido vocal es: /Ʌ/      /bɅs/	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	6	".trim()), { orden: 6, permitirReplay: true, texto_1: "	Trén	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Train	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	7	".trim()), { orden: 7, permitirReplay: true, texto_1: "	Barco	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Boat	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	8	".trim()), { orden: 8, permitirReplay: true, texto_1: "	El sonido vocal es:  / əƱ /      /bəƱ t/	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	9	".trim()), { orden: 9, permitirReplay: false, texto_1: "	Pero un momento – one moment, please! No se dice bo-at, Craig? Se escribe B-O-A-T, bo-at.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	10	".trim()), { orden: 10, permitirReplay: true, texto_1: "	No, en inglés muchas veces las palabras no se pronuncian como se escriben.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	11	".trim()), { orden: 11, permitirReplay: true, texto_1: "	OK seguimos:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	12	".trim()), { orden: 12, permitirReplay: false, texto_1: "	Billete	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Ticket	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	13	".trim()), { orden: 13, permitirReplay: true, texto_1: "	Coche	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Car	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	14	".trim()), { orden: 14, permitirReplay: true, texto_1: "	Aparcamiento	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	car-park	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	15	".trim()), { orden: 15, permitirReplay: true, texto_1: "	En Reino Unido, el aparcamiento  se llama car-park   y en Estados Unidos se dice  parking lot.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	16	".trim()), { orden: 16, permitirReplay: true, texto_1: "	car-park (en el Inglés Británico.)	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	parking  lot (en el Ingles Americano.)	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	17	".trim()), { orden: 17, permitirReplay: true, texto_1: "	¡Taxi! 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Taxi!	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	18	".trim()), { orden: 18, permitirReplay: true, texto_1: "	Ahora escucha y repite algunas frases útiles para cuando vayas a coger un taxi:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	19	".trim()), { orden: 19, permitirReplay: false, texto_1: "	El aeropuerto, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	The airport, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	20	".trim()), { orden: 20, permitirReplay: true, texto_1: "	La estación de autobuses, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	The bus station, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	21	".trim()), { orden: 21, permitirReplay: true, texto_1: "	La estación de trenes, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	The train station, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	22	".trim()), { orden: 22, permitirReplay: true, texto_1: "	¿Cuánto cuesta?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	 How much?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	23	".trim()), { orden: 23, permitirReplay: false, texto_1: "	Escucha la pronunciación de un sonido vocal :	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	/aƱ/ /haƱ/	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	24	".trim()), { orden: 24, permitirReplay: true, texto_1: "	la palabra how se escribe con la‘h’ , pero el sonido en inglés se aspira en una manera muy sutil. Imaginemos que estamos limpiando las gafas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	25	".trim()), { orden: 25, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	how	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	26	".trim()), { orden: 26, permitirReplay: true, texto_1: "	how much howə howə you Hello	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Harry Potter Hi	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	27	".trim()), { orden: 27, permitirReplay: false, texto_1: "	Ahora repite susurrando las palabras:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello Hi	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	28	".trim()), { orden: 28, permitirReplay: true, texto_1: "	How are you? How much?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	29	".trim()), { orden: 29, permitirReplay: true, texto_1: "	Ahora repite con voz:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Hello Hi	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	30	".trim()), { orden: 30, permitirReplay: true, texto_1: "	How are you? How much?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	31	".trim()), { orden: 31, permitirReplay: true, texto_1: "	¿Cuánto cuesta el taxi ?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How much is the taxi?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	32	".trim()), { orden: 32, permitirReplay: true, texto_1: "	¿Cuánto cuesta el billete?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How much is the ticket?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	33	".trim()), { orden: 33, permitirReplay: true, texto_1: "	¿Cuánto es? 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How much is it? 	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	34	".trim()), { orden: 34, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	…it	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	35	".trim()), { orden: 35, permitirReplay: true, texto_1: "	…is it	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	...much is it How much is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	36	".trim()), { orden: 36, permitirReplay: false, texto_1: "	El it es un pronombre y se refiere a un sustantivo especifico. Hay casos en los que no se traduce al español.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	37	".trim()), { orden: 37, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	38	".trim()), { orden: 38, permitirReplay: true, texto_1: "	How much is it?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	39	".trim()), { orden: 39, permitirReplay: true, texto_1: "	¿Cuanto es, por favor?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How much is it, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	40	".trim()), { orden: 40, permitirReplay: true, texto_1: "	No lo entiendo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I don’t understand it.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	41	".trim()), { orden: 41, permitirReplay: true, texto_1: "	¿Donde?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	42	".trim()), { orden: 42, permitirReplay: true, texto_1: "	¿Donde está?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	43	".trim()), { orden: 43, permitirReplay: true, texto_1: "	¿Dónde está la estación?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where’s the station?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	44	".trim()), { orden: 44, permitirReplay: false, texto_1: "	En inglés existen las formas contraídas. Como en muchos idiomas al hablar desaparecen sonidos. Por ejemplo, en castellano se dice algunas veces ‘pescao’ en lugar de ‘pescado’. En inglés escrito, estas contracciones  aparecen como una coma alta entre dos letras. La coma sustituye las letras que se han omitido.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	45	".trim()), { orden: 45, permitirReplay: true, texto_1: "	Ejemplo: ¿Dónde está la estación?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where is the station? Escucha La contracción:	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	46	".trim()), { orden: 46, permitirReplay: true, texto_1: "	Where’s the station?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	47	".trim()), { orden: 47, permitirReplay: false, texto_1: "	Repite	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	…station	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	48	".trim()), { orden: 48, permitirReplay: true, texto_1: "	…the station	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Where’s the station? Where’s the station? Parada de autobús Bus stop	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	49	".trim()), { orden: 49, permitirReplay: true, texto_1: "	¿Dónde está la parada del autobús?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where’s the bus stop? Where’s the bus stop?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	50	".trim()), { orden: 50, permitirReplay: true, texto_1: "	Car-park	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	51	".trim()), { orden: 51, permitirReplay: true, texto_1: "	¿Dónde está el aparcamiento?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Where’s the car-park? Where’s the car-park?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	52	".trim()), { orden: 52, permitirReplay: true, texto_1: "	Perdone. ¿Dónde está el aparcamiento?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Excuse me. Where’s the car-park?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	53	".trim()), { orden: 53, permitirReplay: true, texto_1: "	¿Cómo te llamas?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	What is your name? Escucha y repite la contracción:	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	54	".trim()), { orden: 54, permitirReplay: true, texto_1: "	What’s your name? What’s your name?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	55	".trim()), { orden: 55, permitirReplay: false, texto_1: "	Lo siento.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I am sorry. Escucha y repite la contracción:	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	56	".trim()), { orden: 56, permitirReplay: true, texto_1: "	I’m sorry.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	57	".trim()), { orden: 57, permitirReplay: true, texto_1: "	No hablo inglés.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I do not speak English. Escucha y repite la contracción: I don’t speak English.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	58	".trim()), { orden: 58, permitirReplay: true, texto_1: "	Lo siento, no hablo inglés.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’m sorry, I don’t speak English.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	59	".trim()), { orden: 59, permitirReplay: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	60	".trim()), { orden: 60, permitirReplay: true, texto_1: "	English.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	…speak English.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	61	".trim()), { orden: 61, permitirReplay: true, texto_1: "	…don’t speak English.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	…I don’t speak English.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	62	".trim()), { orden: 62, permitirReplay: true, texto_1: "	…sorry, I don’t speak English. I’m sorry, I don’t speak English. I’m sorry, I don’t speak English.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	63	".trim()), { orden: 63, permitirReplay: true, texto_1: "	Chino	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Chinese	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	64	".trim()), { orden: 64, permitirReplay: true, texto_1: "	Lo siento, no hablo chino.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’m sorry, I don’t speak Chinese.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	65	".trim()), { orden: 65, permitirReplay: true, texto_1: "	El famoso detective privado Rick Mansworth esta en Londres, Inglaterra. Escucha como intenta comunicar al taxista que quiere ir al aeropuerto:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	C – Alright, mate?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	66	".trim()), { orden: 66, permitirReplay: true, texto_1: "	Rick – Hi. Good morning.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	C - Where to?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	67	".trim()), { orden: 67, permitirReplay: true, texto_1: "	Rick - The airport, please. C – OK. No problem	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Rick - How much is it, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	68	".trim()), { orden: 68, permitirReplay: true, texto_1: "	C – 45 quid, mate.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Rick – I’m sorry, I don’t understand.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	69	".trim()), { orden: 69, permitirReplay: true, texto_1: "	C – Forty-five. Four and five. forty-five. Yeah?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Rick - Oh 45 pounds! OK. No problem.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	70	".trim()), { orden: 70, permitirReplay: false, texto_1: "	En inglés británico coloquial, un quid se traduce como una libra esterlina. El nombre más común para una libra esterlina es un pound. 45 quid ó 45 pounds es lo mismo.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Mate también es una palabra coloquial	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	71	".trim()), { orden: 71, permitirReplay: false, texto_1: "	que significa ‘amigo’, o ‘tío’ (en español coloquial). Los americanos suelen decir man y los ingleses y los australianos suelen decir mate.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	72	".trim()), { orden: 72, permitirReplay: false, texto_1: "	Ahora escucha y repite los números del 1 al 10	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Uno One Dos Two Tres Three Cuatro Four Cinco Five Seis Six Siete Seven Ocho Eight Nueve Nine Diez Ten	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	73	".trim()), { orden: 73, permitirReplay: true, texto_1: "	Y ahora vamos a contar con música.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	1-2-3-4-5-6-7-8-9-10	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	74	".trim()), { orden: 74, permitirReplay: true, texto_1: "	1-2-3-4-5-6-7-8-9-10	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	123-456-789-10	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	75	".trim()), { orden: 75, permitirReplay: false, texto_1: "	Escucha y repite: dólar	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	dollar	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	76	".trim()), { orden: 76, permitirReplay: true, texto_1: "	Cinco dólares	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Five dollars	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	77	".trim()), { orden: 77, permitirReplay: true, texto_1: "	Para hacer el plural en inglés,  añada una ‘s’:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One dollar Two dollars	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	78	".trim()), { orden: 78, permitirReplay: true, texto_1: "	One ticket Two tickets.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One car Three cars	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	79	".trim()), { orden: 79, permitirReplay: true, texto_1: "	Diez  dólares	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Ten dollars	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	80	".trim()), { orden: 80, permitirReplay: true, texto_1: "	Dos billetes, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Two tickets, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	81	".trim()), { orden: 81, permitirReplay: true, texto_1: "	Cerveza	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Beer	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	82	".trim()), { orden: 82, permitirReplay: true, texto_1: "	Tres cervezas, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Three beers, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	83	".trim()), { orden: 83, permitirReplay: true, texto_1: "	Diez minutos	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Ten minutes	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	84	".trim()), { orden: 84, permitirReplay: true, texto_1: "	Una libra esterlina	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One pound Two pounds	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	85	".trim()), { orden: 85, permitirReplay: true, texto_1: "	Cuatro libras esterlinas, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Four pounds, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	86	".trim()), { orden: 86, permitirReplay: true, texto_1: "	Un euro	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One euro	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	87	".trim()), { orden: 87, permitirReplay: true, texto_1: "	Two euros… three euros...four euros	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	88	".trim()), { orden: 88, permitirReplay: true, texto_1: "	Ocho euros , por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Eight euros, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	89	".trim()), { orden: 89, permitirReplay: true, texto_1: "	¿Cuánto es?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	How much is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	90	".trim()), { orden: 90, permitirReplay: true, texto_1: "	Diez euros, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	10 euros, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	91	".trim()), { orden: 91, permitirReplay: true, texto_1: "	Un billete de ida, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	One way, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	92	".trim()), { orden: 92, permitirReplay: true, texto_1: "	Ida y vuelta, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Return, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	93	".trim()), { orden: 93, permitirReplay: true, texto_1: "	¿Cuándo?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	When?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	94	".trim()), { orden: 94, permitirReplay: true, texto_1: "	El próximo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	The next	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	95	".trim()), { orden: 95, permitirReplay: true, texto_1: "	¿Cúando es el próximo avión?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	When’s the next plane?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	96	".trim()), { orden: 96, permitirReplay: true, texto_1: "	¿Cúando es el próximo autobús?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	When’s the next bus?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	97	".trim()), { orden: 97, permitirReplay: true, texto_1: "	¿Cúando sale el próximo tren?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	When’s the next train?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	98	".trim()), { orden: 98, permitirReplay: false, texto_1: "	Escucha a una persona comprando dos billetes.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	99	".trim()), { orden: 99, permitirReplay: true, texto_1: "	Hello	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	Two tickets. Please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	100	".trim()), { orden: 100, permitirReplay: true, texto_1: "	Here you are	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	How much is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	101	".trim()), { orden: 101, permitirReplay: true, texto_1: "	Four pounds, please. Thank you very much. You’re welcome.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	102	".trim()), { orden: 102, permitirReplay: false, texto_1: "	Escucha estas frases y repítelas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Two tickets. Please. How much is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	103	".trim()), { orden: 103, permitirReplay: true, texto_1: "	Thank you very much.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	104	".trim()), { orden: 104, permitirReplay: false, texto_1: "	Ahora vas a escuchar el  dialogo otra vez, pero con pausas. Intenta llenar estas pausas con las frases que has practicado anteriormente.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	105	".trim()), { orden: 105, permitirReplay: true, texto_1: "	Hello	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	………………..Two tickets. Please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	106	".trim()), { orden: 106, permitirReplay: true, texto_1: "	Here you are	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	………………..How much is it?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	107	".trim()), { orden: 107, permitirReplay: true, texto_1: "	Four pounds, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	………………..Thank you very much.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	108	".trim()), { orden: 108, permitirReplay: true, texto_1: "	You’re welcome.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	109	".trim()), { orden: 109, permitirReplay: true, texto_1: "	Me da cuatro billetes.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like four tickets.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	110	".trim()), { orden: 110, permitirReplay: true, texto_1: "	La expression I’d like está contraída. La expresión no contraída es I would like.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	I would like se traduce literalmente a ‘Quisiera’ o ‘Me gustaría’.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	111	".trim()), { orden: 111, permitirReplay: false, texto_1: "	La contracción es	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like....	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	112	".trim()), { orden: 112, permitirReplay: false, texto_1: "	Repite:   	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like...	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	113	".trim()), { orden: 113, permitirReplay: false, texto_1: "	En este contexto la traducción será algo como ‘ por favor, me da..’ o simplemente ‘cuatro billetes, por favor’. Es curioso Craig, pero el inglés tiene más expresiones y maneras para pedir las cosas que el españo, ¿no?.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	114	".trim()), { orden: 114, permitirReplay: false, texto_1: "	Sí, mucho más, es curioso!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	115	".trim()), { orden: 115, permitirReplay: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	116	".trim()), { orden: 116, permitirReplay: true, texto_1: "	Me da cuatro billetes, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like four tickets, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	117	".trim()), { orden: 117, permitirReplay: false, texto_1: "	Repite otra vez:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like four tickets, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	118	".trim()), { orden: 118, permitirReplay: false, texto_1: "	Sigue el ejemplo anterior substituyendo por las siguientes palabras e intenta decirlas  después de oír el tono:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	119	".trim()), { orden: 119, permitirReplay: true, texto_1: "	Un mapa	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	A map	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	120	".trim()), { orden: 120, permitirReplay: true, texto_1: "	I’d like a map, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	121	".trim()), { orden: 121, permitirReplay: true, texto_1: "	Un horario	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	A timetable	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	122	".trim()), { orden: 122, permitirReplay: true, texto_1: "	I’d like a timetable, please. A ticket	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	I’d like a ticket, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	123	".trim()), { orden: 123, permitirReplay: true, texto_1: "	Una cerveza	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like a beer, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	124	".trim()), { orden: 124, permitirReplay: true, texto_1: "	Dos cervezas	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like two beers, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	125	".trim()), { orden: 125, permitirReplay: true, texto_1: "	Tres cervezas	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like three beers, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	126	".trim()), { orden: 126, permitirReplay: true, texto_1: "	Cuatro cervezas	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like four beers, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	127	".trim()), { orden: 127, permitirReplay: true, texto_1: "	Un taxi	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	I’d like a taxi, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	128	".trim()), { orden: 128, permitirReplay: false, texto_1: "	Aparte de pedir cosas, también se puede preguntar y ofrecer con la palabra would. Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like...	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	129	".trim()), { orden: 129, permitirReplay: true, texto_1: "	¿Quisiera usted un billete de ida y vuelta?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like a return ticket?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	130	".trim()), { orden: 130, permitirReplay: true, texto_1: "	Té	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Tea	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	131	".trim()), { orden: 131, permitirReplay: true, texto_1: "	¿Quiere té?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like tea?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	132	".trim()), { orden: 132, permitirReplay: true, texto_1: "	café	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	coffee	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	133	".trim()), { orden: 133, permitirReplay: true, texto_1: "	¿Quiere café?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like coffee?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	134	".trim()), { orden: 134, permitirReplay: true, texto_1: "	¿Quiere té o café?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like tea or coffee?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	135	".trim()), { orden: 135, permitirReplay: true, texto_1: "	¿Quiere una cerveza?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like a beer?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	136	".trim()), { orden: 136, permitirReplay: true, texto_1: "	¿Quiere dos billetes?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like two tickets?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	137	".trim()), { orden: 137, permitirReplay: true, texto_1: "	¿Quiere un taxi, señor?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Would you like a taxi, sir?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	138	".trim()), { orden: 138, permitirReplay: true, texto_1: "	Si. Me gustaría un taxi para ir  al aeropuerto, gracias. 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	Yes. I’d like a taxi to the airport. Thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	139	".trim()), { orden: 139, permitirReplay: false, texto_1: "	Rick el detective está  en la estación de trenes en Inglaterra. Escucha un dialogo en el que quiere comprar un billete de tren y contesta las siguientes preguntas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	1. ¿Qué tipo de billete pide Rick, de ida o de ida y vuelta?	".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	140	".trim()), { orden: 140, permitirReplay: true, texto_1: "	2. ¿Cuánto vale un billete?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), texto_2: "	3. ¿Cuántos billetes quiere Rick?	".trim(), languageCodeTexto_2: "	es-CO	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	141	".trim()), { orden: 141, permitirReplay: true, texto_1: "	Rick -Excuse me. How much is a ticket to London, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	S – One way or return?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	142	".trim()), { orden: 142, permitirReplay: true, texto_1: "	Rick – Please speak more slowly.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	S – One…. way…. or ….return.. ticket?	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	143	".trim()), { orden: 143, permitirReplay: true, texto_1: "	Rick - One way, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	S – Five …pounds.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	144	".trim()), { orden: 144, permitirReplay: true, texto_1: "	Rick - Two tickets, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "	S -That’s ten pounds.	".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-2").collection("frasesOraciones").doc("leccion-2-" + "	145	".trim()), { orden: 145, permitirReplay: true, texto_1: "	Rick - Thank you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), isCompuesta: false, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Faeropuerto.jpg?alt=media&token=2ad26c18-0e9b-4bdb-bf4c-32ee6d93edc	1".trim() });


    return batch.commit().then(function () {
      console.log("Carga de cursos exitosa!");
    });

  }

  cargarLeccion_6() {
    console.log("Iniciando cargarLeccion_6.....");
    var db = this.afs.firestore;
    var batch = db.batch();

    //batch.set(db.collection("cursosIngles").doc("A1"), { nombre: "A1" });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6"), { numero: 6, tieneExamen: false, titulo: { textoEspanol: "Lección 6 – De compras", textoIngles: "Lesson 6 – Shopping", urlAudioEspanol: "https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-1.mp3?alt=media&token=f3d53c76-4a9e-47e0-bef6-1af52415d6ee", urlAudioIngles: "https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-2.mp3?alt=media&token=556a4f56-8f9f-4e68-bebd-0e96ca8935d9" } });

    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	1	".trim()), { orden: 1, isCompuesta: false, texto_1: "	En esta lección, vamos a aprender algunas palabras y frases útiles relacionadas con el tema de compras:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-3.mp3?alt=media&token=36d68859-5142-41eb-bf02-59fe8f249eb9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fde-compras.jpg?alt=media&token=e719cf58-c1da-4c84-b212-a60a5ff515d7	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	2	".trim()), { orden: 2, isCompuesta: true, texto_1: "	¿Te acuerdas como se dice ¿Qué? en inglés?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-4.mp3?alt=media&token=b5d765e2-f8f8-4c20-b5a9-fe117576a98b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	.......‘What?’ What’s your name?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-5.mp3?alt=media&token=44aab3c5-2bbb-4d54-a086-ee5a8f28176f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	3	".trim()), { orden: 3, isCompuesta: true, texto_1: "	¿Y ¿Comó? en inglés es..............	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-6.mp3?alt=media&token=d49f27d4-774f-409b-a36c-f6d5558238cb	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	…..‘How?’ ¿Comó estas? – How are you?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-7.mp3?alt=media&token=eede3c29-b1af-4084-9473-620abd7e5dc0	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	4	".trim()), { orden: 4, isCompuesta: true, texto_1: "	¿Te acuerdas como se dice ¿Dondé?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-8.mp3?alt=media&token=b99c3ec6-b4ae-46c9-9094-e0f67e60d096	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	.......‘Where?’	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-9.mp3?alt=media&token=0fee79bd-2d4f-4095-b3a6-8a3321223e7a	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	5	".trim()), { orden: 5, isCompuesta: true, texto_1: "	Y cómo se dice ‘¿dónde es?’	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-10.mp3?alt=media&token=7b62706b-57b8-4599-b78e-020248f73b24	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	‘Where is’ o ‘where’s’ 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-11.mp3?alt=media&token=2467bd56-673e-4ab6-988f-5ada60a8db87	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	6	".trim()), { orden: 6, isCompuesta: false, texto_1: "	Escucha y repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-12.mp3?alt=media&token=1fe4a837-ea79-4342-bd40-54bfc9554bf9	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	7	".trim()), { orden: 7, isCompuesta: true, texto_1: "	El banco	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-13.mp3?alt=media&token=0dd8fbfa-7913-4d49-9c65-656c9fd1d915	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FEl%20banco.jpg?alt=media&token=8dd667b0-7987-4956-8728-e9efa42f071e	".trim(), texto_2: "	The bank	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-14.mp3?alt=media&token=6c5658e9-3a5a-441a-9e63-ddcf80958ab9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	8	".trim()), { orden: 8, isCompuesta: false, texto_1: "	Where’s the bank?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-15.mp3?alt=media&token=8981c25e-08df-45c4-bdb5-c39e5e3a272e	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	9	".trim()), { orden: 9, isCompuesta: true, texto_1: "	El supermercado	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-16.mp3?alt=media&token=5d7bd4ae-f042-4530-8426-4e76a5f13a73	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSupermercado.jpg?alt=media&token=f944b7d2-32f6-4bc5-be19-279b1d00f5d4	".trim(), texto_2: "	The supermarket	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-17.mp3?alt=media&token=c5fe7635-2a64-4c51-8a3a-fe8f82f9a2b3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	10	".trim()), { orden: 10, isCompuesta: false, texto_1: "	Where’s the supermarket?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-18.mp3?alt=media&token=c7f9e97c-f875-451d-b1bd-e6b277030120	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSupermercado.jpg?alt=media&token=f944b7d2-32f6-4bc5-be19-279b1d00f5d5	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	11	".trim()), { orden: 11, isCompuesta: true, texto_1: "	Perdone. ¿Dónde está la estación?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-19.mp3?alt=media&token=cdff0ab0-b4a4-4496-b407-5d3089460602	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Excuse me. Where’s the station?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-20.mp3?alt=media&token=5a115696-d7da-4597-9c1d-8fa4bbed63ec	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	12	".trim()), { orden: 12, isCompuesta: true, texto_1: "	El verbo ‘comprar’ en inglés es:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-21.mp3?alt=media&token=a5b78746-2291-4085-afb9-d8204a0ceec0	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcomprar.jpg?alt=media&token=f800be33-a723-4886-8229-073589b0ffc7	".trim(), texto_2: "	To buy	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-22.mp3?alt=media&token=c68c70a0-dad7-45be-9ff8-9a5391d00a4d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	13	".trim()), { orden: 13, isCompuesta: true, texto_1: "	¿Dónde puedo comprar.......?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-23.mp3?alt=media&token=68690c8e-be21-44e7-9015-472fbab2fae7	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcomprar.jpg?alt=media&token=f800be33-a723-4886-8229-073589b0ffc8	".trim(), texto_2: "	Where can I buy…….?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-24.mp3?alt=media&token=fac1677d-104c-4217-9896-7bda170c6e4d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	14	".trim()), { orden: 14, isCompuesta: true, texto_1: "	¿Dónde puedo comprar un plano?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-25.mp3?alt=media&token=9c46c698-e03c-4023-8632-39033f8b4d30	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcomprar.jpg?alt=media&token=f800be33-a723-4886-8229-073589b0ffc9	".trim(), texto_2: "	Where can I buy a map?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-26.mp3?alt=media&token=934234e7-0093-4a81-8f52-449d92e15652	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	15	".trim()), { orden: 15, isCompuesta: false, texto_1: "	¡Ojo! A veces la palabra ‘can’ se oye muy débil. Escucha: Where /kən/ I buy a map?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-27.mp3?alt=media&token=feb51d52-55ac-423d-9ab3-383ba0ddcf4b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	16	".trim()), { orden: 16, isCompuesta: true, texto_1: "	Escucha y repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-28.mp3?alt=media&token=05dbec3d-eb8b-4f7e-a45d-9b2b27f0d5c0	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	/kən/	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-29.mp3?alt=media&token=82fa65d4-8ce1-4ca2-ad2a-56eea5fdd916	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	17	".trim()), { orden: 17, isCompuesta: false, texto_1: "	Where /kən/ I buy… Where /kən/ I buy a map?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-30.mp3?alt=media&token=9c2b990f-9246-4c84-80cb-092edd5d705b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	18	".trim()), { orden: 18, isCompuesta: true, texto_1: "	Periódico	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-31.mp3?alt=media&token=ba27b140-0214-4b99-b26d-4651c9451f09	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPeri%C3%B3dico.jpg?alt=media&token=e2193a23-3974-4d01-9f48-f8d2f7775ab9	".trim(), texto_2: "	Newspaper	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-32.mp3?alt=media&token=30fafaf8-fdd2-4014-9c85-4191d8fbe057	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	19	".trim()), { orden: 19, isCompuesta: true, texto_1: "	¿Dónde puedo comprar un periódico?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-33.mp3?alt=media&token=5ec93c17-0ed4-4637-827e-b2691a6c4f11	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPeri%C3%B3dico.jpg?alt=media&token=e2193a23-3974-4d01-9f48-f8d2f7775ab10	".trim(), texto_2: "	Where /kən/ I buy a newspaper?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-34.mp3?alt=media&token=83558ba7-0ff7-4184-b232-affc757af0fe	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	20	".trim()), { orden: 20, isCompuesta: true, texto_1: "	¿Dónde puedo comprar un periódico en español?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-35.mp3?alt=media&token=4c62ca26-9f58-4cd0-a2e9-2752d597a466	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPeri%C3%B3dico.jpg?alt=media&token=e2193a23-3974-4d01-9f48-f8d2f7775ab11	".trim(), texto_2: "	Where /kən/ I buy a Spanish newspaper? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-36.mp3?alt=media&token=6091149d-d9f7-4577-9690-a764deb0f0a9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	21	".trim()), { orden: 21, isCompuesta: false, texto_1: "	Where /kən/ I buy a ticket?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-37.mp3?alt=media&token=4ea4d265-f622-4dc9-a049-76197db2dbaf	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	22	".trim()), { orden: 22, isCompuesta: true, texto_1: "	Pilas	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-38.mp3?alt=media&token=114acb67-3e09-4add-8b8a-e25c73dae8a2	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPilas.jpg?alt=media&token=dc5ea3ae-05e9-4434-b270-91d18608084a	".trim(), texto_2: "	Batteries	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-39.mp3?alt=media&token=562bfd00-8d03-43aa-9660-26ec07035aa9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	23	".trim()), { orden: 23, isCompuesta: true, texto_1: "	¿Dónde puedo comprar pilas?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-40.mp3?alt=media&token=645121f9-2ee0-4a18-8fad-6f72567a0ae5	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPilas.jpg?alt=media&token=dc5ea3ae-05e9-4434-b270-91d18608084a	".trim(), texto_2: "	Where /kən/ I buy batteries?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-41.mp3?alt=media&token=96b80df1-f54b-4d26-bab0-f5be8afe411e	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	24	".trim()), { orden: 24, isCompuesta: true, texto_1: "	Sólo estoy mirando.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-42.mp3?alt=media&token=13a743b7-a1ee-457d-b20f-e79db94074c9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fmirando.jpg?alt=media&token=b543a2bd-7072-4229-b494-366372268ba3	".trim(), texto_2: "	I’m just looking.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-43.mp3?alt=media&token=171b40e7-0b41-4696-89ce-7b9e3ecf56b2	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	25	".trim()), { orden: 25, isCompuesta: true, texto_1: "	Can I help you?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-44.mp3?alt=media&token=61451768-e174-4928-b96c-1e56cb469b35	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’m just looking, thank you.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-45.mp3?alt=media&token=c4f245ee-7b43-44c9-833c-93c7559e0297	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	26	".trim()), { orden: 26, isCompuesta: true, texto_1: "	¿Cuanto cuesta esto?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-46.mp3?alt=media&token=547bbc26-8ba1-46f8-81a6-ded5675176f2	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	How much is this?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-47.mp3?alt=media&token=145c2983-44f9-4953-9d1a-3e7db5647b03	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	27	".trim()), { orden: 27, isCompuesta: true, texto_1: "	En inglés, ‘this’ significa ‘este’, ‘esta’ o ‘esto’	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-48.mp3?alt=media&token=268beac0-38e2-4f7e-984b-413c2ad8d67b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	These significa ‘estos’ o ‘estas’	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-49.mp3?alt=media&token=c10504c7-b92a-4f40-a4af-7ffdd37bb481	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	28	".trim()), { orden: 28, isCompuesta: false, texto_1: "	Those significa ‘esos/esas’ o ‘aquellos/aquellas’ 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-50.mp3?alt=media&token=17d609f1-0896-4a30-a335-a10e6773a628	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	29	".trim()), { orden: 29, isCompuesta: true, texto_1: "	Repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-51.mp3?alt=media&token=1e54134f-f5c4-4231-9c46-02baee1df45f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	This these those	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-52.mp3?alt=media&token=cebdae2a-dd8e-4f65-9931-1a67d551cf9a	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	30	".trim()), { orden: 30, isCompuesta: false, texto_1: "	This newspaper, these tickets, those maps	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-53.mp3?alt=media&token=6585e7c7-9df0-4d15-a654-22f36013f997	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	31	".trim()), { orden: 31, isCompuesta: true, texto_1: "	Esta mañana	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-54.mp3?alt=media&token=ea3a07e4-55fa-41f5-be7a-4f5c001ef48e	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fen-la-ma%C3%B1ana.jpg?alt=media&token=478a45e4-9428-49fb-b79b-238f96c72470	".trim(), texto_2: "	This morning	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-55.mp3?alt=media&token=42ce4297-cca5-4114-94a1-f890b90667f6	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	32	".trim()), { orden: 32, isCompuesta: true, texto_1: "	Esta tarde	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-56.mp3?alt=media&token=0b6a0644-6870-473b-9d82-73ea234a5986	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fen-la-tarde.jpg?alt=media&token=40cf6d97-9188-487f-9812-0f05ceb2f7b6	".trim(), texto_2: "	This afternoon	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-57.mp3?alt=media&token=375b23bc-adfb-4aea-bc82-a3518b1e96e1	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	33	".trim()), { orden: 33, isCompuesta: true, texto_1: "	¿Cuánto cuesta este bocadillo?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-58.mp3?alt=media&token=76e073ed-543b-4c0f-934f-d1dbfe45f6b9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fbocadillo.jpg?alt=media&token=61499a1a-cb53-48a1-9f59-33ab17f9f5fa	".trim(), texto_2: "	How much is this sandwich?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-59.mp3?alt=media&token=eb32abc8-fd53-4726-9c9b-d8b4e57b974b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	34	".trim()), { orden: 34, isCompuesta: true, texto_1: "	How much is that Spanish newspaper? How much are those batteries?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-60.mp3?alt=media&token=4c128f1c-a545-4258-b8b7-620dbe0d5d26	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPeri%C3%B3dico.jpg?alt=media&token=e2193a23-3974-4d01-9f48-f8d2f7775ab9	".trim(), texto_2: "	 How much are those batteries?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-61.mp3?alt=media&token=8d20d4ea-424c-426c-a073-1a5517ba133b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	35	".trim()), { orden: 35, isCompuesta: true, texto_1: "	Camisetas	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-62.mp3?alt=media&token=38306237-a699-4529-b4bd-6cb8af4bca51	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCamiseta.png?alt=media&token=6b609941-797a-4294-8d8b-3c23666146cc	".trim(), texto_2: "	T-shirts	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-63.mp3?alt=media&token=a5fd4bdb-138b-4e7f-80da-7702ab291bf8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	36	".trim()), { orden: 36, isCompuesta: true, texto_1: "	Camisa	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-64.mp3?alt=media&token=04d0356e-a2f5-47ba-96b7-ed862ccb9ffb	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCamisa.jpg?alt=media&token=4c987f72-8110-47e3-825d-45d7375fb6d4	".trim(), texto_2: "	Shirt	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-65.mp3?alt=media&token=c3d02ffe-fc1d-41c5-af97-bfd32590c325	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	37	".trim()), { orden: 37, isCompuesta: true, texto_1: "	Sombrero	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-66.mp3?alt=media&token=0546d386-c2c5-4bcb-86e6-3c2d26870a06	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSombrero.png?alt=media&token=b51acc94-5b39-48c3-a60d-002144a80219	".trim(), texto_2: "	hat	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-67.mp3?alt=media&token=a9a3caf6-be2b-49eb-b8de-aed06621acf5	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	38	".trim()), { orden: 38, isCompuesta: true, texto_1: "	Vestido	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-68.mp3?alt=media&token=de439245-b0c5-4ca1-b4bf-6d532c94214c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fvestido.jpg?alt=media&token=e5b8fabf-1c54-470c-bfe4-f02c31e58c9d	".trim(), texto_2: "	Dress	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-69.mp3?alt=media&token=f8cc9d79-100e-41dd-a085-6e9802391ba7	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	39	".trim()), { orden: 39, isCompuesta: true, texto_1: "	Falda	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-70.mp3?alt=media&token=4e8deaf0-5189-4912-bdf5-7c97c498e6f0	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FFalda.jpg?alt=media&token=063c8066-d3cf-4f26-8d66-dbaa0243fc1c	".trim(), texto_2: "	Skirt	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-71.mp3?alt=media&token=5f073a19-d44d-4d1a-ba5b-3175883987c3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	40	".trim()), { orden: 40, isCompuesta: true, texto_1: "	How much are these T-shirts? How much is this dress?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-72.mp3?alt=media&token=8dd608af-8f10-4373-b92a-a3a2c675702f	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCamisa.jpg?alt=media&token=4c987f72-8110-47e3-825d-45d7375fb6d4	".trim(), texto_2: "	How much is this dress?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-73.mp3?alt=media&token=ac3e077f-9888-491e-b96b-230c3054eeb2	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	41	".trim()), { orden: 41, isCompuesta: true, texto_1: "	How much is this shirt?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-74.mp3?alt=media&token=400a32b1-9353-449e-b3d8-949ec05a8798	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FFalda.jpg?alt=media&token=063c8066-d3cf-4f26-8d66-dbaa0243fc1c	".trim(), texto_2: "	How much is this skirt?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-75.mp3?alt=media&token=e9dee7bc-6da1-4c0d-a704-a255673e7212	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	42	".trim()), { orden: 42, isCompuesta: true, texto_1: "	Zapatos	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-76.mp3?alt=media&token=707cf613-384d-43ce-9dd7-6a5a053daa07	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVino%20tinto.jpg?alt=media&token=e3ce2664-6a1c-47e9-b29d-28ab518b3c7e	".trim(), texto_2: "	Shoes	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-77.mp3?alt=media&token=3a9ed163-8681-48bb-be03-8b68fe664e1f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	43	".trim()), { orden: 43, isCompuesta: true, texto_1: "	Perdone.¿Cuanto valen aquellos zapatos?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-78.mp3?alt=media&token=53f08ba8-fa08-4f50-a2ba-191d9012f214	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVino%20tinto.jpg?alt=media&token=e3ce2664-6a1c-47e9-b29d-28ab518b3c7e	".trim(), texto_2: "	Excuse me. How much are those shoes?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-79.mp3?alt=media&token=ee6d3547-151a-44de-8898-b96af0fec21a	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	44	".trim()), { orden: 44, isCompuesta: true, texto_1: "	Abrigo	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-80.mp3?alt=media&token=5a6a116d-1641-4f2b-999d-4e16811efda8	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAbrigo.jpg?alt=media&token=536d4efb-92d6-418b-b8cb-43c166766ed3	".trim(), texto_2: "	Coat	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-81.mp3?alt=media&token=8547e712-b950-4823-90e6-586a09862634	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	45	".trim()), { orden: 45, isCompuesta: false, texto_1: "	Excuse me. How much is this coat?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-82.mp3?alt=media&token=ed80dad5-593c-4db3-8994-0c52e544c178	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAbrigo.jpg?alt=media&token=536d4efb-92d6-418b-b8cb-43c166766ed4	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	46	".trim()), { orden: 46, isCompuesta: true, texto_1: "	Pantalón	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-83.mp3?alt=media&token=35b0dbf6-f779-48a1-8c99-e711aa80f29c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpantalon.jpg?alt=media&token=47698a79-da5c-4456-9082-5506cb2e597e	".trim(), texto_2: "	Trousers (en el inglés británico)	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-84.mp3?alt=media&token=b3674bd6-5189-4bdb-b449-33c2b0f83fdd	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	47	".trim()), { orden: 47, isCompuesta: true, texto_1: "	Pants (en el inglés americano)	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-85.mp3?alt=media&token=3750a0b3-513c-44c6-a631-92a77edec972	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpantalon.jpg?alt=media&token=47698a79-da5c-4456-9082-5506cb2e597e	".trim(), texto_2: "	Excuse me. How much are these trousers? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-86.mp3?alt=media&token=5b351311-af16-4238-9d7b-0cf0140e950b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	48	".trim()), { orden: 48, isCompuesta: false, texto_1: "	How much are these pants?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-87.mp3?alt=media&token=e5d21077-bfbb-46a3-9be2-d9b61b7b135c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fpantalon.jpg?alt=media&token=47698a79-da5c-4456-9082-5506cb2e597e	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	49	".trim()), { orden: 49, isCompuesta: true, texto_1: "	Vaqueros	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-88.mp3?alt=media&token=2d411389-9a32-4543-bd73-cf72a05f5392	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVaqueros.jpg?alt=media&token=6a6fb8de-30de-4d49-b2a2-4c6cc528df7c	".trim(), texto_2: "	Jeans	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-89.mp3?alt=media&token=6460d8d2-0d93-4f6d-b899-2167be0556f0	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	50	".trim()), { orden: 50, isCompuesta: true, texto_1: "	How much are these jeans?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-90.mp3?alt=media&token=05392f7e-cc04-467c-9025-482741521fce	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVaqueros.jpg?alt=media&token=6a6fb8de-30de-4d49-b2a2-4c6cc528df7c	".trim(), texto_2: "	How much are these jeans?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-90.mp3?alt=media&token=05392f7e-cc04-467c-9025-482741521fce	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	51	".trim()), { orden: 51, isCompuesta: false, texto_1: "	How much is this hat?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-92.mp3?alt=media&token=9267b9a5-7946-43f7-abc6-7d9e3acb797f	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSombrero.png?alt=media&token=b51acc94-5b39-48c3-a60d-002144a80219	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	52	".trim()), { orden: 52, isCompuesta: true, texto_1: "	Jersey	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-93.mp3?alt=media&token=dd4c14cd-f009-40aa-95cd-92207727ea77	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FJersey.jpg?alt=media&token=098aa034-f034-49b4-abd2-7771950f190e	".trim(), texto_2: "	Sweater	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-94.mp3?alt=media&token=8bb143c4-7ebb-4b09-b4dc-1cced4674901	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	53	".trim()), { orden: 53, isCompuesta: false, texto_1: "	How much is this sweater?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-95.mp3?alt=media&token=466371a7-3596-4d71-a6a5-0b6e55c77b4a	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FJersey.jpg?alt=media&token=098aa034-f034-49b4-abd2-7771950f190e	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	54	".trim()), { orden: 54, isCompuesta: true, texto_1: "	¿Dónde puedo comprar un jersey?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-96.mp3?alt=media&token=fd3ebbb3-c955-4f29-be93-e174addf8e61	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FJersey.jpg?alt=media&token=098aa034-f034-49b4-abd2-7771950f190e	".trim(), texto_2: "	Where can I buy a sweater?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-97.mp3?alt=media&token=173e787b-3297-48f8-916f-23d57637ea0d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	55	".trim()), { orden: 55, isCompuesta: true, texto_1: "	Perfume	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-98.mp3?alt=media&token=abf7d43b-8863-4faa-9c66-531eb7c04f1a	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPerfume.jpg?alt=media&token=d80de766-e3ca-4bf1-bcd0-5a7bdd15c2df	".trim(), texto_2: "	Perfume	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-99.mp3?alt=media&token=203f9d80-4cb1-449a-8d16-31b229234afc	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	56	".trim()), { orden: 56, isCompuesta: false, texto_1: "	Excuse me. Where can I buy perfume?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-100.mp3?alt=media&token=4dd7e60b-1fcd-4969-8834-009ef3ce40a9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPerfume.jpg?alt=media&token=d80de766-e3ca-4bf1-bcd0-5a7bdd15c2df	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	57	".trim()), { orden: 57, isCompuesta: true, texto_1: "	Rick está tomando algo con Angeles en el bar del Hotel. Escucha la conversación que tiene Angeles con la camerera.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-101.mp3?alt=media&token=0a9a6ea5-93e6-4f69-bca9-aca4c7accd83	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Red wine, sir, y café con leche para usted.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-102.mp3?alt=media&token=7228d751-4882-48f9-b839-90a420452b9d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	58	".trim()), { orden: 58, isCompuesta: true, texto_1: "	Angeles: Ah ¿Hablas español?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-103.mp3?alt=media&token=3e98de9a-0e45-4649-ad25-50ad99dbc0d0	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Un poco, pero solo cuento hasta diez.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-104.mp3?alt=media&token=b3268791-d30c-4a1d-be8d-bc781cdcae39	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	59	".trim()), { orden: 59, isCompuesta: true, texto_1: "	Angeles: I can help you.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-105.mp3?alt=media&token=87a86c9f-fb09-47f4-b8d9-f15c9e50760d	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Okay, eleven?  	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-106.mp3?alt=media&token=2a09bca1-0931-4171-9aa4-d27b1ae494e1	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	60	".trim()), { orden: 60, isCompuesta: true, texto_1: "	Angeles: Once	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-107.mp3?alt=media&token=ad914121-f5e1-42f8-bda7-6a8a05b12eee	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FOnce.png?alt=media&token=444ee8fb-4c9d-4697-93a9-fe8af072f806	".trim(), texto_2: "	Waitress: Twelve?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-108.mp3?alt=media&token=de36d0cd-b1c9-4a84-9caa-5ae332864fb9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	61	".trim()), { orden: 61, isCompuesta: true, texto_1: "	Angeles: Doce	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-109.mp3?alt=media&token=8278cbd4-3ba7-470d-9c61-72b948e3532a	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FDoce.jpg?alt=media&token=b7880a4b-3fbc-4a1e-b68c-14af0ad6a35b	".trim(), texto_2: "	Waitress: Thirteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-110.mp3?alt=media&token=6fd1c1ab-316f-4301-851c-42be2e897990	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	62	".trim()), { orden: 62, isCompuesta: true, texto_1: "	Angeles: Trece	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-111.mp3?alt=media&token=3ba33e73-da65-420c-8784-a1af194ffe70	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FTrece.png?alt=media&token=12a6e202-98a1-4d0e-ae20-6f1cf16df9c4	".trim(), texto_2: "	Waitress: Fourteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-112.mp3?alt=media&token=ac932477-edd1-4f49-a14f-20b4861f7740	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	63	".trim()), { orden: 63, isCompuesta: true, texto_1: "	Angeles: Catorce	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-113.mp3?alt=media&token=bcedbf0d-8676-4882-9e70-f8419348400c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCatorce.jpg?alt=media&token=2d829782-c97f-479c-b621-96856abee124	".trim(), texto_2: "	Waitress: Fifteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-114.mp3?alt=media&token=d127f2a0-bd8b-43b1-b291-de980d21e21b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	64	".trim()), { orden: 64, isCompuesta: true, texto_1: "	Angeles: Quince	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-115.mp3?alt=media&token=d12a95d5-83d5-4b7f-aa6e-22d94073da0b	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FQuince.jpg?alt=media&token=04911beb-bca9-4b0a-8a47-39a25fed8473	".trim(), texto_2: "	Waitress: Sixteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-116.mp3?alt=media&token=bc8a4e4e-47c9-4815-b82f-3d2e57c82e23	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	65	".trim()), { orden: 65, isCompuesta: true, texto_1: "	Angeles: Dieciséis	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-117.mp3?alt=media&token=0b043822-f054-4ffe-8ab1-63130317c849	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FDieciseis.jpg?alt=media&token=094a8c98-93cc-4269-bcea-df74ff6e70c8	".trim(), texto_2: "	Waitress: Seventeen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-118.mp3?alt=media&token=d8cbeec7-2509-4794-8a0c-f76f649f9c25	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	66	".trim()), { orden: 66, isCompuesta: true, texto_1: "	Angeles: Diecisiete	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-119.mp3?alt=media&token=abd2c689-5ef9-47ef-b1cd-850c707b64cc	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FDiecisiete.jpg?alt=media&token=b38ee003-09b7-4a45-9412-587216212ef8	".trim(), texto_2: "	Waitress: Eighteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-120.mp3?alt=media&token=442850ce-18e9-4ca6-97da-01a165322752	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	67	".trim()), { orden: 67, isCompuesta: true, texto_1: "	Angeles: Dieciocho	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-121.mp3?alt=media&token=79d9ac02-1ce9-4706-8fd9-1d829f5c22e8	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FDieciocho.jpg?alt=media&token=21154567-caa4-45a2-93db-d743976c8321	".trim(), texto_2: "	Waitress: Nineteen	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-122.mp3?alt=media&token=5867908d-48fd-4c1b-9ac4-f21f8d252752	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	68	".trim()), { orden: 68, isCompuesta: true, texto_1: "	Angeles: Diecinueve	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-123.mp3?alt=media&token=b2b94c46-5c06-4302-b9be-3e85e358a38c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FDiecinueve.jpg?alt=media&token=3b533f64-8a6d-4a04-920f-f46b32316c52	".trim(), texto_2: "	Waitress: Twenty	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-124.mp3?alt=media&token=f595f52a-d982-43ff-a6af-d1c7d88edcc3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	69	".trim()), { orden: 69, isCompuesta: false, texto_1: "	Angeles: Veinte	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-125.mp3?alt=media&token=841f6baa-d0dc-479c-800a-37b318b88c25	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVeinte.jpg?alt=media&token=0e0b6251-24e8-4ec9-8b7c-5334969c4b63	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	70	".trim()), { orden: 70, isCompuesta: true, texto_1: "	Rick: So, eleven is ....	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-126.mp3?alt=media&token=584d3029-6375-4549-b912-bd5247079842	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: once          	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-127.mp3?alt=media&token=4e09894c-604c-4c0e-ae3d-a8705186d9de	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	71	".trim()), { orden: 71, isCompuesta: true, texto_1: "	Rick: Twelve…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-128.mp3?alt=media&token=9a072b96-6e2b-4d20-94a2-32650dfff8ec	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Doce	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-129.mp3?alt=media&token=5f769d87-e66c-4c69-b4f0-a703b7c04a3c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	72	".trim()), { orden: 72, isCompuesta: true, texto_1: "	Rick: Thirteen…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-130.mp3?alt=media&token=db1bd564-cc50-48fa-8b9c-ca5dcff4bc0f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Trece	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-131.mp3?alt=media&token=d234a721-01bf-43b5-bf24-e4d6be787c2c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	73	".trim()), { orden: 73, isCompuesta: true, texto_1: "	Rick: Fourteen… 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-132.mp3?alt=media&token=13d1a7fc-71bc-47d3-8cc5-7b6edc7b6057	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Catorce	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-133.mp3?alt=media&token=235ccdbd-d0cf-49be-b2d8-f48209ce4983	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	74	".trim()), { orden: 74, isCompuesta: true, texto_1: "	Rick: Fifteen… 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-134.mp3?alt=media&token=2e4c2f03-b765-476b-8811-365b78330b36	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Quince	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-135.mp3?alt=media&token=b0fac971-6586-42bd-8745-9cf64e0ff8f9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	75	".trim()), { orden: 75, isCompuesta: true, texto_1: "	Rick: Sixteen…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-136.mp3?alt=media&token=84b394d7-57db-427a-994e-8977861772c3	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Dieciséis	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-137.mp3?alt=media&token=49d821d9-9821-423a-bf77-20cc167ae47c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	76	".trim()), { orden: 76, isCompuesta: true, texto_1: "	Rick: Seventeen…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-138.mp3?alt=media&token=44512944-8cef-4d3b-96ab-5effea46fc1f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Diecisiete	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-139.mp3?alt=media&token=178fd5d2-9142-4812-a942-32fd765ad8c1	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	77	".trim()), { orden: 77, isCompuesta: true, texto_1: "	 Rick: Eighteen…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-140.mp3?alt=media&token=2407613c-69cc-4c9d-bdf6-1d95c1423cda	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Dieciocho 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-141.mp3?alt=media&token=50745326-d5a2-476c-8b94-8eaa770b3c6b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	78	".trim()), { orden: 78, isCompuesta: true, texto_1: "	 Rick: Nineteen…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-142.mp3?alt=media&token=6d298ce0-ca65-41c5-b3e7-ebf1c3e4d780	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Diecinueve	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-143.mp3?alt=media&token=22e0b89f-764e-4880-bce7-2c4f6bfbd1c0	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	79	".trim()), { orden: 79, isCompuesta: true, texto_1: "	 Rick: Twenty…	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-144.mp3?alt=media&token=65e7dc22-7a77-4ee3-85e9-71ad0179da99	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	 Waitress: veinte	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-145.mp3?alt=media&token=e4974e42-9c2a-4f86-a83f-565894eea7af	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	80	".trim()), { orden: 80, isCompuesta: true, texto_1: "	 Rick: Very good!… 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-146.mp3?alt=media&token=0a21d4f6-bb6b-4d7a-98ba-48d8bfe85dab	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Waitress: Thank you.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-147.mp3?alt=media&token=94023971-8d04-4d6a-b48b-f536fa6afb13	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	81	".trim()), { orden: 81, isCompuesta: true, texto_1: "	¿Cuantas palabras hay en las siguientes frases? Cuéntalas. ¡Cuidado! las contracciones, como I’m = I am y It’s = It is, cuentan como dos palabras. Escucharás cada frase dos veces.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-148.mp3?alt=media&token=2ac97c48-b340-4680-8103-374b291a38c5	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1.   How much is this shirt?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-149.mp3?alt=media&token=defaffc9-88e1-4963-a2c2-b0b0b54e61b5	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	82	".trim()), { orden: 82, isCompuesta: true, texto_1: "	2.   Where can I buy a hat?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-150.mp3?alt=media&token=8c08067b-c5ab-453a-8708-d0187e1f245f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.   I’m just looking.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-151.mp3?alt=media&token=c1653cd1-aabe-4958-9e9e-ac281b424ecb	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	83	".trim()), { orden: 83, isCompuesta: true, texto_1: "	4.   I’d like to buy a shirt.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-152.mp3?alt=media&token=d18fcd6a-9e90-497c-853c-1df70b616124	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	5.      It’s seventeen euros, please.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-153.mp3?alt=media&token=dbda87a9-b119-4b9f-aff5-d837f6e3f15c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	84	".trim()), { orden: 84, isCompuesta: true, texto_1: "	Escucha las respuestas:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-154.mp3?alt=media&token=1ecae380-fce6-44fa-b78b-9c13732d5c39	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1. How much is this shirt? (5 palabras)  	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-155.mp3?alt=media&token=5a31757b-2ab7-4d03-9557-164618392aba	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	85	".trim()), { orden: 85, isCompuesta: true, texto_1: "	2.Where can I buy a hat? (6 palabras)	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-156.mp3?alt=media&token=5f849dbb-bad8-4c8a-8ab8-bcf634b808de	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.I’m just looking. (4 palabras)	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-157.mp3?alt=media&token=68c9e473-5a8b-4b19-9662-d0d31da2d743	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	86	".trim()), { orden: 86, isCompuesta: true, texto_1: "	4. I’d like to buy a shirt. (7 palabras)	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-156.mp3?alt=media&token=5f849dbb-bad8-4c8a-8ab8-bcf634b808de	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	5. It’s seventeen euros, please. (5 palabras)	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-159.mp3?alt=media&token=ce4156f1-fd0d-4c7f-93a9-6e865a0365c0	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	87	".trim()), { orden: 87, isCompuesta: true, texto_1: "	 Escucha las frases otra vez y repítelas:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-160.mp3?alt=media&token=8ddaed8e-f267-43bc-b2f7-be6ec8ae5dec	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1.   How much is this shirt?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-161.mp3?alt=media&token=1162d52a-5d9a-4a48-869a-b938e4944e40	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	88	".trim()), { orden: 88, isCompuesta: true, texto_1: "	2.   Where can I buy a hat?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-162.mp3?alt=media&token=908da274-89ef-4871-a4a9-7aceb86a05f8	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.   I’m just looking.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-163.mp3?alt=media&token=1a584333-9da6-4c25-96b3-d2c95af18c34	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	89	".trim()), { orden: 89, isCompuesta: true, texto_1: "	4.   I’d like to buy a shirt.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-164.mp3?alt=media&token=0925a2d8-8e87-4ddb-b050-97bd82b50b3b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	5.      It’s seventeen euros, please.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-165.mp3?alt=media&token=b20d35bc-8534-478f-8904-548acfd532d4	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	90	".trim()), { orden: 90, isCompuesta: true, texto_1: "	El verbo ‘escribir’ en inglés es:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-166.mp3?alt=media&token=08c7888b-99d0-4a8f-868c-d1c798721f7b	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fescribir.jpg?alt=media&token=dcac1875-82a2-4f53-9bdb-bb1a0b26dc77	".trim(), texto_2: "	To write	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-167.mp3?alt=media&token=ebfc47c4-a924-4aff-bc85-84c6d4b8f8ec	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	91	".trim()), { orden: 91, isCompuesta: true, texto_1: "	¿Puede escribir el precio, por favor?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-168.mp3?alt=media&token=2c3403ee-504f-41c8-8aa1-1776ca12bb83	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fescribir.jpg?alt=media&token=dcac1875-82a2-4f53-9bdb-bb1a0b26dc78	".trim(), texto_2: "	Can you write the price, please?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-169.mp3?alt=media&token=18566e09-2e8e-4e2d-af79-8549c42cf8bb	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	92	".trim()), { orden: 92, isCompuesta: true, texto_1: "	Rebajas	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-170.mp3?alt=media&token=ac2830e8-c55d-4563-81bc-0b75cc867f29	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	Sales	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-171.mp3?alt=media&token=3ef75ee6-5bb4-4e2e-9878-08d1278adfc7	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	93	".trim()), { orden: 93, isCompuesta: true, texto_1: "	Está rebajado / está rebajada	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-172.mp3?alt=media&token=77fc12d8-f36b-4e27-9ed5-fbc02db06042	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	It’s on sale	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-173.mp3?alt=media&token=d8ab17d4-d3c6-4f8a-b938-30ea88ba88d8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	94	".trim()), { orden: 94, isCompuesta: true, texto_1: "	Caro	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-174.mp3?alt=media&token=2dc887a4-001b-42f6-b4a8-2002f5babe1c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	Expensive	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-175.mp3?alt=media&token=e2250a15-ba8a-43fa-83d5-6c6ddffc4fe2	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	95	".trim()), { orden: 95, isCompuesta: true, texto_1: "	Muy	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-176.mp3?alt=media&token=d8723937-4eb9-47a9-ba05-99b28064d5d1	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	Very	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-177.mp3?alt=media&token=e9b15ce6-22a5-4024-b9c4-28d5f50036f3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	96	".trim()), { orden: 96, isCompuesta: true, texto_1: "	Es muy caro.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-178.mp3?alt=media&token=df389726-7d40-4daf-a792-c6dea321d036	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	That’s very expensive.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-179.mp3?alt=media&token=1540294b-d8f6-4f32-9a93-3d79a33be38f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	97	".trim()), { orden: 97, isCompuesta: true, texto_1: "	Demasiado	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-180.mp3?alt=media&token=19d777ca-f920-4143-9e98-0c1973a6f62c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	Too	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-181.mp3?alt=media&token=edd29049-a151-4f6c-a303-a84053322a64	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	98	".trim()), { orden: 98, isCompuesta: true, texto_1: "	Es demasiado caro.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-182.mp3?alt=media&token=66260a99-7842-4040-b282-e1a822b6e106	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	That’s too expensive.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-183.mp3?alt=media&token=9ef60eca-5980-49ca-bd58-94e5179a16ea	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	99	".trim()), { orden: 99, isCompuesta: true, texto_1: "	Barato	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-184.mp3?alt=media&token=694b455d-48b2-42cf-9135-00fa8a122430	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	Cheap	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-185.mp3?alt=media&token=5d8ccba0-fb9e-4467-82c8-cda808e0776f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	100	".trim()), { orden: 100, isCompuesta: true, texto_1: "	¡Que barato! 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-186.mp3?alt=media&token=c6e8f87d-b45c-4d08-9a75-33af0c67d019	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frebajas.jpg?alt=media&token=17e0ed33-bb31-45b8-b7f8-b07a5ea7ad5c	".trim(), texto_2: "	That’s cheap!	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-187.mp3?alt=media&token=ed17a9c2-0a66-424a-9fd6-70d75e1098a1	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	101	".trim()), { orden: 101, isCompuesta: true, texto_1: "	El verbo ‘probar’ en inglés es:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-188.mp3?alt=media&token=09cc6830-3396-4c59-b3a0-2c7f08713265	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	To try on	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-189.mp3?alt=media&token=eef0f156-0aee-462b-b43b-b6ffcccf14d2	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	102	".trim()), { orden: 102, isCompuesta: false, texto_1: "	Suena como una sola palabra pero son dos try y on. Escucha y repite: try on try on	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-190.mp3?alt=media&token=9f9dc1d3-5cec-4882-a81b-cc5730ab6ee6	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	103	".trim()), { orden: 103, isCompuesta: true, texto_1: "	Chaqueta	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-191.mp3?alt=media&token=7ab4ab90-0aec-4481-9ffe-7c6b13694d52	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FChaqueta.jpg?alt=media&token=9c06691d-464d-412e-b790-3e58339db54f	".trim(), texto_2: "	Jacket	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-192.mp3?alt=media&token=b9163f4b-d672-4c4a-81ce-779709cc0d2e	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	104	".trim()), { orden: 104, isCompuesta: true, texto_1: "	Can I try on this jacket?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-193.mp3?alt=media&token=4dfec141-f011-4dd3-be51-9034c1565261	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FChaqueta.jpg?alt=media&token=9c06691d-464d-412e-b790-3e58339db54f	".trim(), texto_2: "	Ahora, repite la misma pregunta substituyendo jacket por cada una de las siguientes palabras. Intenta decir tu frase después del tono. 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-194.mp3?alt=media&token=eca16a6b-a40b-4a7f-9c18-2b84a6e74121	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	105	".trim()), { orden: 105, isCompuesta: true, texto_1: "	This shirt…Can I try on this shirt?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-195.mp3?alt=media&token=563a0aed-105e-40b8-a8e9-2a1b549d4b8c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	These trousers..Can I try on these trousers? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-196.mp3?alt=media&token=49a6573c-eaf8-4283-8a70-5579c0d4c0d7	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	106	".trim()), { orden: 106, isCompuesta: true, texto_1: "	 These shoes…Can I try on these shoes?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-197.mp3?alt=media&token=80fc3e26-f4ce-4107-9fd9-6dd3feb03b82	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	This sweater…Can I try on this sweater? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-198.mp3?alt=media&token=cb5a9f85-8e4f-4e26-bcb3-5c7a5c1d54f5	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	107	".trim()), { orden: 107, isCompuesta: true, texto_1: "	This T-shirt…Can I try on this T-shirt?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-199.mp3?alt=media&token=92e531d5-d027-4551-9d1e-150cdc6b3f29	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	This hat…Can I try on this hat?  	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-200.mp3?alt=media&token=39a672b7-5e8e-4d3b-b551-375c5290023b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	108	".trim()), { orden: 108, isCompuesta: true, texto_1: "	This dress…Can I try on this dress?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-201.mp3?alt=media&token=5383b08b-3f39-4090-b46c-fae1cfa253c5	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	This coat…Can I try on this coat?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-202.mp3?alt=media&token=3eef26ec-9a76-4adf-92e9-c102b44270a7	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	109	".trim()), { orden: 109, isCompuesta: true, texto_1: "	Los probadores	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-203.mp3?alt=media&token=9acc8b78-fea5-4d4d-83df-e6361a97ab67	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa973	".trim(), texto_2: "	Changing rooms	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-204.mp3?alt=media&token=63100d87-7711-453c-8fc0-fbb15d85dac5	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	110	".trim()), { orden: 110, isCompuesta: true, texto_1: "	¿Te acuerdas la palabra room – ‘habitación’ que hemos encontrado en la lección de alojamiento? a single room, a double room. Pues el verbo to change significa cambiar, y los changing rooms son los probadores.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-205.mp3?alt=media&token=5c2f5a93-d874-4982-9cd6-ec742fc22651	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa974	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	111	".trim()), { orden: 111, isCompuesta: false, texto_1: "	Escucha y repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-206.mp3?alt=media&token=0de34d64-782a-4a1b-a3bb-b0b5fe428ad5	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa975	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	112	".trim()), { orden: 112, isCompuesta: true, texto_1: "	Changing rooms	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-207.mp3?alt=media&token=7e75b244-95e2-45c6-ae7e-a4c2e5d8317e	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa976	".trim(), texto_2: "	¿Dónde están los probadores?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-208.mp3?alt=media&token=d42fdbe6-14ad-409a-9d44-6a821434e819	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	113	".trim()), { orden: 113, isCompuesta: true, texto_1: "	Where are the changing rooms?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-209.mp3?alt=media&token=8b2e3a3a-75cb-4dd3-9beb-4c561c82d1ff	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa977	".trim(), texto_2: "	I’d like to try on these jeans. 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-210.mp3?alt=media&token=c0900306-2af6-4a98-ba99-24ade4acf50b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	114	".trim()), { orden: 114, isCompuesta: false, texto_1: "	Can I try on these jeans?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-211.mp3?alt=media&token=4d83799c-28b2-4b5f-ae03-cc49b02433cd	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fprobadores.jpg?alt=media&token=97854664-68f6-41b3-af07-fd03623fa978	".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	115	".trim()), { orden: 115, isCompuesta: true, texto_1: "	Talla	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-212.mp3?alt=media&token=3b85796a-36e9-4082-b846-137c05354776	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fmetro.jpg?alt=media&token=d003c3b0-ab35-4152-983e-f98d9a8c0faf	".trim(), texto_2: "	Size	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-213.mp3?alt=media&token=df00838f-ec4e-4ce8-989a-29f4eafefc91	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	116	".trim()), { orden: 116, isCompuesta: true, texto_1: "	¿Cuál es su talla?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-214.mp3?alt=media&token=9c4d69fd-a5ce-4153-bfe5-c4143e74f0a2	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fmetro.jpg?alt=media&token=d003c3b0-ab35-4152-983e-f98d9a8c0faf	".trim(), texto_2: "	What’s your size?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-215.mp3?alt=media&token=deaf5063-a8b2-458b-a1ae-1784f6ec75b3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	117	".trim()), { orden: 117, isCompuesta: true, texto_1: "	La palabra your significa tu o tus (su o sus de usted), y también vuestro/a, Vuestros/as, su/sus (de ustedes)	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-216.mp3?alt=media&token=d4c7f6e6-1d32-4be3-8b6d-136f0f08c2e4	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	What’s your name? ¿Cuál es tu nombre?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-217.mp3?alt=media&token=bf10761e-7ba1-4e26-81d1-ef95f276b154	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	118	".trim()), { orden: 118, isCompuesta: true, texto_1: "	Is this your coat? – ¿Es este su abrigo?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-218.mp3?alt=media&token=1747f3ca-6132-410b-b5ff-6e8abcd56e06	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fropa.jpg?alt=media&token=8d12a175-0fe3-4e72-9960-9e164aca85bb	".trim(), texto_2: "	Are these your jeans? – Estos son vuestros vaqueros?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-219.mp3?alt=media&token=dcef5803-9148-4ab8-845c-e887cbbaa385	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	119	".trim()), { orden: 119, isCompuesta: true, texto_1: "	My significa Mi/mis	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-220.mp3?alt=media&token=6259b85c-1d54-4307-bbeb-3752dda05928	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	My name’s José. - Mi nombre es José.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-221.mp3?alt=media&token=de368558-3008-4cb3-819d-a18023ed9ea3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	120	".trim()), { orden: 120, isCompuesta: true, texto_1: "	His significa su/sus (de él)	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-222.mp3?alt=media&token=60364bcd-9146-4b38-9835-dae16691b955	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Is this his car? - ¿Este es su coche?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-223.mp3?alt=media&token=4f684ba2-cb88-480c-b0fa-6f3376ab8703	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	121	".trim()), { orden: 121, isCompuesta: true, texto_1: "	This is not his house. Esta no es su casa.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-224.mp3?alt=media&token=c5502f20-81ff-4566-9ae9-afb6825a6ee1	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Her significa su/sus (de ella)	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-225.mp3?alt=media&token=d79c6a71-4890-4c28-9c11-ab86648562d8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	122	".trim()), { orden: 122, isCompuesta: true, texto_1: "	Where’s her ticket? - Dónde está su billete?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-226.mp3?alt=media&token=1d76aa89-6634-40af-bd0c-5a44bfd6bc18	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Our significa nuestro/a, nuestros/as	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-227.mp3?alt=media&token=a1191e6b-38d7-4e8d-9ec1-e964bc8202a0	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	123	".trim()), { orden: 123, isCompuesta: true, texto_1: "	Is this our room key? – Esta es la llave de nuestro dormitorio?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-228.mp3?alt=media&token=689d021a-96dd-413e-afea-6c9e4f6b16a7	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Their significa su, sus /de ellos, de ellas	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-229.mp3?alt=media&token=0082afd6-b156-4b55-b2ed-7aa7c1219ce2	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	124	".trim()), { orden: 124, isCompuesta: false, texto_1: "	This is their bill. 9 is not our table. Our table is 14. – Esta es la cuenta de ellos. La nueve no es nuestra mesa. Nuestra mesa es la catorce.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-230.mp3?alt=media&token=4579d9bd-de01-429e-acc3-3dd75f69a658	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	125	".trim()), { orden: 125, isCompuesta: false, texto_1: "	Escucha y repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-231.mp3?alt=media&token=c1a015e3-c89a-4ebb-badb-5df7c910d16b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	126	".trim()), { orden: 126, isCompuesta: true, texto_1: "	My 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-232.mp3?alt=media&token=021bdc48-2be5-415a-bf0d-3aacd348a663	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Your 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-233.mp3?alt=media&token=fed61d3f-abb9-4ff1-a48a-ddf71c661de3	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	127	".trim()), { orden: 127, isCompuesta: true, texto_1: "	His 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-234.mp3?alt=media&token=08e5359c-b19e-4883-8d59-6dbe1c6bebb8	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Her 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-235.mp3?alt=media&token=f4687c6c-0b0e-4e5e-9564-6ed13cbed1ee	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	128	".trim()), { orden: 128, isCompuesta: true, texto_1: "	Our 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-236.mp3?alt=media&token=45c37922-3a6f-46f6-a020-e4b5322689eb	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Their	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-237.mp3?alt=media&token=c7df5d41-a12e-439f-98e7-ae6a8bf956bc	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	129	".trim()), { orden: 129, isCompuesta: true, texto_1: "	Mi talla es…..	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-238.mp3?alt=media&token=cd2a204c-31bb-444d-8a23-be2bc6ae55ca	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	My size is……	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-239.mp3?alt=media&token=b83a524d-5f70-45d4-b3d4-470426cc6c24	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	130	".trim()), { orden: 130, isCompuesta: true, texto_1: "	What’s his size? 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-240.mp3?alt=media&token=1113bb7a-f21b-43d4-9fe5-f7d60d2d3ca1	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	What’s her size?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-241.mp3?alt=media&token=d3ec7188-76d6-4ecc-9e5f-66a28aa7458f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	131	".trim()), { orden: 131, isCompuesta: true, texto_1: "	Sorry, it’s not my size.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-242.mp3?alt=media&token=cc5481c1-8808-43ee-a92f-6f6fd424cea3	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	This is our hotel.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-243.mp3?alt=media&token=a79efcad-fea1-4fd5-ad86-cf8ee422687f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	132	".trim()), { orden: 132, isCompuesta: true, texto_1: "	It’s their newspaper.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-244.mp3?alt=media&token=8e5ac2cb-70a2-4be5-89ee-16a5e5afab63	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	 Is this your room?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-245.mp3?alt=media&token=eb798eea-9fd3-4b41-bfb5-8f66ac195419	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	133	".trim()), { orden: 133, isCompuesta: true, texto_1: "	¿Tiene la talla catorce?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-246.mp3?alt=media&token=3763be57-68fd-4149-9a42-c181ea6e20a3	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Do you have size 14?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-247.mp3?alt=media&token=feca8f45-729e-4395-a0dd-f6bca63d2a1d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	134	".trim()), { orden: 134, isCompuesta: false, texto_1: "	Escucha y repite los colores: 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-248.mp3?alt=media&token=476ebb75-3cab-459f-a136-4d1407f346d8	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	135	".trim()), { orden: 135, isCompuesta: true, texto_1: "	Negro	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-249.mp3?alt=media&token=4a88c0d2-5e8a-48eb-98cb-41c3ff8f31c9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FNegro.png?alt=media&token=07d5cbbc-c1a2-4e8b-b9c1-bbbf4d356955	".trim(), texto_2: "	Black	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-250.mp3?alt=media&token=2290298c-ca23-430d-8a10-88fa7e391cb8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	136	".trim()), { orden: 136, isCompuesta: true, texto_1: "	Blanco	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-251.mp3?alt=media&token=2c77ac1d-e8d6-4f83-a286-0a8554b08658	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FBlanco.jpg?alt=media&token=7e736faf-8be0-42f9-ade3-188ba4d3ce98	".trim(), texto_2: "	White	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-252.mp3?alt=media&token=7e5de0ec-e79d-4dd7-934a-ff5b50996523	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	137	".trim()), { orden: 137, isCompuesta: true, texto_1: "	Rojo	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-253.mp3?alt=media&token=a388d299-42e6-4418-a2a1-482e578eee6d	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FRojo.jpg?alt=media&token=46a9ee26-aa35-4a38-973b-dd7bc3c73d62	".trim(), texto_2: "	Red	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-254.mp3?alt=media&token=33906aea-2d47-4dc9-8f37-a8f1bc16a77f	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	138	".trim()), { orden: 138, isCompuesta: true, texto_1: "	Azul	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-255.mp3?alt=media&token=ee76b1e1-dec5-4dbe-ab7b-981a6f33f2a9	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAzul.jpg?alt=media&token=a79917f5-9069-4c00-bcac-c1f1a384c2b5	".trim(), texto_2: "	Blue	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-256.mp3?alt=media&token=6f892b9e-029f-43d1-83cb-ceb925b6cb70	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	139	".trim()), { orden: 139, isCompuesta: true, texto_1: "	Amarillo 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-257.mp3?alt=media&token=30eba40d-7ac5-4647-82b0-9312618f565d	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAmarillo.jpg?alt=media&token=f0afa860-015c-4f40-b4e6-f333bd07c919	".trim(), texto_2: "	Yellow	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-258.mp3?alt=media&token=09e02c6d-1bbf-44d5-90d2-068245e2061e	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	140	".trim()), { orden: 140, isCompuesta: true, texto_1: "	Verde	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-259.mp3?alt=media&token=9130123e-fc2b-464f-9254-cf67f452d99e	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVerde.jpg?alt=media&token=1870dfcc-cdb6-466e-b4c0-85ca92dcd59c	".trim(), texto_2: "	Green	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-260.mp3?alt=media&token=4f84b291-7922-4879-beeb-bef3d436e253	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	141	".trim()), { orden: 141, isCompuesta: true, texto_1: "	Naranja	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-261.mp3?alt=media&token=a8f96f33-d405-48e9-9b65-d094dbf9cc14	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FNaranja.jpg?alt=media&token=86a8ec88-1c50-41b0-913f-906c3752371d	".trim(), texto_2: "	Orange	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-262.mp3?alt=media&token=0ebf4fc9-1f0a-464c-91b1-42083cf29aea	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	142	".trim()), { orden: 142, isCompuesta: true, texto_1: "	Marrón 	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-263.mp3?alt=media&token=838c2a63-c021-4a7a-8017-8dace5ddc220	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Brown	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-264.mp3?alt=media&token=6af52b70-c3f9-4da0-93a1-1866fd1f8cdb	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	143	".trim()), { orden: 143, isCompuesta: true, texto_1: "	Gris	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-265.mp3?alt=media&token=2f4c1180-5b8e-47a5-9130-9b3e8dc32a3c	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FGris.jpg?alt=media&token=b79b2253-a793-4600-991b-49c4a2bc4b2b	".trim(), texto_2: "	Grey	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-266.mp3?alt=media&token=2b26d546-6686-4831-88f4-733f751308e8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	144	".trim()), { orden: 144, isCompuesta: true, texto_1: "	Me gusta el abrigo gris	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-267.mp3?alt=media&token=ad0b2d26-ddb4-4557-81af-0a2aa399ffc3	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I like the grey coat.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-268.mp3?alt=media&token=4e17adf5-6696-4c31-a445-017767bcd41a	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	145	".trim()), { orden: 145, isCompuesta: true, texto_1: "	I like the orange shoes.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-269.mp3?alt=media&token=bfbdfaaf-c870-48af-9bfc-800b99e82933	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	¿Cuánto vale el vestido azul?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-270.mp3?alt=media&token=fd52f784-c4f5-480a-b52f-3af4793db764	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	146	".trim()), { orden: 146, isCompuesta: true, texto_1: "	How much is the blue dress?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-271.mp3?alt=media&token=77d876ff-8500-47d0-b170-782da6be6fe9	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Da te cuenta que en inglés el adjetivo viene antes del nombre:	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-272.mp3?alt=media&token=eff2c85a-e595-449f-9a32-4ee96724be69	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	147	".trim()), { orden: 147, isCompuesta: false, texto_1: "	The blue dress	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-273.mp3?alt=media&token=0d0f7823-a4d3-437e-b4bb-8ea6843cfb50	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	148	".trim()), { orden: 148, isCompuesta: true, texto_1: "	En español normalmente viene después:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-274.mp3?alt=media&token=ab1e4e86-b99d-4100-b381-fb9a281e8ee5	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	‘El vestido azul’	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-275.mp3?alt=media&token=70733138-b3b4-4446-af49-fde0d5f5205c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	149	".trim()), { orden: 149, isCompuesta: false, texto_1: "	El articulo determinado’the’ se usa por los 4 artículos en español; el, los, la y las.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-276.mp3?alt=media&token=cfc36cde-1759-47f4-8b76-e85c70417ef0	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	150	".trim()), { orden: 150, isCompuesta: true, texto_1: "	El abrigo blanco	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-277.mp3?alt=media&token=41cafc7b-70b6-484b-8eb0-a20ceb56b5bc	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	The white coat	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-278.mp3?alt=media&token=2cfbfe92-86be-4cd7-83b7-a3ff0a3a6c5d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	151	".trim()), { orden: 151, isCompuesta: true, texto_1: "	Los pantalones azules	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-279.mp3?alt=media&token=dc36bfc5-93c8-4bf0-8286-9618aed98b0b	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	The blue trousers	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-280.mp3?alt=media&token=861dc70b-c31f-43b8-b04e-e0ebbbc9a4c9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	152	".trim()), { orden: 152, isCompuesta: true, texto_1: "	La camisa roja	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-281.mp3?alt=media&token=18f4a7e9-c38c-4a97-be14-61dda5c0281d	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	The red T-shirt	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-282.mp3?alt=media&token=2e231c8d-a5ed-4e6d-9c77-e2c62f3a9c71	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	153	".trim()), { orden: 153, isCompuesta: true, texto_1: "	Los zapatos negros	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-283.mp3?alt=media&token=ebe9b93f-7cd0-4687-8472-e204355e25a0	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	The black shoes	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-284.mp3?alt=media&token=b8d20918-0698-4a19-bb72-bb5cc401e9c9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	154	".trim()), { orden: 154, isCompuesta: true, texto_1: "	La mujer vestida de rojo	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-285.mp3?alt=media&token=e775d54e-2be7-4937-a768-12d08197ebcc	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	The Lady in red	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-286.mp3?alt=media&token=1cbba2a7-a3dd-48fc-a953-1801e8b84a25	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	155	".trim()), { orden: 155, isCompuesta: true, texto_1: "	Can I see the red shirt?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-287.mp3?alt=media&token=cb1aafd6-fbc5-450c-9839-6a3072e2966a	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	How much are the yellow T-shirts?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-288.mp3?alt=media&token=575b0d3d-4807-4edd-a76c-81884888cba8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	156	".trim()), { orden: 156, isCompuesta: true, texto_1: "	El verbo ‘llevar’ en inglés es:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-289.mp3?alt=media&token=81b0d64b-4f0b-4473-a49b-beece12d174e	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	To take	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-290.mp3?alt=media&token=9e62c620-0218-46e0-b659-7aaa1ea56e4b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	157	".trim()), { orden: 157, isCompuesta: true, texto_1: "	Me lo llevo.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-291.mp3?alt=media&token=676009e5-bb82-4ba5-9d1f-32f91c1b747e	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’ll take it.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-292.mp3?alt=media&token=15b4a97c-ade3-4d31-8bbc-bfa48bb54058	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	158	".trim()), { orden: 158, isCompuesta: true, texto_1: "	El verbo ‘dejar’ en inglés es:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-293.mp3?alt=media&token=c253b874-04f6-42c3-8259-0a34ca88975f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	To leave	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-294.mp3?alt=media&token=ba1e0de4-9f6f-407e-967a-a3e657d56322	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	159	".trim()), { orden: 159, isCompuesta: true, texto_1: "	Lo dejo	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-295.mp3?alt=media&token=de7d6aa7-c9d4-4fc6-a18d-8ca1fdac3b60	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’ll leave it.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-296.mp3?alt=media&token=a7bf6fc4-4a83-4741-bda1-9e2a36464d83	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	160	".trim()), { orden: 160, isCompuesta: true, texto_1: "	Repite:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-297.mp3?alt=media&token=fa2bb8e4-f12f-4f94-8db4-4328f2dc83a2	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Yes, I’ll take it. 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-298.mp3?alt=media&token=44454b1b-5199-4bee-ba88-196d55ff4633	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	161	".trim()), { orden: 161, isCompuesta: true, texto_1: "	No, I’ll leave it.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-299.mp3?alt=media&token=c83ddd31-29fc-4bdd-a13f-509f85368db5	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’ll take it, please.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-300.mp3?alt=media&token=18ff196b-98f6-45d6-b566-d8aa13a47de9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	162	".trim()), { orden: 162, isCompuesta: false, texto_1: "	I’ll leave it, thank you.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-301.mp3?alt=media&token=0606bf42-cd51-47b7-9d53-79291b7e7266	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	163	".trim()), { orden: 163, isCompuesta: true, texto_1: "	Me llevo estas, por favor.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-302.mp3?alt=media&token=ef45a9a6-e75b-4078-99d9-e3dfd74c3dac	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’ll take these, please.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-303.mp3?alt=media&token=0d25ac7e-a3a0-4f6f-ae94-37952a454259	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	164	".trim()), { orden: 164, isCompuesta: true, texto_1: "	¿Cúanto es?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-304.mp3?alt=media&token=aa2b3094-d46d-42f0-872d-5b3cdc390686	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	How much is it?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-305.mp3?alt=media&token=d50b44f6-40f1-4265-b466-c26abf15d46d	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	165	".trim()), { orden: 165, isCompuesta: true, texto_1: "	How much is it?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-305.mp3?alt=media&token=d50b44f6-40f1-4265-b466-c26abf15d46d	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), urlAudioTexto_2: "		".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	166	".trim()), { orden: 166, isCompuesta: true, texto_1: "	Rick necesita ropa nueva para su cita con Angeles, y se va de compras a las tiendas de Londres. Escucha esta conversación y contesta las siguientes preguntas:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-306.mp3?alt=media&token=17b343b0-d2b6-43d8-8fd4-e03aed143d7f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3. Qué quiere comprar Rick?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-307.mp3?alt=media&token=0c71736d-cb34-4419-8ad8-52f2bf0ca209	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	167	".trim()), { orden: 167, isCompuesta: true, texto_1: "	4. ¿Cuánto cuesta?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-308.mp3?alt=media&token=2f1a71c3-86d9-403a-8476-4a1876b67314	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3. Lo compra o no?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-309.mp3?alt=media&token=26dac70c-b191-44df-b478-46425dccfe88	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	168	".trim()), { orden: 168, isCompuesta: true, texto_1: "	S – Can I help you sir?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-310.mp3?alt=media&token=5608b8eb-f03f-431a-bb02-1b6a8d630e48	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick – Yeah. I’d like to try on this shirt.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-311.mp3?alt=media&token=58da187a-e311-4115-8292-61d044dadca7	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	169	".trim()), { orden: 169, isCompuesta: true, texto_1: "	S – What size are you?	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-312.mp3?alt=media&token=c985efdd-eb57-44b2-a4f3-100183c187ec	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick – This is my size, I think. Size 15. How much is it?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-313.mp3?alt=media&token=01c925fd-9d2c-48f9-a2b0-0ca29ee91e59	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	170	".trim()), { orden: 170, isCompuesta: true, texto_1: "	S – It’s on sale. 45 pounds.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-314.mp3?alt=media&token=76f4927c-7808-4b88-8ad5-e89f2efadced	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick – That’s Okay. Do you have it in black?	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-315.mp3?alt=media&token=2bfc6fb5-74dc-4863-b01c-8aba81f9cfba	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	171	".trim()), { orden: 171, isCompuesta: true, texto_1: "	S – No, I’m sorry. We only have it in white and green.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-316.mp3?alt=media&token=ce9ec854-299a-4896-9579-915bff094bf6	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick – Okay, I’ll leave it. Thank you.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-317.mp3?alt=media&token=9d7cedc7-dade-4ca2-bb01-7dd1f1c04a3c	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	172	".trim()), { orden: 172, isCompuesta: true, texto_1: "	Escuchas las respuestas:	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-318.mp3?alt=media&token=b92d7d2e-fabf-4753-a2ec-33da09b14241	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1.   Qué quiere comprar Rick? a shirt – una camisa	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-319.mp3?alt=media&token=0b9b5482-939a-493b-a547-45a58ec08c3b	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	173	".trim()), { orden: 173, isCompuesta: true, texto_1: "	2.   ¿Cuánto cuesta?45 pounds – 45 libras esterlinas	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-320.mp3?alt=media&token=c3674458-47c1-4ea4-bab6-42eb5014b24f	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.   Lo compro o no? I’ll leave it. – Lo dejo	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-321.mp3?alt=media&token=bc079f86-0242-45a1-a1a0-e20b0b74fad9	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	174	".trim()), { orden: 174, isCompuesta: true, texto_1: "	I think significa ‘pienso’ o ‘ creo’.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-322.mp3?alt=media&token=afbdd073-2f02-4e66-b966-c350ebce6ff5	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	This is my size, I think.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-323.mp3?alt=media&token=d04d16da-7370-4d61-b647-71bd548d1900	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	175	".trim()), { orden: 175, isCompuesta: true, texto_1: "	Escucha y repite las frases que dice Rick.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-324.mp3?alt=media&token=2624e277-8cc0-48a3-8ebe-28ce78bc376d	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like to try on this shirt. 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-325.mp3?alt=media&token=689833e8-117a-4647-b111-c4346a8b6496	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	176	".trim()), { orden: 176, isCompuesta: true, texto_1: "	This is my size	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-326.mp3?alt=media&token=2109b5d3-8812-407f-83e3-f07056ae2e08	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	How much is it? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-327.mp3?alt=media&token=432ed396-9259-4bf2-93c8-c049a0d97ba8	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	177	".trim()), { orden: 177, isCompuesta: true, texto_1: "	That’s okay	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-328.mp3?alt=media&token=7e2fcc62-92c7-4d4d-88f8-19388e3c5834	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Do you have black? 	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-329.mp3?alt=media&token=ffe30809-4c85-4658-bbbe-9e1e477a1eca	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-6").collection("frasesOraciones").doc("leccion-6-" + "	178	".trim()), { orden: 178, isCompuesta: true, texto_1: "	Okay, I’ll leave it.	".trim(), urlAudioTexto_1: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-330.mp3?alt=media&token=4aa099f3-a008-4723-a0d0-85656d539d3d	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Thank you.	".trim(), urlAudioTexto_2: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-6%2FLeccion-6-331.mp3?alt=media&token=1319613d-9a93-46c6-886f-12243f6a5423	".trim(), permitirReplay: true });


    return batch.commit().then(function () {
      console.log("Carga de cursos exitosa!");
    });
  }

  cargarLeccion_5() {
    console.log("Iniciando cargarLeccion_5.....");
    var db = this.afs.firestore;
    var batch = db.batch();

    //batch.set(db.collection("cursosIngles").doc("A1"), { nombre: "A1" });
    //batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5"), { numero: 5, tieneExamen: false, titulo: { textoEspanol: "Lección 5 - Comida y Bebida", textoIngles: "Lesson 5 - Food and Drink", urlAudioEspanol: "https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-5%2FLeccion-5-1.mp3?alt=media&token=bd22d437-52d2-4d46-91e4-1df34b823341", urlAudioIngles: "https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2FA1%2Ffrases-oraciones%2Fmp3%2Fleccion-5%2FLeccion-5-2.mp3?alt=media&token=25633724-4daf-4c56-a948-eded7aa7f4fe" } });

    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	1	".trim()), { orden: 1, isCompuesta: false, texto_1: "	En esta lección, vamos a aprender algunas palabras y frases útiles relacionadas con la comida, la bebida, los restaurantes y los bares. Escucha y repite las siguientes palabras:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	2	".trim()), { orden: 2, isCompuesta: true, texto_1: "	Desayuno	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fdesayuno.jpg?alt=media&token=8ec11c53-ffdb-4cd5-94c3-ff6654c5d48e	".trim(), texto_2: "	Breakfast	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	3	".trim()), { orden: 3, isCompuesta: true, texto_1: "	Comida	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAlmuerzo.jpg?alt=media&token=663f4b02-4699-4089-9d78-7b50e25f8976	".trim(), texto_2: "	Lunch	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	4	".trim()), { orden: 4, isCompuesta: true, texto_1: "	Cena	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCena.jpg?alt=media&token=9efd6ccd-6ab4-43c8-b398-3d2b2c557632	".trim(), texto_2: "	Dinner	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	5	".trim()), { orden: 5, isCompuesta: true, texto_1: "	Té	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "	Tea	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	6	".trim()), { orden: 6, isCompuesta: true, texto_1: "	Taza	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "	Cup	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	7	".trim()), { orden: 7, isCompuesta: true, texto_1: "	Una taza	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "	A cup	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	8	".trim()), { orden: 8, isCompuesta: true, texto_1: "	Una taza de té	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "	A cup of tea	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	9	".trim()), { orden: 9, isCompuesta: false, texto_1: "	Escucha y repite la pronunciación:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	10	".trim()), { orden: 10, isCompuesta: false, texto_1: "	/ə/	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	11	".trim()), { orden: 11, isCompuesta: false, texto_1: "	/ə/ cup	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	12	".trim()), { orden: 12, isCompuesta: false, texto_1: "	/ə/ cup /əv/ tea	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	13	".trim()), { orden: 13, isCompuesta: false, texto_1: "	/ə/cup /əv/ tea	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20taza%20de%20t%C3%A9.jpg?alt=media&token=9a71f489-01cf-41e7-95fd-6ebc4b7da17b	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	14	".trim()), { orden: 14, isCompuesta: true, texto_1: "	Café	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCaf%C3%A9.jpg?alt=media&token=44d3412a-3323-4517-a72f-820b4b8296fa	".trim(), texto_2: "	Coffee	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	15	".trim()), { orden: 15, isCompuesta: true, texto_1: "	/ə/ cup /əv/ coffee	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCaf%C3%A9.jpg?alt=media&token=44d3412a-3323-4517-a72f-820b4b8296fa	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	16	".trim()), { orden: 16, isCompuesta: true, texto_1: "	Café solo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCaf%C3%A9.jpg?alt=media&token=44d3412a-3323-4517-a72f-820b4b8296fa	".trim(), texto_2: "	Black coffee	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	17	".trim()), { orden: 17, isCompuesta: true, texto_1: "	Un café solo, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCaf%C3%A9.jpg?alt=media&token=44d3412a-3323-4517-a72f-820b4b8296fa	".trim(), texto_2: "	I’d like black coffee, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	18	".trim()), { orden: 18, isCompuesta: true, texto_1: "	Leche	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FLeche.jpg?alt=media&token=675cfaa5-e6b4-4297-a6a7-8a51ce055bc2	".trim(), texto_2: "	Milk	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	19	".trim()), { orden: 19, isCompuesta: true, texto_1: "	Café con leche	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcafe-con-leche.jpg?alt=media&token=468e6545-737d-4b1e-aeee-deae15f0cdeb	".trim(), texto_2: "	Coffee with milk	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	20	".trim()), { orden: 20, isCompuesta: true, texto_1: "	Azúcar	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAz%C3%BAcar.jpg?alt=media&token=fa7c9408-7439-4f18-b67f-74365601a334	".trim(), texto_2: "	Sugar	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	21	".trim()), { orden: 21, isCompuesta: true, texto_1: "	Con azúcar, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcafe-con-leche-con-azucar.jpg?alt=media&token=a139fbeb-ecec-4712-8939-8779e3c6e5b8	".trim(), texto_2: "	With sugar, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	22	".trim()), { orden: 22, isCompuesta: true, texto_1: "	Sin azúcar, por favor	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcafe-con-leche.jpg?alt=media&token=468e6545-737d-4b1e-aeee-deae15f0cdeb	".trim(), texto_2: "	No sugar, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	23	".trim()), { orden: 23, isCompuesta: true, texto_1: "	Con leche y azúcar	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcafe-con-leche-con-azucar.jpg?alt=media&token=a139fbeb-ecec-4712-8939-8779e3c6e5b8	".trim(), texto_2: "	With milk and sugar	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	24	".trim()), { orden: 24, isCompuesta: true, texto_1: "	agua	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FAgua.jpg?alt=media&token=a59167ee-a62d-48b2-87c2-b30abe28c544	".trim(), texto_2: "	Water	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	25	".trim()), { orden: 25, isCompuesta: true, texto_1: "	Agua mineral	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fagua-mineral.jpg?alt=media&token=157520e2-92f5-4370-84ca-d12e0682e1f3	".trim(), texto_2: "	Mineral water	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	26	".trim()), { orden: 26, isCompuesta: true, texto_1: "	Una botella	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20botella.jpg?alt=media&token=0af397b2-24d5-48ec-b30a-8dcf5c9e42f5	".trim(), texto_2: "	A bottle	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	27	".trim()), { orden: 27, isCompuesta: true, texto_1: "	Una botella de agua.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20botella%20de%20Agua.jpg?alt=media&token=b5bbb9ac-dee2-4731-9933-8e29f65e012d	".trim(), texto_2: "	A bottle of water	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	28	".trim()), { orden: 28, isCompuesta: true, texto_1: "	Coca Cola	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCoca%20Cola.jpg?alt=media&token=e8700dd3-a22c-48f2-a925-cc1860930df1	".trim(), texto_2: "	Coke	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	29	".trim()), { orden: 29, isCompuesta: true, texto_1: "	Vaso	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVaso.jpg?alt=media&token=2795c4ca-c2cf-423e-8f50-f515ce9e958f	".trim(), texto_2: "	Glass	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	30	".trim()), { orden: 30, isCompuesta: false, texto_1: "	A glass of Coke A glass of milk	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	31	".trim()), { orden: 31, isCompuesta: true, texto_1: "	Zumo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FZumo.jpg?alt=media&token=f9e0424d-5169-42e8-a47c-2af39006103d	".trim(), texto_2: "	Juice	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	32	".trim()), { orden: 32, isCompuesta: true, texto_1: "	Zumo de naranja	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FZumo%20de%20Naranja.jpg?alt=media&token=4d0ecb0d-1903-46f5-b987-5e3883d50528	".trim(), texto_2: "	Orange juice	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	33	".trim()), { orden: 33, isCompuesta: false, texto_1: "	Escucha y repite el sonido consonante:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	34	".trim()), { orden: 34, isCompuesta: false, texto_1: "	/ʤ/	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FZumo%20de%20Naranja.jpg?alt=media&token=4d0ecb0d-1903-46f5-b987-5e3883d50528	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	35	".trim()), { orden: 35, isCompuesta: false, texto_1: "	/orinʤ/	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FZumo%20de%20Naranja.jpg?alt=media&token=4d0ecb0d-1903-46f5-b987-5e3883d50528	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	36	".trim()), { orden: 36, isCompuesta: false, texto_1: "	Orange significa naranja y juice significa zumo. Por lo tanto orange juice es zumo de naranja. Apple juice es zumo de manzana y tomato juice es zumo de tomate.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FZumo%20de%20Naranja.jpg?alt=media&token=4d0ecb0d-1903-46f5-b987-5e3883d50528	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	37	".trim()), { orden: 37, isCompuesta: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	38	".trim()), { orden: 38, isCompuesta: false, texto_1: "	Tomato juice apple juice orange juice	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	39	".trim()), { orden: 39, isCompuesta: true, texto_1: "	Un vaso de zumo de naranja.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	A glass of orange juice	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	40	".trim()), { orden: 40, isCompuesta: true, texto_1: "	Un vaso de zumo de naranja, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like a glass of orange juice, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	41	".trim()), { orden: 41, isCompuesta: true, texto_1: "	hielo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FHielo.jpg?alt=media&token=5a58b9f7-a1e2-42bb-9c08-679d27e1695a	".trim(), texto_2: "	Ice	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	42	".trim()), { orden: 42, isCompuesta: true, texto_1: "	Con hielo, por favor	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FHielo.jpg?alt=media&token=5a58b9f7-a1e2-42bb-9c08-679d27e1695a	".trim(), texto_2: "	With ice, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	43	".trim()), { orden: 43, isCompuesta: true, texto_1: "	Vino	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fvino.jpg?alt=media&token=30ec4082-0d62-4a0e-9675-24c8469a7781	".trim(), texto_2: "	Wine	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	44	".trim()), { orden: 44, isCompuesta: true, texto_1: "	Vino tinto	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVino%20tinto.jpg?alt=media&token=e3ce2664-6a1c-47e9-b29d-28ab518b3c7e	".trim(), texto_2: "	Red wine	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	45	".trim()), { orden: 45, isCompuesta: true, texto_1: "	Un vaso de vino, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fvaso-vino.jpg?alt=media&token=c00e1dd5-ecd6-4e73-b321-5c158bebc3a6	".trim(), texto_2: "	I’d like a glass of wine, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	46	".trim()), { orden: 46, isCompuesta: true, texto_1: "	Una botella de vino.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fbotella-vino-tinto.png?alt=media&token=7b528465-bf0c-4c0c-aef8-5f64840708dc	".trim(), texto_2: "	A bottle of wine.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	47	".trim()), { orden: 47, isCompuesta: true, texto_1: "	Una botella de vino tinto.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fbotella-vino-tinto.png?alt=media&token=7b528465-bf0c-4c0c-aef8-5f64840708dc	".trim(), texto_2: "	A bottle of red wine.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	48	".trim()), { orden: 48, isCompuesta: true, texto_1: "	Casa	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCasa.jpg?alt=media&token=7581c9fa-5a05-46b2-95a3-53d85771f7e8	".trim(), texto_2: "	House	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	49	".trim()), { orden: 49, isCompuesta: true, texto_1: "	Una botella de vino de la casa.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fbotella-vino-tinto.png?alt=media&token=7b528465-bf0c-4c0c-aef8-5f64840708dc	".trim(), texto_2: "	A bottle of house wine.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	50	".trim()), { orden: 50, isCompuesta: true, texto_1: "	Vino blanco	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fvino-banco.jpg?alt=media&token=f2aad2aa-11d9-41d8-b662-f4583a52dd6f	".trim(), texto_2: "	White wine	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	51	".trim()), { orden: 51, isCompuesta: true, texto_1: "	Quisiera una botella de vino blanco, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fvino-banco.jpg?alt=media&token=f2aad2aa-11d9-41d8-b662-f4583a52dd6f	".trim(), texto_2: "	I’d like a bottle of white wine, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	52	".trim()), { orden: 52, isCompuesta: true, texto_1: "	Un camarero	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fun-camarero.jpg?alt=media&token=19b78f68-98bd-4d0f-ae88-917dbba28b2f	".trim(), texto_2: "	A waiter	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	53	".trim()), { orden: 53, isCompuesta: true, texto_1: "	Una camarera	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Funa-camarera.jpg?alt=media&token=1e2f7ae9-a6f6-4286-9859-0895a1520699	".trim(), texto_2: "	A waitress	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	54	".trim()), { orden: 54, isCompuesta: true, texto_1: "	¿Algo más?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Anything else?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	55	".trim()), { orden: 55, isCompuesta: true, texto_1: "	Perdone, ¿Dónde están los servicios?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Excuse me. Where are the toilets?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	56	".trim()), { orden: 56, isCompuesta: false, texto_1: "	Rick y Angeles están muy a gusto juntos hablando en el bar del hotel. Rick pide algo para beber. Escucha y contesta las siguientes preguntas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	57	".trim()), { orden: 57, isCompuesta: false, texto_1: "	1.   Qué tipo de café pide Rick?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	58	".trim()), { orden: 58, isCompuesta: false, texto_1: "	2.   Aparte del café, Rick pide dos cosas más. ¿Qué son?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	59	".trim()), { orden: 59, isCompuesta: false, texto_1: "	Rick – Excuse me, I’d like a coffee, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	60	".trim()), { orden: 60, isCompuesta: false, texto_1: "	Waitress - White or black?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	61	".trim()), { orden: 61, isCompuesta: false, texto_1: "	Rick - With milk, please. No sugar.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	62	".trim()), { orden: 62, isCompuesta: false, texto_1: "	Waitress - Anything else, sir?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	63	".trim()), { orden: 63, isCompuesta: false, texto_1: "	Rick - Yes, a glass of red wine and a bottle of mineral water. Escucha las respuestas:	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	64	".trim()), { orden: 64, isCompuesta: true, texto_1: "	1.   Qué tipo de café pide Rick?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	 – A coffee with milk and no sugar.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	65	".trim()), { orden: 65, isCompuesta: false, texto_1: "	Pide un café con leche, sin azúcar.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	66	".trim()), { orden: 66, isCompuesta: true, texto_1: "	2.   Aparte del café, Rick pide dos cosas. ¿qué son?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	- A glass of red wine and a bottle of mineral water.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	67	".trim()), { orden: 67, isCompuesta: false, texto_1: "	Un vaso de vino tinto y una botella de agua mineral.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	68	".trim()), { orden: 68, isCompuesta: false, texto_1: "	Anything else? significa ‘¿algo más?’ Te acuerdas que significa ‘ sir’?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	69	".trim()), { orden: 69, isCompuesta: false, texto_1: "	Pues la palabra ‘sir’ en inglés es ‘señor’.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	70	".trim()), { orden: 70, isCompuesta: true, texto_1: "	Una mesa	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20mesa.jpg?alt=media&token=0d16eaf0-b017-42f0-b24a-ec5621d9f020	".trim(), texto_2: "	A table	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	71	".trim()), { orden: 71, isCompuesta: true, texto_1: "	Una mesa para tres.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	A table for three.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	72	".trim()), { orden: 72, isCompuesta: false, texto_1: "	Si te fijas en la pronunciación del artículo a y la preposición for se oyen más débiles. No se oye a y for, se oye // y /f/ .Los nombres, ‘table’ y ‘three’ se oyen más fuertes. Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	73	".trim()), { orden: 73, isCompuesta: false, texto_1: "	/ə/ table /fə/ three.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	74	".trim()), { orden: 74, isCompuesta: false, texto_1: "	/ə/ table /fə/ six.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	75	".trim()), { orden: 75, isCompuesta: false, texto_1: "	La preposición of también se oye más débil cuando está colocada en medio de una frase. Se oye /v/. Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	76	".trim()), { orden: 76, isCompuesta: false, texto_1: "	/ə/ bottle /əv/ wine.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	77	".trim()), { orden: 77, isCompuesta: false, texto_1: "	/ə/ cup /əv/ coffee	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	78	".trim()), { orden: 78, isCompuesta: false, texto_1: "	/ə/ glass /əv/ coke.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	79	".trim()), { orden: 79, isCompuesta: false, texto_1: "	/ə/ glass /əv/ milk.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	80	".trim()), { orden: 80, isCompuesta: false, texto_1: "	/ə/ cup /əv/ coffee.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	81	".trim()), { orden: 81, isCompuesta: false, texto_1: "	/ə/ bottle /əv/ beer.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	82	".trim()), { orden: 82, isCompuesta: false, texto_1: "	/ə/ glass /əv/ water.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	83	".trim()), { orden: 83, isCompuesta: true, texto_1: "	Una mesa para dos, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	A table for two, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	84	".trim()), { orden: 84, isCompuesta: true, texto_1: "	Ventana	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FVentana.jpg?alt=media&token=3c425386-45c5-49c5-9acf-72de3d9958fc	".trim(), texto_2: "	Window	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	85	".trim()), { orden: 85, isCompuesta: true, texto_1: "	Al lado	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Next to	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	86	".trim()), { orden: 86, isCompuesta: true, texto_1: "	¿Podemos tener una mesa al lado de la ventana?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Can we have a table next to the window?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	87	".trim()), { orden: 87, isCompuesta: false, texto_1: "	Repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	88	".trim()), { orden: 88, isCompuesta: false, texto_1: "	the window	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	89	".trim()), { orden: 89, isCompuesta: false, texto_1: "	next to the window	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	90	".trim()), { orden: 90, isCompuesta: false, texto_1: "	a table next to the window	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	91	".trim()), { orden: 91, isCompuesta: false, texto_1: "	Can we have a table next to the window? Can we have a table next to the window?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	92	".trim()), { orden: 92, isCompuesta: true, texto_1: "	La carta	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FLa%20carta.jpg?alt=media&token=1935aa60-99bc-4279-a569-b0aa393c953f	".trim(), texto_2: "	The menu	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	93	".trim()), { orden: 93, isCompuesta: true, texto_1: "	Me trae la carta, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FLa%20carta.jpg?alt=media&token=1935aa60-99bc-4279-a569-b0aa393c953f	".trim(), texto_2: "	Can I see the menu, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	94	".trim()), { orden: 94, isCompuesta: false, texto_1: "	¿Te acuerdas que significa el verbo see? Pues to see significa ‘ver’ en español.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	95	".trim()), { orden: 95, isCompuesta: true, texto_1: "	¿Le puedo ayudar?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Can I help you?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	96	".trim()), { orden: 96, isCompuesta: false, texto_1: "	Cuando quieres pedir comida en un restaurante, utiliza el verbo:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a18	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	97	".trim()), { orden: 97, isCompuesta: false, texto_1: "	To order	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a19	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	98	".trim()), { orden: 98, isCompuesta: false, texto_1: "	Listo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a20	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	99	".trim()), { orden: 99, isCompuesta: false, texto_1: "	Ready	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a21	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	100	".trim()), { orden: 100, isCompuesta: false, texto_1: "	Listo para pedir	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a22	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	101	".trim()), { orden: 101, isCompuesta: false, texto_1: "	Ready to order.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a23	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	102	".trim()), { orden: 102, isCompuesta: false, texto_1: "	¿Quieren pedir ya?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a24	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	103	".trim()), { orden: 103, isCompuesta: false, texto_1: "	Are you ready to order?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Frestaurant.jpg?alt=media&token=104e6695-9a0c-4a5a-8cec-2e1fa3d78a25	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	104	".trim()), { orden: 104, isCompuesta: true, texto_1: "	Pan	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPan.jpg?alt=media&token=f5fbbe95-92d9-43a3-813a-3ff58f1ae133	".trim(), texto_2: "	Bread	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	105	".trim()), { orden: 105, isCompuesta: true, texto_1: "	Tráiganos un poco de pan, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPan.jpg?alt=media&token=f5fbbe95-92d9-43a3-813a-3ff58f1ae134	".trim(), texto_2: "	Can we have some bread, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	106	".trim()), { orden: 106, isCompuesta: true, texto_1: "	hamburguesa	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FHamburguesa.jpg?alt=media&token=ff2d46ca-5dbd-4d65-92dd-582510039ad3	".trim(), texto_2: "	Hamburger	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	107	".trim()), { orden: 107, isCompuesta: false, texto_1: "	Can we have two hamburgers, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FHamburguesa.jpg?alt=media&token=ff2d46ca-5dbd-4d65-92dd-582510039ad4	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	108	".trim()), { orden: 108, isCompuesta: true, texto_1: "	Sopa	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSopa.jpg?alt=media&token=40398e56-e92e-469a-9323-542c7595285e	".trim(), texto_2: "	Soup	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	109	".trim()), { orden: 109, isCompuesta: true, texto_1: "	La sopa, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FSopa.jpg?alt=media&token=40398e56-e92e-469a-9323-542c7595285e	".trim(), texto_2: "	I’d like soup, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	110	".trim()), { orden: 110, isCompuesta: false, texto_1: "	Ahora repite la misma frase substituyendo soup por cada una de las siguientes palabras. Intenta decir tu frase después del tono: 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	111	".trim()), { orden: 111, isCompuesta: true, texto_1: "	Ensalada	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fensalada.jpg?alt=media&token=cbe91bd2-50c4-48e4-afaa-aa832f6a0912	".trim(), texto_2: "	Salad	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	112	".trim()), { orden: 112, isCompuesta: false, texto_1: "	I’d like salad, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fensalada.jpg?alt=media&token=cbe91bd2-50c4-48e4-afaa-aa832f6a0914	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	113	".trim()), { orden: 113, isCompuesta: true, texto_1: "	Pescado	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPescado.png?alt=media&token=742fa851-e278-498a-92a4-bd26509b5f64	".trim(), texto_2: "	Fish	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	114	".trim()), { orden: 114, isCompuesta: false, texto_1: "	I’d like fish, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPescado.png?alt=media&token=742fa851-e278-498a-92a4-bd26509b5f66	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	115	".trim()), { orden: 115, isCompuesta: true, texto_1: "	Pollo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPollo.jpg?alt=media&token=a35ab526-6c1c-4016-8277-086736d35cf3	".trim(), texto_2: "	Chicken	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	116	".trim()), { orden: 116, isCompuesta: false, texto_1: "	I’d like chicken, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPollo.jpg?alt=media&token=a35ab526-6c1c-4016-8277-086736d35cf5	".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	117	".trim()), { orden: 117, isCompuesta: true, texto_1: "	Filete de ternera	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Steak	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	118	".trim()), { orden: 118, isCompuesta: false, texto_1: "	I’d like steak, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	119	".trim()), { orden: 119, isCompuesta: true, texto_1: "	Quiero un filete con ensalada.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like steak and salad.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	120	".trim()), { orden: 120, isCompuesta: true, texto_1: "	Patatas fritas	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPatatas%20fritas.jpg?alt=media&token=fadcc397-3407-4d77-9e54-bb7478509b74	".trim(), texto_2: "	French fries (en el ingles americano)	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	121	".trim()), { orden: 121, isCompuesta: true, texto_1: "	Un bocadillo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	A sandwich	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	122	".trim()), { orden: 122, isCompuesta: true, texto_1: "	Un bocadillo, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like a sandwich, please. Queso	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	123	".trim()), { orden: 123, isCompuesta: true, texto_1: "	Un bocadillo de queso.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like a cheese sandwich.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	124	".trim()), { orden: 124, isCompuesta: true, texto_1: "	Jamón 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FJam%C3%B3n.jpg?alt=media&token=2c37a9eb-904c-4473-8d54-e333c30a1060	".trim(), texto_2: "	Ham	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	125	".trim()), { orden: 125, isCompuesta: false, texto_1: "	I’d like a ham sandwich.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	126	".trim()), { orden: 126, isCompuesta: true, texto_1: "	Cuchillo	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCuchillo.jpg?alt=media&token=d112a686-78b1-4a64-bfde-609b05347556	".trim(), texto_2: "	Knife	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	127	".trim()), { orden: 127, isCompuesta: false, texto_1: "	Can I have a knife, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	128	".trim()), { orden: 128, isCompuesta: false, texto_1: "	Ahora repite la misma frase substituyendo knife por cada una de las siguientes palabras. Intenta decir tu frase después del tono: 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	129	".trim()), { orden: 129, isCompuesta: true, texto_1: "	Tenedor	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Ftenedor.jpg?alt=media&token=5af3e8ad-8877-445d-9fe9-3d80cc5cd17b	".trim(), texto_2: "	Fork	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	130	".trim()), { orden: 130, isCompuesta: false, texto_1: "	Can I have a fork, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	131	".trim()), { orden: 131, isCompuesta: true, texto_1: "	Cuchara	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FCuchara.jpg?alt=media&token=5d59098e-2ad0-4819-a646-673b115c9178	".trim(), texto_2: "	Spoon	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	132	".trim()), { orden: 132, isCompuesta: false, texto_1: "	Can I have a spoon, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	133	".trim()), { orden: 133, isCompuesta: true, texto_1: "	Plato	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPlato.png?alt=media&token=20f37779-9540-4dc4-8052-466bf1a6f97f	".trim(), texto_2: "	Plate	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	134	".trim()), { orden: 134, isCompuesta: false, texto_1: "	Can I have a plate, please? Glass	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	135	".trim()), { orden: 135, isCompuesta: false, texto_1: "	Glass	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	136	".trim()), { orden: 136, isCompuesta: false, texto_1: "	Can I have a glass, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	137	".trim()), { orden: 137, isCompuesta: true, texto_1: "	Cenicero	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	ashtray	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	138	".trim()), { orden: 138, isCompuesta: false, texto_1: "	Can I have an ashtray, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	139	".trim()), { orden: 139, isCompuesta: true, texto_1: "	¿Quiere una hamburguesa?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Would you like a hamburger?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	140	".trim()), { orden: 140, isCompuesta: true, texto_1: "	Verdura	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Vegetables	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	141	".trim()), { orden: 141, isCompuesta: false, texto_1: "	La palabra vegetables lleva 3 silabas. Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	142	".trim()), { orden: 142, isCompuesta: false, texto_1: "	/veg-tə-blz/ la primera sílaba es fuerte.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	143	".trim()), { orden: 143, isCompuesta: false, texto_1: "	/veg-tə-blz/	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	144	".trim()), { orden: 144, isCompuesta: true, texto_1: "	El verbo ‘beber’ en inglés es:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	To drink	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	145	".trim()), { orden: 145, isCompuesta: true, texto_1: "	¿Qué quiere para beber?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	What would you like to drink?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	146	".trim()), { orden: 146, isCompuesta: true, texto_1: "	Una cerveza.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FUna%20cerveza.jpg?alt=media&token=db2d3348-5b75-4a84-b999-883ef3953220	".trim(), texto_2: "	I’d like a beer.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	147	".trim()), { orden: 147, isCompuesta: true, texto_1: "	El verbo ‘comer’ en inglés es:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fcomer.jpg?alt=media&token=554418c2-6f20-46de-975d-3b8f7005d0d3	".trim(), texto_2: "	To eat	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	148	".trim()), { orden: 148, isCompuesta: true, texto_1: "	What would you like to eat?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	 I’d like a sandwich.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	149	".trim()), { orden: 149, isCompuesta: true, texto_1: "	Postre	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FPostre.jpg?alt=media&token=e9d904f3-bfe3-4a55-bca8-bc39ab01b59c	".trim(), texto_2: "	Dessert	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	150	".trim()), { orden: 150, isCompuesta: true, texto_1: "	¿Quiere postre?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Would you like dessert?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	151	".trim()), { orden: 151, isCompuesta: true, texto_1: "	Queremos postre, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	We’d like dessert, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	152	".trim()), { orden: 152, isCompuesta: false, texto_1: "	Escucha la contracción:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	153	".trim()), { orden: 153, isCompuesta: false, texto_1: "	We would like	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	154	".trim()), { orden: 154, isCompuesta: false, texto_1: "	We’d like	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	155	".trim()), { orden: 155, isCompuesta: false, texto_1: "	We’d like dessert, please. 	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	156	".trim()), { orden: 156, isCompuesta: false, texto_1: "	We’d like some bread, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	157	".trim()), { orden: 157, isCompuesta: true, texto_1: "	La cuenta	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2FLa%20cuenta.jpg?alt=media&token=7afbf710-0131-481a-a8a9-1b4bdf713cda	".trim(), texto_2: "	The check (en ingles americano)	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	158	".trim()), { orden: 158, isCompuesta: false, texto_1: "	We’d like the bill, please. 	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	159	".trim()), { orden: 159, isCompuesta: false, texto_1: "	We’d like the check, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	160	".trim()), { orden: 160, isCompuesta: true, texto_1: "	Perdone. La cuenta, por favor.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Excuse me! Can we have the bill, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	161	".trim()), { orden: 161, isCompuesta: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	162	".trim()), { orden: 162, isCompuesta: true, texto_1: "	…the bill, please	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	…have the bill, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	163	".trim()), { orden: 163, isCompuesta: true, texto_1: "	…we have the bill, please 	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Can we have the bill, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	164	".trim()), { orden: 164, isCompuesta: true, texto_1: "	Excuse me! Can we have the bill, please? 	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	165	".trim()), { orden: 165, isCompuesta: true, texto_1: "	Rick va a un restaurante en Londres. Escucha su conversación y contesta las siguientes preguntas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1.   ¿Para cuantas personas es la mesa?	".trim(), languageCodeTexto_2: "	es-CO	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	166	".trim()), { orden: 166, isCompuesta: true, texto_1: "	2.   ¿Beben agua, cerveza o vino?	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.      ¿Que es la última cosa que piden?	".trim(), languageCodeTexto_2: "	es-CO	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	167	".trim()), { orden: 167, isCompuesta: true, texto_1: "	S – Good afternoon.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick – Hi. A table for two, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	168	".trim()), { orden: 168, isCompuesta: true, texto_1: "	Rick - Can I see the menu, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	S – Here you are, sir.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	169	".trim()), { orden: 169, isCompuesta: true, texto_1: "	Rick – Thank you.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	S- Are you ready to order?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	170	".trim()), { orden: 170, isCompuesta: true, texto_1: "	Rick - Yes, we’d like one soup and one salad. Chicken and chips, and the fish with vegetables.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	S – Would you like some wine, sir?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	171	".trim()), { orden: 171, isCompuesta: true, texto_1: "	Rick – No, thank you. Just water.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick - Can I have some bread, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	172	".trim()), { orden: 172, isCompuesta: true, texto_1: "	S - Here you are.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Rick - Thank you.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	173	".trim()), { orden: 173, isCompuesta: true, texto_1: "	Rick - Can I have the bill, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	S - Here you are sir.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	174	".trim()), { orden: 174, isCompuesta: false, texto_1: "	Rick - Thank you very much.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	175	".trim()), { orden: 175, isCompuesta: true, texto_1: "	Escucha las respuestas:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	1.   ¿Para cuantas personas es la mesa?..............A table for two. – una mesa para dos.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	176	".trim()), { orden: 176, isCompuesta: true, texto_1: "	2.   ¿Beben agua, cerveza o vino?........ Just water. Beben solo agua. La palabra just significa ‘solo’ en este contexto. ‘Solo agua’ - Just water.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	3.   ¿Que es la última cosa que piden?.........	".trim(), languageCodeTexto_2: "	es-CO	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	177	".trim()), { orden: 177, isCompuesta: true, texto_1: "	Can I have the bill, please? – La cuenta, por favor. Piden la cuenta.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Sir se traduce al español como ‘señor’.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	178	".trim()), { orden: 178, isCompuesta: true, texto_1: "	‘Señora’ en inglés es madam, y para ‘señorita’ se dice miss.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	La traducción de ‘here you are’, en este contexto, es ‘aquí la tiene’ o ‘aquí lo tiene’.	".trim(), languageCodeTexto_2: "	es-CO	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	179	".trim()), { orden: 179, isCompuesta: false, texto_1: "	Escucha y repite las siguientes frases:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	180	".trim()), { orden: 180, isCompuesta: true, texto_1: "	Can I see the menu, please? Can I see the menu, please?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	I’d like soup and salad, please. I’d like soup and salad, please. 	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	181	".trim()), { orden: 181, isCompuesta: true, texto_1: "	No, thank you. Just water. No, thank you. Just water.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	 Can I have the bill, please? Can I have the bill, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	182	".trim()), { orden: 182, isCompuesta: false, texto_1: "	Thank you very much. Thank you very much.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	183	".trim()), { orden: 183, isCompuesta: false, texto_1: "	Ahora vas a escuchar el dialogo otra vez, pero con pausas. Intenta llenar estas pausas con las frases que has practicado anteriormente. 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	184	".trim()), { orden: 184, isCompuesta: true, texto_1: "	Good afternoon, Can I help you?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	………….Can I see the menu, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	185	".trim()), { orden: 185, isCompuesta: true, texto_1: "	Are you ready to order?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	………….I’d like soup and salad, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	186	".trim()), { orden: 186, isCompuesta: true, texto_1: "	Would you like some wine?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	…………..No, thank you. Just water.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	187	".trim()), { orden: 187, isCompuesta: true, texto_1: "	Would you like anything else?	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	………….Can I have the bill, please?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	188	".trim()), { orden: 188, isCompuesta: true, texto_1: "	Here you are.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	………….Thank you very much.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	189	".trim()), { orden: 189, isCompuesta: false, texto_1: "	Los británicos pasan mucha tiempo bebiendo en los pubs. Allí se reúnen con compañeros de trabajo y amigos, ven partidos de fútbol, celebran fiestas y beben grandes cantidades de cerveza rubia, que se llama lager, y hay cerveza de color más oscuro y tostada que se llama ale o bitter. Se toman la cerveza principalmente en dos tamaños; en pintas o pints, que son casi medio litro, o en media pintas o halfpints que es más o menos un cuarto de litro.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	190	".trim()), { orden: 190, isCompuesta: false, texto_1: "	Escucha y repite:	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: false });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	191	".trim()), { orden: 191, isCompuesta: true, texto_1: "	A pint, please	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	A halfpint, please	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	192	".trim()), { orden: 192, isCompuesta: false, texto_1: "	A pint of lager, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "		".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	193	".trim()), { orden: 193, isCompuesta: true, texto_1: "	¡Salud!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Cheers!	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	194	".trim()), { orden: 194, isCompuesta: true, texto_1: "	Two pints of bitter please. Three pints, please.	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Three pints, please.	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	195	".trim()), { orden: 195, isCompuesta: true, texto_1: "	Otra	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	another	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	196	".trim()), { orden: 196, isCompuesta: true, texto_1: "	Another pint, please 	".trim(), languageCodeTexto_1: "	en-US	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	Where’s the toilet?	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	197	".trim()), { orden: 197, isCompuesta: true, texto_1: "	¡Este pub es fantástico!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: false, urlImagen: "		".trim(), texto_2: "	This pub’s fantastic!	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	198	".trim()), { orden: 198, isCompuesta: true, texto_1: "	¡Te quiero!	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fte-amo.png?alt=media&token=1dd39518-a2f1-41bf-b4ca-fa73ce55159a	".trim(), texto_2: "	I love you!	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	199	".trim()), { orden: 199, isCompuesta: true, texto_1: "	Estoy borracho/borracha 	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fborracho.jpg?alt=media&token=c65cf500-abd9-4fed-97fd-bf13cf67773d	".trim(), texto_2: "	I’m drunk! I’m pissed!	".trim(), languageCodeTexto_2: "	en-US	".trim(), permitirReplay: true });
    batch.set(db.collection("cursosIngles").doc("A1").collection("lecciones").doc("leccion-5").collection("frasesOraciones").doc("leccion-5-" + "	200	".trim()), { orden: 200, isCompuesta: true, texto_1: "	I’m pissed en el inglés británico significa ‘estoy borracho’ o ‘borracha’, pero ten cuidado porque no es una expresión muy educada y puedes ofender a alguien en algunas situaciones formales.	".trim(), languageCodeTexto_1: "	es-CO	".trim(), tieneImagen: true, urlImagen: "	https://firebasestorage.googleapis.com/v0/b/aprendiendosolo-92242.appspot.com/o/curso-ingles%2Fimagenes%2Fborracho.jpg?alt=media&token=c65cf500-abd9-4fed-97fd-bf13cf67773d	".trim(), texto_2: "	En el inglés americano, pissed significa enfadado o enfadada o que estás de mal humor	".trim(), languageCodeTexto_2: "	es-CO	".trim(), permitirReplay: true });


    //HACER COMMIT DE LOS DATOS AGREGADOS
    return batch.commit().then(function () {
      console.log("Carga de lecciones exitosa!");
    });
  }

  cargarPreguntasLecturaCritica() {

    /*batch.set(db.collection("pruebasSaber").doc("cuadernillos").collection("cuadernos").doc("lectura-critica").collection("preguntas").doc("pregunta-"+"	1	".trim()), { isCompuesta:	true	, formatoResumen: "	texto	".trim(), tiuloResumen: "	RESPONDA LA PREGUNTA 1 DE ACUERDO CON LA SIGUIENTE INFORMACIÓN	".trim(), textoResumen:	"El primer gran filósofo del siglo diecisiete (si exceptuamos a Bacon y Galileo) fue Descartes, y si alguna vez se dijo de alguien que estuvo a punto de ser asesinado habrá que decirlo de él.
    La historia es la siguiente, según la cuenta Baillet en su Vie de M. Descartes, tomo I, páginas 102-103. En 1621, Descartes, que tenía unos veintiséis años, se hallaba como siempre viajando (pues era inquieto como una hiena) y, al llegar al Elba, tomó una embarcación para Friezland oriental. Nadie se ha enterado nunca de lo que podía buscar en Friezland oriental y tal vez él se hiciera la misma pregunta, ya que, al llegar a Embden, decidió dirigirse al instante a Friezland occidental, y siendo demasiado impaciente para tolerar cualquier demora, alquiló una barca y contrató a unos cuantos marineros.
    Tan pronto habían salido al mar cuando hizo un agradable descubrimiento, al saber que se había encerrado en una guarida de asesinos. Se dio cuenta, dice M. Baillet, de que su tripulación estaba formada por criminales, no aficionados, señores, como lo somos nosotros, sino profesionales cuya máxima ambición, por el momento, era degollarlo.
    
    La historia es demasiado amena para resumirla y a continuación la traduzco cuidadosamente del ori- ginal francés de la biografía: “M. Descartes no tenía más compañía que su criado, con quien conver- saba en francés. Los marineros, creyendo que se trataba de un comerciante y no de un caballero, pensaron que llevaría dinero consigo y pronto llegaron a una decisión que no era en modo alguno ventajosa para su bolsa. Entre los ladrones de mar y los ladrones de bosques, hay esta diferencia, que los últimos pueden perdonar la vida a sus víctimas sin peligro para ellos, en tanto que si los otros llevan a sus pasajeros a la costa, corren grave peligro de ir a parar a la cárcel. La tripulación de M. Descartes tomó sus precauciones para evitar todo riesgo de esta naturaleza. Lo suponían un extran- jero venido de lejos, sin relaciones en el país, y se dijeron que nadie se daría el trabajo de averiguar su paradero cuando desapareciera”. Piensen, señores, en estos perros de Friezland que hablan de un filósofo como si fuese una barrica de ron consignada a un barco de carga. “Notaron que era de ca- rácter manso y paciente y, juzgándolo por la gentileza de su comportamiento y la cortesía de su trato, se imaginaron que debía ser un joven inexperimentado, sin situación ni raíces en la vida, y concluye- ron que les sería fácil quitarle la vida. No tuvieron empacho en discutir la cuestión en presencia suya pues no creían que entendiese otro idioma además del que empleaba para hablar con su criado; como resultado de sus deliberaciones decidieron asesinarlo, arrojar sus restos al mar y dividirse el botín”.
    
    Perdonen que me ría, caballeros, pero a decir verdad me río siempre que recuerdo esta historia, en la que hay dos cosas que me parecen muy cómicas. Una de ellas es el pánico de Descartes, a quien se le debieron poner los pelos de punta, ante el pequeño drama de su propia muerte, funeral, herencia y administración de bienes. Pero hay otro aspecto que me parece aún más gracioso, y es que si los mastines de Friezland hubieran estado “a la altura”, no tendríamos filosofía cartesiana."	".trim(), citaTextoResumen:"	Tomado y adaptado de: De Quincey, T. (1999). Del asesinato considerado como una de las bellas artes. Alianza Editorial.	"trim(), numero:	1	, textoPregunta:"	A juzgar por su estilo, tema y estructura, ¿en cuál de los siguientes contextos estaría inscrito más apropiadamente el pasaje anterior?	".trim(), formatoPregunta:"	texto	".trim(), citaTextoPregunta:"		".trim(), numeroRespuestaCorrecta: 	2	, urlResumen:"		".trim(), urlPregunta:"		".trim() } );	1	En una revista académica, como parte de un artículo sobre los orígenes y la importancia de la filosofía cartesiana.	texto																		
    */

    console.log("Iniciando cargarPreguntasLecturaCritica.....");
    var db = this.afs.firestore;
    /*var batch = db.batch();

    batch.set(db.collection("pruebasSaber").doc("cuadernillos").collection("cuadernos").doc("lectura-critica").collection("preguntas").doc("pregunta-" + "	1	".trim()), {
      isCompuesta: true, formatoResumen: "	texto	".trim(), tiuloResumen: "	RESPONDA LA PREGUNTA 1 DE ACUERDO CON LA SIGUIENTE INFORMACIÓN	".trim(), textoResumen: "El primer gran filósofo del siglo diecisiete (si exceptuamos a Bacon y Galileo) fue Descartes, y si alguna vez se dijo de alguien que estuvo a punto de ser asesinado habrá que decirlo de él.																																																																																																																																																																																																																																																																																".trim()
        + "La historia es la siguiente, según la cuenta Baillet en su Vie de M. Descartes, tomo I, páginas 102-103. En 1621, Descartes, que tenía unos veintiséis años, se hallaba como siempre viajando (pues era inquieto como una hiena) y, al llegar al Elba, tomó una embarcación para Friezland oriental. Nadie se ha enterado nunca de lo que podía buscar en Friezland oriental y tal vez él se hiciera la misma pregunta, ya que, al llegar a Embden, decidió dirigirse al instante a Friezland occidental, y siendo demasiado impaciente para tolerar cualquier demora, alquiló una barca y contrató a unos cuantos marineros.																																																																																																																																																																																																																																																											".trim()
        + "Tan pronto habían salido al mar cuando hizo un agradable descubrimiento, al saber que se había encerrado en una guarida de asesinos. Se dio cuenta, dice M. Baillet, de que su tripulación estaba formada por criminales, no aficionados, señores, como lo somos nosotros, sino profesionales cuya máxima ambición, por el momento, era degollarlo.																																																																																																																																																																																																																																																																																																																														".trim()
        + "																																																																																																																																																																																																																																																																																																																																																																																																																			".trim()
        + "La historia es demasiado amena para resumirla y a continuación la traduzco cuidadosamente del ori- ginal francés de la biografía: “M. Descartes no tenía más compañía que su criado, con quien conver- saba en francés. Los marineros, creyendo que se trataba de un comerciante y no de un caballero, pensaron que llevaría dinero consigo y pronto llegaron a una decisión que no era en modo alguno ventajosa para su bolsa. Entre los ladrones de mar y los ladrones de bosques, hay esta diferencia, que los últimos pueden perdonar la vida a sus víctimas sin peligro para ellos, en tanto que si los otros llevan a sus pasajeros a la costa, corren grave peligro de ir a parar a la cárcel. La tripulación de M. Descartes tomó sus precauciones para evitar todo riesgo de esta naturaleza. Lo suponían un extran- jero venido de lejos, sin relaciones en el país, y se dijeron que nadie se daría el trabajo de averiguar su paradero cuando desapareciera”. Piensen, señores, en estos perros de Friezland que hablan de un filósofo como si fuese una barrica de ron consignada a un barco de carga. “Notaron que era de ca- rácter manso y paciente y, juzgándolo por la gentileza de su comportamiento y la cortesía de su trato, se imaginaron que debía ser un joven inexperimentado, sin situación ni raíces en la vida, y concluye- ron que les sería fácil quitarle la vida. No tuvieron empacho en discutir la cuestión en presencia suya pues no creían que entendiese otro idioma además del que empleaba para hablar con su criado; como resultado de sus deliberaciones decidieron asesinarlo, arrojar sus restos al mar y dividirse el botín”".trim()
        + "		".trim()
        + "Perdonen que me ría, caballeros, pero a decir verdad me río siempre que recuerdo esta historia, en la que hay dos cosas que me parecen muy cómicas. Una de ellas es el pánico de Descartes, a quien se le debieron poner los pelos de punta, ante el pequeño drama de su propia muerte, funeral, herencia y administración de bienes. Pero hay otro aspecto que me parece aún más gracioso, y es que si los mastines de Friezland hubieran estado “a la altura”, no tendríamos filosofía cartesiana.".trim(),
      citaTextoResumen: "	Tomado y adaptado de: De Quincey, T. (1999). Del asesinato considerado como una de las bellas artes. Alianza Editorial.	"trim(), numero: 1, textoPregunta: "	A juzgar por su estilo, tema y estructura, ¿en cuál de los siguientes contextos estaría inscrito más apropiadamente el pasaje anterior?	".trim(), formatoPregunta: "	texto	".trim(), citaTextoPregunta: "		".trim(), numeroRespuestaCorrecta: 2, urlResumen: "		".trim(), urlPregunta: "		".trim()
    });*/

  }


}
