import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { ConfigPines } from '../modelo/config-pines';
import { Pin } from '../modelo/pin';

@Injectable({
  providedIn: 'root'
})
export class PinService {

  maxRegistrosPorLote: number;

  constructor(private afs: AngularFirestore) {
    this.maxRegistrosPorLote = 500;
  }

  consultarPines(municipio: string, escuela: string) {
    var citiesRef = this.afs.firestore.collection('pines');
    if (escuela != null && escuela != "") {
      return citiesRef.where('municipio', '==', municipio)
        .where('escuela', '==', escuela);
    } else {
      return citiesRef.where('municipio', '==', municipio);
    }
  }

  actualizarPin(pin: Pin) {
    this.afs.collection("pines").doc(pin.UID)
      .set({
        escuela: pin.escuela,
        estado: pin.estado,
        //fechaFin: pin.fechaFin,
        modulos: pin.modulos,
        municipio: pin.municipio,
        nombrePin: pin.nombrePin,
        tipo: pin.tipo,
        caduca: false
      })
      .catch(error => {
        console.error("Error writing actualizarPin() ", error);
      });
  }

  mostrarPines(){
    var seqer = this.generarPines();
    seqer.set_prefix('2022');
    seqer.set_seq(1000000);
    var unique = seqer.gensym(); // unique is "Q1000"
    console.log('PIN unique: '+seqer.gensym());
    console.log('PIN unique: '+seqer.gensym());
  }

  generarPines(){
    var prefix = '';
    var seq = 0;
    return {
        set_prefix: function (p) {
            prefix = String(p);
        },
        set_seq: function (s) {
            seq = s;
        },
        gensym: function ( ) {
            var result = prefix + seq;
            seq += 1;
            return result;
        }
    };
  }

  generarPin(pin: Pin, cantidad: number) {
    console.log("Iniciando proceso generaciòn de pines....");
    var numeroLotes = 1;
    var numeroRegistrosAdicionales = 0;
    pin.estado = "Creado";
    pin.caduca = true;
    pin.fechaFin = new Date(2050, 12, 31);
    if (cantidad > this.maxRegistrosPorLote) {
      var auxDato = cantidad / this.maxRegistrosPorLote;
      numeroLotes = parseInt(auxDato.toString());
      numeroRegistrosAdicionales = cantidad % this.maxRegistrosPorLote;
      for (var i = 0; i < numeroLotes; i++) {
        this.cargarLotePines(pin, this.maxRegistrosPorLote);
      }
      if (numeroRegistrosAdicionales > 0) {
        this.cargarLotePines(pin, numeroRegistrosAdicionales);
      }
    } else {
      this.cargarLotePines(pin, cantidad);
    }
  }

  cargarLotePines(pin: Pin, cantidad: number) {    
    var db = this.afs.firestore;
    var batch = db.batch();
    var UID: string;
    var secuencia: number = 0;
    this.obtenerConfiguracionPines().then(configRef => {
      if(configRef.exists){
        const configPines = configRef.data() as ConfigPines;
        secuencia = configPines.sequencia;
        var seqer = this.generarPines();
        var yearCurrent: number = (new Date()).getFullYear();
        if(yearCurrent !== configPines.prefijo){
          configPines.prefijo = yearCurrent;
        }
        seqer.set_prefix(configPines.prefijo);
        seqer.set_seq(secuencia);
        var unique = seqer.gensym();
        if (cantidad <= 500) {
          for (var i = 0; i < cantidad; i++) {
            //UID = this.afs.createId();
            secuencia = secuencia + 1;
            configPines.sequencia = secuencia;
            UID = +seqer.gensym() + '';
            batch.set(db.collection('pines').doc(UID),
              {
                escuela: pin.escuela,
                estado: pin.estado,
                fechaFin: pin.fechaFin,
                modulos: pin.modulos,
                municipio: pin.municipio,
                nombrePin: pin.nombrePin,
                tipo: pin.tipo,
                caduca: pin.caduca
              });
          }
          this.actualizarConfigPines(configPines).then(result => {
            return batch.commit().then(function () {
              console.log("Generacion de pines exitosa!");
            });
          });          
        }
      }
    });    
  }

  verificarPin(pin: string) {
    return this.afs.firestore.collection("pines").doc(pin).get();
  }

  obtenerAllPins() {
    return this.afs.firestore.collection("pines").get();
  }

  obtenerPinsPorNombrePin(nombrePin: string) {
    return this.afs.firestore.collection("pines")
      .where("nombrePin", "==", nombrePin)
      .get();
  }

  obtenerConfiguracionPines(){
    return this.afs.firestore.collection("configGeneral")
    .doc("config-pines").get();
  }

  actualizarConfigPines(configPines: ConfigPines){
    return this.afs.firestore.collection("configGeneral").doc("config-pines")
      .set({
        prefijo: configPines.prefijo,
        sequencia: configPines.sequencia
      })
      .catch(error => {
        console.error("Error writing actualizarConfigPines() ", error);
      });    
  }

}
