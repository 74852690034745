import { Component, OnInit } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { EstadoExamenOrientacion } from '../modelo/estado-examen-orientacion';
import { OrientacionService } from '../servicios/orientacion.service';
import { ChasidePregunta } from '../modelo/chaside-pregunta';
import { Area } from '../modelo/area';

@Component({
  selector: 'app-orientacion',
  templateUrl: './orientacion.component.html',
  styleUrls: ['./orientacion.component.css']
})
export class OrientacionComponent implements OnInit {

  private estadoExamen: EstadoExamenOrientacion;
  private cargarDatosBasicos: boolean;
  private listaPreguntas: ChasidePregunta[];
  private preguntaActual: ChasidePregunta;
  private listaAreas: Area[];
  private areaPrincipal: Area;
  private areaSecundaria: Area;
  public respuestaEstudiante: number = 0;
  porcentageLeccion: number;
  esEntero: boolean;

  constructor(private loginCom: AuthService, private orientacionService: OrientacionService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("orientacion");
    } else {
      this.init();
    }
  }

  init() {
    this.esEntero = true;
    this.cargarDatosBasicos = false;
    this.estadoExamen = new EstadoExamenOrientacion();
    this.estadoExamen.isFinalizado = false;
    this.estadoExamen.listaPreguntas = [];
    this.preguntaActual = new ChasidePregunta();
    this.orientacionService.buscarEstadoActualExamen(this.loginCom.usuarioSession.UID)
      .then(doc => {
        if (doc.exists) {
          const data = doc.data() as EstadoExamenOrientacion;
          this.estadoExamen = data;
          this.estadoExamen.fechaUltimoTest = new Date();
          this.estadoExamen.numeroIntentos = this.estadoExamen.numeroIntentos + 1;
        } else {
          this.estadoExamen.fechaUltimoTest = new Date();
          this.estadoExamen.numeroIntentos = 1;
          this.orientacionService.guardarEstadoExamen(this.loginCom.usuarioSession.UID, this.estadoExamen);
        }
        if (this.estadoExamen.isFinalizado) {
          this.estadoExamen.areasAplicables.forEach(element => {
            if (element.isPrincipal) {
              this.areaPrincipal = element;
            } else {
              this.areaSecundaria = element;
            }
          });
        }
        //this.consultarAreas();
        this.consultarPreguntas();
        this.cargarDatosBasicos = true;
      })
      .catch(error => {
        console.log("Error en buscarEstadoActualExamen()", error);
      });
  }

  consultarAreas() {
    this.listaAreas = [];
    this.orientacionService.buscarAreas()
      .then(lista => {
        lista.forEach(preguntaDoc => {
          const data = preguntaDoc.data() as Area;
          this.listaAreas.push(data);
        });
      })
      .catch(error => {
        console.log("Error en consultarAreas()", error);
      });
  }

  consultarPreguntas() {
    this.listaPreguntas = [];
    this.orientacionService.buscarPreguntas()
      .then(lista => {
        lista.forEach(preguntaDoc => {
          const data = preguntaDoc.data() as ChasidePregunta;
          this.listaPreguntas.push(data);
          if (this.estadoExamen.numeroPregunta == data.numero) {
            this.preguntaActual = data;
          }
        });
        this.calcularPorcentajeExamen();
      })
      .catch(error => {
        console.log("Error en consultarPreguntas()", error);
      });
  }

  siguientePregunta() {
    if (this.respuestaEstudiante == 2) {
      this.preguntaActual.respuesta = true;
    } else {
      this.preguntaActual.respuesta = false;
    }
    this.respuestaEstudiante = 0;
    this.estadoExamen.listaPreguntas.push(this.preguntaActual);
    if (this.preguntaActual.numero == 98) {
      this.estadoExamen.isFinalizado = true;
      this.finalizarExamen();
    } else {
      this.preguntaActual = this.listaPreguntas[this.preguntaActual.numero];
      this.estadoExamen.listaPreguntas = this.listaPreguntas;
      this.estadoExamen.numeroPregunta = this.preguntaActual.numero;
      this.guardarEstadoExamen();
      this.calcularPorcentajeExamen();
    }
    
  }

  guardarEstadoExamen(){
    this.estadoExamen.fechaUltimoTest = new Date();
    this.orientacionService.guardarEstadoExamen(this.loginCom.usuarioSession.UID, this.estadoExamen);
  }

  finalizarExamen() {

    let grupo_C: number = 0;
    let grupo_H: number = 0;
    let grupo_A: number = 0;
    let grupo_S: number = 0;
    let grupo_I: number = 0;
    let grupo_D: number = 0;
    let grupo_E: number = 0;
    let grupo = "";

    //this.estadoExamen.listaPreguntas.push(this.preguntaActual);

    this.estadoExamen.listaPreguntas.forEach(pregunta => {
      grupo = pregunta.grupo.split("-")[0];
      if (pregunta.respuesta) {
        switch (grupo) {
          case "C":
            grupo_C++;
            break;
          case "H":
            grupo_H++;
            break;
          case "A":
            grupo_A++;
            break;
          case "S":
            grupo_S++;
            break;
          case "I":
            grupo_I++;
            break;
          case "D":
            grupo_D++;
            break;
          case "E":
            grupo_E++;
            break;
          default:
            break;
        }
      }
    });

    let grupoPrincipal: string;
    let grupoSecundario: string;

    var valores = [grupo_C, grupo_H, grupo_A, grupo_S, grupo_I, grupo_D, grupo_E];

    var max = Math.max.apply(null, valores);

    var indice = valores.indexOf(max);

    grupoPrincipal = this.obtenerGrupoMayor(indice);

    valores[indice] = 0;

    max = Math.max.apply(null, valores);

    indice = valores.indexOf(max);

    grupoSecundario = this.obtenerGrupoMayor(indice);

    this.areaPrincipal = new Area();
    this.orientacionService.buscarArea(grupoPrincipal)
      .then(doc => {
        if (doc.exists) {
          const data = doc.data() as Area;
          this.areaPrincipal = data;
          this.areaPrincipal.isPrincipal = true;
          this.estadoExamen.areasAplicables.push(this.areaPrincipal);
          this.guardarEstadoExamen();
        }
      })
      .catch(error => {
        console.log("Error en buscarArea()", error);
      });

    this.areaSecundaria = new Area();
    this.orientacionService.buscarArea(grupoSecundario)
      .then(doc => {
        if (doc.exists) {
          const data = doc.data() as Area;
          this.areaSecundaria = data;
          this.areaSecundaria.isPrincipal = false;
          this.estadoExamen.areasAplicables.push(this.areaSecundaria);
          this.guardarEstadoExamen();
        }
      })
      .catch(error => {
        console.log("Error en buscarArea()", error);
      });
  }

  obtenerGrupoMayor(indice: number) {
    let grupo: string;
    switch (indice) {
      case 0:
        grupo = "C";
        break;
      case 1:
        grupo = "H";
        break;
      case 2:
        grupo = "A";
        break;
      case 3:
        grupo = "S";
        break;
      case 4:
        grupo = "I";
        break;
      case 5:
        grupo = "D";
        break;
      case 6:
        grupo = "E";
        break;
      default:
        grupo = "";
    }
    return grupo;
  }

  realizarExamen() {
    this.preguntaActual = this.listaPreguntas[0];
    this.estadoExamen.numeroPregunta = this.preguntaActual.numero;
  }

  limpiarResultados() {
    this.preguntaActual = this.listaPreguntas[0];
    this.estadoExamen = new EstadoExamenOrientacion();
    this.estadoExamen.isFinalizado = false;
    this.estadoExamen.areasAplicables = [];
    this.estadoExamen.listaPreguntas = [];
    this.estadoExamen.numeroPregunta = 0;
    this.orientacionService.buscarEstadoActualExamen(this.loginCom.usuarioSession.UID)
    .then(ref => {
      if(ref.exists){
        const estadoActual = ref.data() as EstadoExamenOrientacion;
        this.estadoExamen.fechaUltimoTest = new Date();
        this.estadoExamen.numeroIntentos = estadoActual.numeroIntentos + 1;
      } 
      this.orientacionService.guardarEstadoExamen(this.loginCom.usuarioSession.UID, this.estadoExamen);
    })
    .catch(error => console.log(error));    
  }

  calcularPorcentajeExamen() {
    this.esEntero = true;
    this.porcentageLeccion = 0;
    this.porcentageLeccion = (100 * this.preguntaActual.numero) / 98;
    if (!Number.isInteger(this.porcentageLeccion)) {
      this.esEntero = false;
    }
  }

}
