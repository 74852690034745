import { Respuesta } from '../modelo/respuesta';
export class Pregunta {
    UID: string;
    isCompuesta: boolean;
    tiuloResumen: string;
    formatoResumen: string;
    urlResumen: string;
    textoResumen: string;
    citaTextoResumen: string;
    numero: number;
    textoPregunta: string;
    formatoPregunta: string;
    urlPregunta: string;
    citaTextoPregunta: string;
    numeroRespuestaCorrecta: number;
    respuestas: Respuesta[];
    constructor() {
        this.UID = null;
        this.numero = 0;
        this.textoPregunta = "";
        this.formatoPregunta = "";
        this.urlPregunta = "";
        this.citaTextoPregunta = "";
        this.numeroRespuestaCorrecta = 0;
        this.respuestas = null;
        this.isCompuesta = false;
        this.tiuloResumen = "";
        this.formatoResumen = "";
        this.urlResumen = "";
        this.textoResumen = "";
        this.citaTextoResumen = "";
    }
}
