import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <a href="#">            
                <span class="footer-text-left">
                <span>Rumbo al Éxito</span>
            </span>
            /
            </a>
            <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>All Rights Reserved</span>
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
