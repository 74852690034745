import { CursosEstado } from '../modelo/cursos-estado';

export class EstadoModuloIngles {
    UID?: string;
    cursosEstados?: CursosEstado[];
    constructor() {
        this.UID = null;
        this.cursosEstados = null;
    }
}
