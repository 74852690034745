import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginComponent } from '../app/login/login.component';
import { CursoA1Component } from '../app/cursos/curso-a1/curso-a1.component';
import { CursoA2Component } from '../app/cursos/curso-a2/curso-a2.component';
import { CursoB1Component } from '../app/cursos/curso-b1/curso-b1.component';
import { CursoB2Component } from '../app/cursos/curso-b2/curso-b2.component';
import { PinComponent } from '../app/pin/pin.component';
import { PruebasSaberComponent } from '../app/pruebas-saber/pruebas-saber.component';
import { OrientacionComponent } from '../app/orientacion/orientacion.component';
import { RegistrarseComponent } from '../app/registrarse/registrarse.component';
import { DiccionarioComponent } from '../app/diccionario/diccionario.component';
import { ExamenesComponent } from '../app/examenes/examenes.component';
import { CursoElementalComponent } from '../app/curso-elemental/curso-elemental.component';
import { AptitudVerbalComponent } from '../app/aptitud-verbal/aptitud-verbal.component';
import { ProfesionIdealComponent } from '../app/profesion-ideal/profesion-ideal.component';
import { DiccionarioPropedeuticoComponent } from './diccionario-propedeutico/diccionario-propedeutico.component';
import { CulturaGeneralComponent } from './cultura-general/cultura-general.component';
import { ReportesComponent } from './reportes/reportes.component';
import { DiccionarioEspanolComponent } from './diccionario-espanol/diccionario-espanol.component';



export const routes: Routes = [
    { path: 'curso-a1', component: CursoA1Component },
    { path: 'curso-a2', component: CursoA2Component },
    { path: 'curso-b1', component: CursoB1Component },
    { path: 'curso-b2', component: CursoB2Component },    
    { path: 'login', component: LoginComponent },
    { path: '', component: LoginComponent },
    { path: 'pin', component: PinComponent },
    { path: 'pruebasSaber', component: PruebasSaberComponent },
    { path: 'orientacion', component: OrientacionComponent },
    { path: 'registrarse', component: RegistrarseComponent },
    { path: 'diccionario', component: DiccionarioComponent },
    { path: 'examenes', component: ExamenesComponent },
    { path: 'curso-elemental', component: CursoElementalComponent },
    { path: 'aptitud', component: AptitudVerbalComponent },
    { path: 'profesion-ideal', component: ProfesionIdealComponent },
    { path: 'diccionario-propedeutico', component: DiccionarioPropedeuticoComponent },
    { path: 'cultura-general', component: CulturaGeneralComponent},
    { path: 'reportes', component: ReportesComponent},
    { path: 'diccionario-espanol', component: DiccionarioEspanolComponent}
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
