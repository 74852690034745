import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Palabra } from '../modelo/palabra';
import { EstadoUsuarioDiccionario } from '../modelo/estado-usuario-diccionario';

@Injectable({
  providedIn: 'root'
})
export class DiccionarioService {

  constructor(private afs: AngularFirestore, private http: HttpClient) { }

  bucarEstadoActualUsuario(usuarioUID: string) {
    return this.afs.firestore.collection("cursosIngles").doc("diccionario")
      .collection("estados").doc(usuarioUID).get();
  }

  bucarConfiguracion() {
    return this.afs.firestore.collection("cursosIngles").doc("diccionario").get();
  }

  guardarEstadoUsuario(estado: EstadoUsuarioDiccionario) {
    console.log("estado:", estado);
    return this.afs.firestore.collection("cursosIngles").doc("diccionario")
      .collection("estados").doc(estado.UID)
      .set({
        "totalPalabras": estado.totalPalabras,
        "ultimaTraduccion": estado.ultimaTraduccion
      })
      .catch(error => {
        console.error("Error writing guardarEstadoUsuario() ", error);
      });
  }

  buscarPalabra(palabra: string) {
    return this.afs.firestore.collection("cursosIngles").doc("diccionario")
      .collection("palabras").where("palabra", "==", palabra).get();
  }

  actualizarPalabra(palabra: Palabra) {
    console.log("Actualizar palabra");
    return this.afs.firestore.collection("cursosIngles").doc("diccionario")
      .collection("palabras").doc(palabra.uid)
      .set(palabra);
  }

  guardarPalabra(palabra: Palabra) {
    console.log("Nueva palabra");
    return this.afs.firestore.collection("cursosIngles").doc("diccionario")
      .collection("palabras")
      .add({
        "audioBase64Frances": palabra.audioBase64Frances,
        "audioBase64Ingles": palabra.audioBase64Ingles,
        "audioBase64Mandarin": palabra.audioBase64Mandarin,
        "audioBase64Portugues": palabra.audioBase64Portugues,
        "audioBase64intaliano": palabra.audioBase64intaliano,
        "audioBase64Aleman": palabra.audioBase64Aleman,
        "frances": palabra.frances.toUpperCase(),
        "ingles": palabra.ingles.toUpperCase(),
        "italiano": palabra.italiano.toUpperCase(),
        "mandarin": palabra.mandarin.toUpperCase(),
        "palabra": palabra.palabra.toUpperCase(),
        "portugues": palabra.portugues.toUpperCase(),
        "aleman": palabra.aleman.toUpperCase()
      });
  }

  traducirTexto(texto: string, traducirAIdioma: string) {
    console.log("traducirTexto - Texto:", texto);
    console.log("traducirTexto - idioma:", traducirAIdioma);
    const endpoint = 'https://translation.googleapis.com/language/translate/v2';
    /*const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };*/
    const myKey = '&key=AIzaSyDNfU-Gx_JpkkeABbs6pf52oHZDYHoYeP4';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'charset': 'utf-8',
        /*'Access-Control-Allow-Origin': '*',*/
        //'X-Goog-Api-Key': 'AIzaSyDNfU-Gx_JpkkeABbs6pf52oHZDYHoYeP4'
      })
    }
    var objTexto = {
      "q": texto,
      "target": traducirAIdioma,
      "source": "es"
      /*"key": "86a97489e1c4490d77a73a6e8d9f0dab56da7a11"*/
    };
    //return this.http.post<any>(endpoint, JSON.stringify(objTexto), httpOptions);
    return this.http.get(endpoint + "/" + "?q=" +texto.toLowerCase()+"&source=es" + "&target=" + traducirAIdioma + myKey, httpOptions);
  }

}
