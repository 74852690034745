export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDNfU-Gx_JpkkeABbs6pf52oHZDYHoYeP4",
    authDomain: "aprendiendosolo-92242.firebaseapp.com",
    databaseURL: "https://aprendiendosolo-92242.firebaseio.com",
    projectId: "aprendiendosolo-92242",
    storageBucket: "aprendiendosolo-92242.appspot.com",
    messagingSenderId: "1024695533025"
  }
};
