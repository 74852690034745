import { Component, OnInit } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { Elemental } from '../modelo/elemental';
import { ProfesionIdealService } from '../servicios/profesion-ideal.service';

@Component({
  selector: 'app-profesion-ideal',
  templateUrl: './profesion-ideal.component.html',
  styleUrls: ['./profesion-ideal.component.css']
})
export class ProfesionIdealComponent implements OnInit {

  listaPaginas: Elemental[];
  paginaActual: Elemental;
  indicePaginas: number;
  cursoIniciado: boolean;

  constructor(private loginCom: AuthService, private profesionIdealService: ProfesionIdealService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("profesion-ideal");
    } else {
      this.loginCom.esProfesionIdeal = true;
      //this.obenerDatosHtml();
      this.init();
    }
  }

  obenerDatosHtml() {
    var archivoTxt = new XMLHttpRequest();
    var fileRuta = "../../assets/layout/images/profesionIdeal/profesionIdeal.txt";
    archivoTxt.open("GET", fileRuta, false);
    archivoTxt.send(null);
    var txt = archivoTxt.responseText;
    document.getElementById('idbody').innerHTML = txt;
  }

  init() {
    this.cursoIniciado = false;
    this.indicePaginas = 0;
    this.buscarPaginas();
  }

  buscarPaginas() {
    this.listaPaginas = [];
    this.profesionIdealService.buscarPaginas()
      .then(listaPaginas => {
        if (listaPaginas.size > 0) {
          listaPaginas.forEach(element => {
            const pagina = element.data() as Elemental;
            pagina.UID = element.id;
            this.listaPaginas.push(pagina);
          });
          this.paginaActual = this.listaPaginas[this.indicePaginas];
        }
      })
      .catch(error => {
        console.log("Error buscarPaginas()", error);
      });
  }

  iniciarCurso() {
    this.cursoIniciado = true;
  }

  continuar() {
    this.indicePaginas++;
    this.paginaActual = this.listaPaginas[this.indicePaginas];
  }

  anterior() {
    this.indicePaginas--;
    this.paginaActual = this.listaPaginas[this.indicePaginas];
  }

}
