import { Component, OnInit } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { PreguntaAptitudVerbal } from '../modelo/pregunta-aptitud-verbal';
import { AptitudVerbalService } from '../servicios/aptitud-verbal.service';
import { RespuestaAptitudVerbal } from '../modelo/respuesta-aptitud-verbal';

@Component({
  selector: 'app-aptitud-verbal',
  templateUrl: './aptitud-verbal.component.html',
  styleUrls: ['./aptitud-verbal.component.css']
})
export class AptitudVerbalComponent implements OnInit {
  preguntaActual: PreguntaAptitudVerbal;
  listaPreguntas: PreguntaAptitudVerbal[];
  listaPreguntasRespondidas: PreguntaAptitudVerbal[];
  preguntasAleatorias: number[];
  indicePregunta: number;
  countPreguntas: number;
  seleccionRespuestaA: number;
  seleccionRespuestaB: number;
  seleccionRespuestaC: number;
  seleccionRespuestaD: number;
  seleccionRespuestaE: number;
  countPreuntasExitosas: number;
  examenFinalizado: boolean;
  porcentaje: number;
  isHacerExamen: boolean;
  porcentageLeccion: number;
  esEntero: boolean;
  nivel: string;

  constructor(private loginCom: AuthService, private aptitudVerbalService: AptitudVerbalService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("aptitud");
    } else {
      this.init();
    }
  }

  init() {
    this.esEntero = true;
    this.examenFinalizado = false;
    this.isHacerExamen = false;
    this.seleccionRespuestaA = null;
    this.seleccionRespuestaB = null;
    this.seleccionRespuestaC = null;
    this.seleccionRespuestaD = null;
    this.seleccionRespuestaE = null;
    this.listaPreguntasRespondidas = [];
    this.countPreguntas = 0;
    this.indicePregunta = 0;
    this.countPreuntasExitosas = 0;
    this.preguntasAleatorias = [];
    this.cargarNumeroAleatorio();
    this.buscarPregunta(this.preguntasAleatorias[this.indicePregunta]);
    this.calcularPorcentajeExamen();
  }


  realizarExamen() {
    this.init();
    this.isHacerExamen = true;
  }

  continuar() {
    this.indicePregunta++;
    this.countPreguntas++;
    this.validarRespuesta();
    if (this.countPreguntas == 50) {
      this.finExamen();
    } else {
      this.buscarPregunta(this.preguntasAleatorias[this.indicePregunta]);
    }
  }

  finExamen() {
    this.porcentaje = (100 * this.countPreuntasExitosas) / 50;
    if (this.countPreuntasExitosas <= 5) {
      this.nivel = "Vamos amigo, hay que leer más.";
    } else if (this.countPreuntasExitosas > 5 && this.countPreuntasExitosas <= 30) {
      this.nivel = "Muy bien estás en el rango donde se encuentra el 70% de las personas que realizan este test de aptitud verbal.";
    } else if (this.countPreuntasExitosas > 31 && this.countPreuntasExitosas <= 45) {
      this.nivel = "Buena esa! Eres una persona con un muy buen léxico!";
    } else if (this.countPreuntasExitosas > 45) {
      this.nivel = "Felicitaciones!! Manejas un léxico muy rico que de seguro te ayudará a sobresalir en tu vida laboral!";
    }
    this.examenFinalizado = true;
  }

  validarRespuesta() {
    this.preguntaActual.respuestas.forEach(element => {
      if (element.esCorrecta) {
        this.preguntaActual.respuestaCorrecta = element;
      }
      if (this.seleccionRespuestaA != null) {
        if (element.numero == this.seleccionRespuestaA) {
          this.preguntaActual.respuestaSeleccionada = element;
          if (element.esCorrecta) {            
            this.countPreuntasExitosas++;
          }
        }
      }
      if (this.seleccionRespuestaB != null) {
        if (element.numero == this.seleccionRespuestaB) {
          this.preguntaActual.respuestaSeleccionada = element;
          if (element.esCorrecta) {            
            this.countPreuntasExitosas++;
          }
        }
      }
      if (this.seleccionRespuestaC != null) {
        if (element.numero == this.seleccionRespuestaC) {
          this.preguntaActual.respuestaSeleccionada = element;
          if (element.esCorrecta) {            
            this.countPreuntasExitosas++;
          }
        }
      }
      if (this.seleccionRespuestaD != null) {
        if (element.numero == this.seleccionRespuestaD) {
          this.preguntaActual.respuestaSeleccionada = element;
          if (element.esCorrecta) {            
            this.countPreuntasExitosas++;
          }
        }
      }
      if (this.seleccionRespuestaE != null) {
        if (element.numero == this.seleccionRespuestaE) {
          this.preguntaActual.respuestaSeleccionada = element;
          if (element.esCorrecta) {            
            this.countPreuntasExitosas++;
          }
        }
      }
    });
    this.listaPreguntasRespondidas.push(this.preguntaActual);
    this.seleccionRespuestaA = null;
    this.seleccionRespuestaB = null;
    this.seleccionRespuestaC = null;
    this.seleccionRespuestaD = null;
    this.seleccionRespuestaE = null;
    this.calcularPorcentajeExamen();
  }

  buscarPregunta(numero: number) {
    this.aptitudVerbalService.buscarPregunta(numero)
      .then(lista => {
        lista.forEach(element => {
          this.preguntaActual = element.data() as PreguntaAptitudVerbal;
          this.preguntaActual.UID = element.id;
          this.aptitudVerbalService.buscarRespuestas(this.preguntaActual.numero)
            .then(listaRespuestas => {
              this.preguntaActual.respuestas = [];
              listaRespuestas.forEach(res => {
                const data = res.data() as RespuestaAptitudVerbal;
                data.UID = res.id;
                this.preguntaActual.respuestas.push(data);
              });
            })
            .catch(error => console.log(error));
        });
      }).catch(error => {
        console.log("Error buscarPregunta", error);
      });
  }

  calcularPorcentajeExamen() {
    this.esEntero = true;
    this.porcentageLeccion = 0;
    this.porcentageLeccion = (100 * this.countPreguntas) / 50;
    if (!Number.isInteger(this.porcentageLeccion)) {
      this.esEntero = false;
    }
  }

  cargarNumeroAleatorio() {
    var aleatorio = Math.round(Math.random() * 100);
    if (this.preguntasAleatorias.length < 50) {
      var agregar = true;
      this.preguntasAleatorias.forEach(element => {
        if (aleatorio == element) {
          agregar = false;
        }
      });
      if (agregar) {
        this.preguntasAleatorias.push(aleatorio);
      }
      this.cargarNumeroAleatorio();
    }
  }

  cambioRes(event) {
    if (event == "A") {
      this.seleccionRespuestaB = null;
      this.seleccionRespuestaC = null;
      this.seleccionRespuestaD = null;
      this.seleccionRespuestaE = null;
    }else if (event == "B") {
      this.seleccionRespuestaA = null;
      this.seleccionRespuestaC = null;
      this.seleccionRespuestaD = null;
      this.seleccionRespuestaE = null;
    }else if (event == "C") {
      this.seleccionRespuestaA = null;
      this.seleccionRespuestaB = null;
      this.seleccionRespuestaD = null;
      this.seleccionRespuestaE = null;
    }else if (event == "D") {
      this.seleccionRespuestaA = null;
      this.seleccionRespuestaB = null;
      this.seleccionRespuestaC = null;
      this.seleccionRespuestaE = null;
    }else if (event == "E") {
      this.seleccionRespuestaA = null;
      this.seleccionRespuestaB = null;
      this.seleccionRespuestaC = null;
      this.seleccionRespuestaD = null;
    }
  }

}
