export class Palabra {
    uid: string;
    palabra: string;
    ingles: string;
    frances: string;
    portugues: string;
    aleman: string;
    mandarin: string;
    italiano: string;
    audioBase64Ingles: string;
    audioBase64Frances: string;
    audioBase64Portugues: string;
    audioBase64Aleman: string;
    audioBase64Mandarin: string;
    audioBase64intaliano: string;
    constructor() {
        this.uid = "";
        this.palabra = "";
        this.ingles = "";
        this.frances = "";
        this.portugues = "";
        this.aleman = "";
        this.italiano = "";
        this.mandarin = "";
        this.audioBase64Ingles = "";
        this.audioBase64Frances = "";
        this.audioBase64Portugues = "";
        this.audioBase64Aleman = "";
        this.audioBase64Mandarin = "";
        this.audioBase64intaliano = "";
    }
}
