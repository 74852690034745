import { Area } from "./area";
import { ChasidePregunta } from "./chaside-pregunta";

export class EstadoExamenOrientacion {
    numeroPregunta: number;
    isFinalizado: boolean;
    areasAplicables: Area[];
    listaPreguntas: ChasidePregunta[];
    fechaUltimoTest: Date;
    numeroIntentos: number;
    constructor() {
        this.numeroPregunta = 0;
        this.isFinalizado = false;
        this.areasAplicables = [];
        this.listaPreguntas = [];
        this.fechaUltimoTest = new Date();
        this.numeroIntentos = 0;
    }
}
