import { Component, OnInit } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { CursoElementalService } from '../servicios/curso-elemental.service';
import { Elemental } from '../modelo/elemental';

@Component({
  selector: 'app-curso-elemental',
  templateUrl: './curso-elemental.component.html',
  styleUrls: ['./curso-elemental.component.css']
})
export class CursoElementalComponent implements OnInit {

  listaPaginas: Elemental[];
  paginaActual: Elemental;
  indicePaginas: number;
  cursoIniciado: boolean;

  constructor(private loginCom: AuthService, private cursoElementalService: CursoElementalService) { }

  ngOnInit() {
    if (!this.loginCom.isLogged) {
      this.loginCom.validateStatusLogin("curso-elemental");
    } else {
      this.init();
    }
  }

  init() {
    this.cursoIniciado = false;
    this.indicePaginas = 0;
    this.buscarPaginas();
  }

  buscarPaginas() {
    this.listaPaginas = [];
    this.cursoElementalService.buscarPaginas()
      .then(listaPaginas => {
        if (listaPaginas.size > 0) {
          listaPaginas.forEach(element => {
            const pagina = element.data() as Elemental;
            pagina.UID = element.id;
            this.listaPaginas.push(pagina);
          });
          this.paginaActual = this.listaPaginas[this.indicePaginas];
        }
      })
      .catch(error => {
        console.log("Error buscarPaginas()", error);
      });
  }

  iniciarCurso() {
    this.cursoIniciado = true;
  }

  continuar() {
    this.indicePaginas++;
    this.paginaActual = this.listaPaginas[this.indicePaginas];    
  }

  anterior() {
    this.indicePaginas--;
    this.paginaActual = this.listaPaginas[this.indicePaginas];    
  }

}
