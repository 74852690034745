import { Component, Input, OnInit, AfterViewInit, OnDestroy, ElementRef, Renderer, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem, ScrollPanel } from 'primeng/primeng';
import { AppComponent } from './app.component';
import { AuthService } from './servicios/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    modelEstudiante: any[];
    modelAdmin: any[];

    @ViewChild('scrollPanel') layoutMenuScrollerViewChild: ScrollPanel;

    constructor(public auth: AuthService, public app: AppComponent) { }

    ngAfterViewInit() {
        setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    ngOnInit() {
        this.loadMenuEstudiante();
        /*this.modelEstudiante = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Inglés', icon: 'collections',
                items: [
                    {
                        label: 'Cursos', icon: 'collections',
                        items: [
                            { label: 'A1', routerLink: ['/curso-a1'] },
                            { label: 'A2', routerLink: ['/curso-a2'] },
                            { label: 'B1', routerLink: ['/curso-b1'] },
                            { label: 'B2', routerLink: ['/curso-b2'] },
                        ]
                    },
                    { label: 'Test', routerLink: ['/examenes'] },
                    { label: 'Diccionario Multilingüe', routerLink: ['/diccionario'] },
                    { label: 'Curso Elemental', routerLink: ['/curso-elemental'] }
                ]
            },
            {
                label: 'Diccionario Propedéutico', icon: 'assignment',
                items: [
                    { label: 'Revisar Diccionarios', routerLink: ['/diccionario-propedeutico'] }
                ]
            },
            {
                label: 'Pruebas Saber', icon: 'class',
                items: [
                    { label: 'Test Pruebas Saber', routerLink: ['/pruebasSaber'] },
                    { label: 'Test Aptitud Verbal', routerLink: ['/aptitud'] }
                ]
            },
            {
                label: 'Orientación Profesional', icon: 'assignment',
                items: [
                    { label: 'Test Orientación Profesional', routerLink: ['/orientacion'] },
                    { label: 'Mi Profesión Ideal', routerLink: ['/profesion-ideal'] }
                ]
            },
            {
                label: 'Cultura General', icon: 'class',
                items: [
                    { label: 'Test para Estudiantes', routerLink: ['/cultura-general'] }
                ]
            }
        ];*/
        this.modelAdmin = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Pines', icon: 'build', routerLink: ['/pin']
            }
        ];
        //this.verificarEstadoModuloIngles();
    }

    verificarEstadoModuloIngles() {
        /*this.cursoService.buscarEstadoActualModuloIngles(this.loginCom.usuarioSession.UID)
            .then(res => {
                if (res.size > 0) {
                    res.forEach(element => {
                        const estado = element.data() as CursosEstado;
                        estado.UID = element.id;
                        console.log("verificarEstadoModuloIngles - estado:", estado);
                        if (estado.UID == "A1") {
                            this.cargarMenuA1();
                        } else if (estado.UID == "A2") {
                            this.cargarMenuA2();
                        } else if (estado.UID == "B1") {
                            this.cargarMenuB1();
                        } else if (estado.UID == "B2") {
                            this.cargarMenuB2();
                        }
                    });
                } else {
                    this.cargarMenuA1();
                    console.log("No hay estado de curso ingles.");
                }
                this.modelAdmin = [
                    { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
                    {
                        label: 'Pines', icon: 'build', routerLink: ['/pin']
                    }
                ];
            })
            .catch(error => {
                this.cargarMenuA1();
                console.log("Error verificando estados:", error);
            });*/
    }

    cargarMenuA1() {
        this.modelEstudiante = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Modulos', icon: 'menu',
                items: [
                    {
                        label: 'Ingles', icon: 'library-books',
                        items: [
                            {
                                label: 'Cursos', icon: 'collections',
                                items: [
                                    { label: 'A1', icon: 'book', routerLink: ['/curso-a1'] },
                                    { label: 'A2', icon: 'book' },
                                    { label: 'B1', icon: 'book' },
                                    { label: 'B2', icon: 'book' },
                                ]
                            }
                        ]
                    },
                    { label: 'Pruebas Saber', icon: 'library-books', routerLink: ['/pruebasSaber'] },
                    { label: 'Orientación', icon: 'library-books' }
                ]
            }
        ];
    }

    cargarMenuA2() {
        this.modelEstudiante = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Modulos', icon: 'menu',
                items: [
                    {
                        label: 'Ingles', icon: 'library-books',
                        items: [
                            {
                                label: 'Cursos', icon: 'collections',
                                items: [
                                    { label: 'A1', icon: 'book', routerLink: ['/curso-a1'] },
                                    { label: 'A2', icon: 'book', routerLink: ['/curso-a2'] },
                                    { label: 'B1', icon: 'book' },
                                    { label: 'B2', icon: 'book' },
                                ]
                            }
                        ]
                    },
                    { label: 'Pruebas Saber', icon: 'library-books', routerLink: ['/pruebasSaber'] },
                    { label: 'Orientación', icon: 'library-books' }
                ]
            }
        ];
    }

    cargarMenuB1() {
        this.modelEstudiante = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Modulos', icon: 'menu',
                items: [
                    {
                        label: 'Ingles', icon: 'library-books',
                        items: [
                            {
                                label: 'Cursos', icon: 'collections',
                                items: [
                                    { label: 'A1', icon: 'book', routerLink: ['/curso-a1'] },
                                    { label: 'A2', icon: 'book', routerLink: ['/curso-a2'] },
                                    { label: 'B1', icon: 'book', routerLink: ['/curso-b1'] },
                                    { label: 'B2', icon: 'book' },
                                ]
                            }
                        ]
                    },
                    { label: 'Pruebas Saber', icon: 'library-books', routerLink: ['/pruebasSaber'] },
                    { label: 'Orientación', icon: 'library-books', routerLink: ['/orientacion'] }
                ]
            }
        ];
    }

    cargarMenuB2() {
        this.modelEstudiante = [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
            {
                label: 'Modulos', icon: 'menu',
                items: [
                    {
                        label: 'Ingles', icon: 'library-books',
                        items: [
                            {
                                label: 'Cursos', icon: 'collections',
                                items: [
                                    { label: 'A1', icon: 'book', routerLink: ['/curso-a1'] },
                                    { label: 'A2', icon: 'book', routerLink: ['/curso-a2'] },
                                    { label: 'B1', icon: 'book', routerLink: ['/curso-b1'] },
                                    { label: 'B2', icon: 'book', routerLink: ['/curso-b2'] },
                                ]
                            }
                        ]
                    },
                    { label: 'Pruebas Saber', icon: 'library-books', routerLink: ['/pruebasSaber'] },
                    { label: 'Orientación', icon: 'library-books', routerLink: ['/orientacion'] }
                ]
            }
        ];
    }

    loadMenuEstudiante() {
        this.modelEstudiante = [];
        this.modelEstudiante.push({ label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] });
        this.auth.listaModulosUno.forEach(modulo => {
            this.agregarItemMenu(modulo);
        });

    }

    agregarItemMenu(modulo: string){
        switch (modulo) {
            case 'Curso Ingles':
                this.modelEstudiante.push(this.agregarMenuModuloIngles());
                break;
            case 'Pruebas Saber':
                this.modelEstudiante.push(this.agregarMenuModuloPruebasSaber());
                break;
            case 'Orientación':
                this.modelEstudiante.push(this.agregarMenuModuloOrientacion());
                break;
            case 'Cultura General':
                this.modelEstudiante.push(this.agregarMenuModuloCulturaGeneral());
                break;
            case 'Diccionario Propedéutico':
                this.modelEstudiante.push(this.agregarMenuModuloDiccionario());
                break;
        }
    }

    agregarMenuModuloIngles(): any {
        let menu = {
            label: 'Inglés', icon: 'collections',
            items: [
                {
                    label: 'Cursos', icon: 'collections',
                    items: [
                        { label: 'A1', routerLink: ['/curso-a1'] },
                        { label: 'A2', routerLink: ['/curso-a2'] },
                        { label: 'B1', routerLink: ['/curso-b1'] },
                        { label: 'B2', routerLink: ['/curso-b2'] },
                    ]
                },
                { label: 'Test', routerLink: ['/examenes'] },
                { label: 'Diccionario Multilingüe', routerLink: ['/diccionario'] },
                { label: 'Curso Elemental', routerLink: ['/curso-elemental'] }
            ]
        }
        return menu;
    }

    agregarMenuModuloDiccionario(): any {
        let menu = {
            label: 'Diccionario Propedéutico', icon: 'assignment',
            items: [
                { label: 'Revisar Diccionarios', routerLink: ['/diccionario-propedeutico'] }
            ]
        }
        return menu;
    }

    agregarMenuModuloPruebasSaber(): any {
        let menu = {
            label: 'Pruebas Saber', icon: 'class',
            items: [
                { label: 'Test Pruebas Saber', routerLink: ['/pruebasSaber'] },
                { label: 'Test Aptitud Verbal', routerLink: ['/aptitud'] }
            ]
        }
        return menu;
    }

    agregarMenuModuloOrientacion(): any {
        let menu = {
            label: 'Orientación Profesional', icon: 'assignment',
            items: [
                { label: 'Test Orientación Profesional', routerLink: ['/orientacion'] },
                { label: 'Mi Profesión Ideal', routerLink: ['/profesion-ideal'] }
            ]
        }
        return menu;
    }

    agregarMenuModuloCulturaGeneral(): any {
        let menu = {
            label: 'Cultura General', icon: 'class',
            items: [
                { label: 'Test para Estudiantes', routerLink: ['/cultura-general'] }
            ]
        }
        return menu;
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppComponent, public router: Router, public location: Location,
        public appMenu: AppMenuComponent,
        private auth: AuthService) { }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex as number === index) ? -1 : index;
        }

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }


}
