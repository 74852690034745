export class Usuario {
    UID: string;
    nombre?: string;
    apellido?: string;
    email?: string;
    escuela?: string;
    estado?: string;
    pin?: string;
    modulos?: string;
    tipoUsuario?: string;
    urlFoto?: string;
    numeroIdentificacion?: string;
    tipoIdentificacion?: string;
    token?: string;
    isAnonimo: boolean;
    isEmailVerificado: boolean;
    fechaFin: Date;
    caduca: boolean;
    constructor() {
        this.UID = "";
        this.nombre = "";
        this.apellido = "";
        this.email = "";
        this.escuela = "";
        this.estado = "";
        this.pin = "";
        this.modulos = "";
        this.tipoUsuario = "";
        this.urlFoto = "";
        this.numeroIdentificacion = "";
        this.tipoIdentificacion = "";
        this.token = "";
        this.isAnonimo = false;
        this.isEmailVerificado = false;
        this.fechaFin = null;
        this.caduca = true;
    }
}
