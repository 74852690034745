import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class CursoElementalService {

  constructor(private afs: AngularFirestore) { }

  buscarPaginas() {
    return this.afs.firestore.collection("cursosIngles").doc("elemental-20-20-20-20")
      .collection("paginas").orderBy("orden").get();
  }
}
